import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const FetchSystems = (props) => {

  const queryParams = new URLSearchParams(window.location.search);
  const accessToken = queryParams.get("userAuthToken");

  const loadOptions = async (searchQuery, _, { page }) => {

    const response = await fetch(
      `/api/v1/service_codes/aar/systems?search=${searchQuery}&page=${page}`, {
      method: 'GET',
      headers: {
        'Access-Token': accessToken,
      },
    }
    );
    const responseJSON = await response.json();

    let options = responseJSON.service_components.map((option) => ({
      label: `${option.name}`,
      value: `${option.id}`,
      data: option
    }))

    return {
      options: options,
      hasMore: responseJSON.meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: props.showError ? '#ff0000' : '#cccccc',
    }),
  };

  return (
    <>
      {typeof (props.value) === 'string' ?
        <AsyncPaginate
          defaultValue={{ value: props.value, label: props.value }}
          loadOptions={loadOptions}
          onChange={onChange}
          isSearchable={true}
          placeholder="System"
          className='service_dropdown async-dd'
          debounceTimeout={500}
          additional={{
            page: 1,
          }}
        /> :

        <AsyncPaginate
          value={props.value}
          loadOptions={loadOptions}
          onChange={onChange}
          isSearchable={true}
          placeholder="Please select"
          className='service_dropdown async-dd'
          styles={customStyles}
          debounceTimeout={500}
          additional={{
            page: 1,
          }}
        />
      }
    </>
  );
};

FetchSystems.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default FetchSystems;
