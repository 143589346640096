import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Loader() {
  return (
    <div className="loader center">
      <div>
        <FontAwesomeIcon icon="spinner" spin />
      </div>
    </div>
  );
}

export default Loader;