import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import withLoader from "../core/withLoader"
import cx from "classnames"
import Select from "react-select"
import ReactTooltip from "react-tooltip"
import ToggleButton from "react-toggle-button"
import { instance } from "../core/api"
import CurrencyInput from "../core/CurrencyInput"

export const Row = ({ children, rowClassName, id, onRowClick }) => {
	return (
		<>
			<div
				className={cx("table-row", rowClassName)}
				onClick={() => onRowClick(id)}
			>
				{children}
			</div>
		</>
	)
}
Row.propTypes = {
	children: PropTypes.node,
	rowClassName: PropTypes.string,
	onRowClick: PropTypes.func,
	id: PropTypes.number,
}
Row.defaultProps = {
	rowClassName: "",
	onRowClick: () => {},
}

export const Checkbox = ({ id, onSelect, checked, checkboxStatus }) => {
	const [isChecked, setIsChecked] = useState(null)
	const handleOnChange = (e) => {
		let status = e.target.checked
		onSelect(id, status)
		setIsChecked(!isChecked)
	}
	return (
		<input
			type="checkbox"
			id={id}
			onChange={(e) => handleOnChange(e)}
			checked={checkboxStatus}
			// checked={checked.includes(id)}
		/>
	)
}
Checkbox.propTypes = {
	id: PropTypes.number,
	checked: PropTypes.array,
}
Checkbox.defaultProps = {
	checked: [],
}

export const Cell = ({
	headerData,
	rowData,
	columnClassName,
	stickyCellIndex,
	isRowClickable,
	onRowClick,
	onSaveClick,
	onRemoveClick,
	onShowClick,
	onSelect,
	checked,
	UOMList,
	onAssociateClick,
	associateStatus,
}) => {
	const [rowId, setRowId] = useState(null)
	const [resObj, setResObj] = useState({})
	const [showTextBox, setShowTextBox] = useState(false)

	const columnClassNames = cx(
		`table-cell`,
		{ "sticky-cell": stickyCellIndex },
		columnClassName
	)

	const formatPrice = (val) => {
		let formatValue = val && val.toString().replace("$", "").replace(",", "")
		return formatValue
	}

	const handleChange = (evt) => {
		let value =
			evt.target.name === "part_price"
				? formatPrice(evt.target.value)
				: evt.target.value
		setResObj({
			...resObj,
			[evt.target.name]: value,
		})
	}

	const closeEdit = (e) => {
		e.preventDefault()
		setShowTextBox(!showTextBox)
	}

	let uomOptions = [{}]

	if (UOMList !== undefined) {
		Object.entries(UOMList).forEach(([key, value]) =>
			uomOptions.push({
				value: `${key}`,
				label: `${value}`,
			})
		)
	}

	const handleUOMChange = (selectedUOM) => {
		resObj.uom = selectedUOM.value
	}

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: "#fff",
			borderColor: "#5195FB",
			minHeight: "30px",
			height: "30px",
			boxShadow: state.isFocused ? null : null,
		}),

		valueContainer: (provided, state) => ({
			...provided,
			height: "30px",
			padding: "0 6px",
		}),

		input: (provided, state) => ({
			...provided,
			margin: "0px",
		}),
		indicatorSeparator: (state) => ({
			display: "none",
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			height: "30px",
		}),
	}

	const truncateText = (str, subStrLength) => {
		// return shortText;
		if (str.length <= subStrLength) {
			return str
		} else {
			return str.slice(0, subStrLength) + "..."
		}
		// Return str truncated with '...' concatenated to the end of str.
	}

	return rowData && rowData.length ? (
		rowData.map((rowValue, rowKey) => {
			return (
				<Row
					key={rowKey}
					rowClassName={isRowClickable ? "clickable-row" : null}
					id={rowValue.id}
					updatedData={resObj}
				>
					{headerData.map(
						(headerValue) =>
							headerValue.name && (
								<div
									key={`${headerValue.name}${rowKey}`}
									className={
										headerValue.isDesc
											? cx(columnClassNames) + " isDesc"
											: headerValue.isBtn
											? cx(columnClassNames) + " isBtn"
											: headerValue.isUOM
											? cx(columnClassNames) + " isUOM"
											: headerValue.isAssociate
											? cx(columnClassNames) + " isAssociate"
											: headerValue.isCurrency
											? cx(columnClassNames) + " isCurrency"
											: cx(columnClassNames)
									}
									onClick={() => {
										if (
											headerValue.name === "edit" &&
											rowValue.can_edit === true
										) {
											setRowId(rowValue.id)
											setResObj(rowValue)
											setShowTextBox(!showTextBox)
										}

										if (
											headerValue.name === "delete" &&
											rowValue.can_edit === true
										) {
											onRemoveClick[headerValue.removeClickFunction](
												rowValue.id
											)
										}

										if (
											headerValue.name === "save" &&
											rowValue.can_edit === true
										) {
											let formIsValid = true
											let errorList = []
											if (resObj.part_group_code === "") {
												formIsValid = false
												errorList.push({
													err: "Part group code required",
												})
											}

											if (resObj.part_group_description === "") {
												formIsValid = false
												errorList.push({
													err: "Part group code description required",
												})
											}

											if (resObj.part_code === "") {
												formIsValid = false
												errorList.push({
													err: "Part code required",
												})
											}

											if (resObj.part_code_description === "") {
												formIsValid = false
												errorList.push({
													err: "Part code description required",
												})
											}

											if (resObj.uom === "") {
												formIsValid = false
												errorList.push({
													err: "UOM required",
												})
											}

											if (resObj.part_price === "") {
												formIsValid = false
												errorList.push({
													err: "Part price required",
												})
											}

											if (formIsValid) {
												setShowTextBox(false)
											}

											onSaveClick[headerValue.saveClickFunction](
												rowValue,
												headerValue.name,
												rowValue.id,
												resObj,
												errorList
											)
										}

										if (headerValue.name === "parts_service") {
											onAssociateClick[headerValue.associateClickFunction](
												rowValue
											)
										}
									}}
								>
									{rowValue ? (
										headerValue.name === "edit" &&
										rowValue.can_edit === true ? (
											<>
												{rowId === rowValue.id && showTextBox ? (
													<span
														className="icon-close"
														onClick={(e) => closeEdit(e)}
													>
														<strong>X</strong>
													</span>
												) : (
													""
												)}{" "}
												<span id={rowValue.id}>
													<i className="fas fa-pencil-alt"></i>
												</span>
											</>
										) : headerValue.name === "show" ? (
											<label className="switch">
												<Checkbox
													id={rowValue.id}
													onSelect={rowValue.can_edit ? onSelect : ""}
													//checked={checked}
													checkboxStatus={rowValue.enabled}
												/>
												<div
													className={
														rowValue.can_edit
															? "slider round"
															: "sliderDisabled round"
													}
												></div>
											</label>
										) : headerValue.name === "parts_service" ? (
											<button className="btn btn-regular btn-blue">
												{rowValue.parts_services &&
												rowValue.parts_services.length > 0
													? "Services"
													: "Associate"}
											</button>
										) : headerValue.name === "delete" &&
										  rowValue.can_edit === true ? (
											<span id={rowValue.id}>
												<i className="far fa-trash-alt"></i>
											</span>
										) : headerValue.name === "save" &&
										  rowValue.can_edit === true ? (
											<span id={rowValue.id}>
												<i className="fas fa-save"></i>
											</span>
										) : rowId === rowValue.id ? (
											showTextBox ? (
												headerValue.name === "part_price" ? (
													<CurrencyInput
														placeholder="0.00"
														type="text"
														name={headerValue.name}
														className="part-text-box price-text-box"
														defaultValue={rowValue[headerValue.name]}
														onChange={handleChange}
													/>
												) : headerValue.name === "uom" ? (
													<Select
														defaultValue={{
															value: rowValue[headerValue.name],
															label: rowValue[headerValue.name],
														}}
														// ref={selectInputRef}
														onChange={handleUOMChange}
														options={uomOptions}
														className="uom_dropdown"
														styles={customStyles}
														placeholder={"UOM"}
													/>
												) : (
													<input
														type="text"
														defaultValue={rowValue[headerValue.name]}
														name={headerValue.name}
														onChange={handleChange}
														className={"part-text-box"}
													/>
												)
											) : headerValue.isTooltip ? (
												<>
													<a
														data-for="main"
														data-tip={rowValue[headerValue.name]}
														data-iscapture="true"
													>
														{truncateText(rowValue[headerValue.name], 20)}
													</a>

													<ReactTooltip
														id="main"
														place="top"
														type="info"
														effect="float"
														multiline={true}
													/>
												</>
											) : headerValue.isCurrency ? (
												rowValue[headerValue.name].toFixed(2)
											) : (
												rowValue[headerValue.name]
											)
										) : headerValue.isCurrency ? (
											rowValue[headerValue.name].toFixed(2)
										) : headerValue.isTooltip ? (
											<>
												<a
													data-for="main"
													data-tip={rowValue[headerValue.name]}
													data-iscapture="true"
												>
													{truncateText(rowValue[headerValue.name], 20)}
												</a>

												<ReactTooltip
													id="main"
													place="top"
													type="info"
													effect="float"
													multiline={true}
												/>
											</>
										) : (
											rowValue[headerValue.name]
										)
									) : (
										"NA"
									)}
								</div>
							)
					)}
				</Row>
			)
		})
	) : (
		null
	)
}

Cell.propTypes = {
	headerData: PropTypes.array,
	rowData: PropTypes.array,
	columnClassName: PropTypes.string,
	onCellClick: PropTypes.func,
	stickyCellIndex: PropTypes.bool,
	checked: PropTypes.array,
	matchParams: PropTypes.object,
	isRowClickable: PropTypes.bool,
	onRowClick: PropTypes.func,
	columnClicks: PropTypes.func,
}
Cell.defaultProps = {
	columnClassName: "",
	headerData: [],
	rowData: [],
	stickyCellIndex: false,
	onCellClick: () => {},
	actionButtons: [],
	checkAggregatorId: false,
	isRowClickable: false,
	onRowClick: () => {},
}

export const TableHeader = ({
	headerData,
	tableHeaderClassName,
	rowClassName,
	columnClassName,
	stickyCellIndex,
}) => {
	return (
		<div className={cx("table-header", tableHeaderClassName)}>
			<Row className={rowClassName}>
				{headerData.map(
					({ name, label, isVline, isDesc, isBtn, isUOM, isAssociate }) => (
						<div
							key={`title-${name}`}
							className={cx(
								`table-cell ${isDesc ? "isDesc" : ""} ${isBtn ? "isBtn" : ""} ${
									isUOM ? "isUOM" : ""
								} ${isAssociate ? "isAssociate" : ""}`,
								{ "sticky-cell": stickyCellIndex },
								columnClassName
							)}
						>
							{isVline ? <span className="vline"></span> : ""} {label}
						</div>
					)
				)}
			</Row>
		</div>
	)
}
TableHeader.propTypes = {
	headerData: PropTypes.array,
	tableHeaderClassName: PropTypes.string,
	rowClassName: PropTypes.string,
	columnClassName: PropTypes.string,
	stickyCellIndex: PropTypes.bool,
	handleSort: PropTypes.func,
	handleSelectAll: PropTypes.func,
}
TableHeader.defaultProps = {
	tableHeaderClassName: "",
	rowClassName: "",
	columnClassName: "",
	headerData: [],
	stickyCellIndex: false,
}

export const TableBody = ({
	headerData,
	rowData,
	rowClassName,
	columnClassName,
	tableBodyClassName,
	stickyCellIndex,
	onCellClick,
	checked,
	onSelect,
	actionButtons,
	matchParams,
	isRowClickable,
	onRowClick,
	columnClicks,
	onSaveClick,
	onRemoveClick,
	addNewRow,
	newRowData,
	removeRow,
	onShowClick,
	UOMList,
	onAssociateClick,
	associateStatus,
	showEmptyMsg,
}) => {
	const [obj, setObj] = useState({
		part_group_code: "",
		part_group_description: "",
		mfr_part: "",
		part_code_description: "",
		uom: "",
		part_code: "",
		part_price: "",
		rating: "",
		specification: "",
	})
	const [isError, setIsError] = useState(false)
	const [selectedUOMOption, setSelectedUOMOption] = useState(null)
	const [errorMessages, setErrorMessages] = useState([])
	const [formSubmit, setFormSubmit] = useState(false)

	const formatPrice = (val) => {
		let formatValue = val && val.toString().replace("$", "").replace(",", "")
		return formatValue
	}

	const handleRowData = (e) => {
		let value =
			e.target.name === "part_price"
				? formatPrice(e.target.value)
				: e.target.value
		setObj({ ...obj, [e.target.name]: value })
	}

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: "#fff",
			borderColor: formSubmit && obj.uom === "" ? "#5195FB #ff0000" : "#5195FB",
			minHeight: "30px",
			height: "30px",
			boxShadow: state.isFocused ? null : null,
		}),

		valueContainer: (provided, state) => ({
			...provided,
			height: "30px",
			padding: "0 6px",
		}),

		input: (provided, state) => ({
			...provided,
			margin: "0px",
		}),
		indicatorSeparator: (state) => ({
			display: "none",
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			height: "30px",
		}),
	}

	const submitForm = () => {
		if (validateForm()) {
			newRowData(obj)
			setSelectedUOMOption(null)
			setObj({
				part_group_code: "",
				part_group_description: "",
				mfr_part: "",
				part_code_description: "",
				uom: "",
				part_code: "",
				part_price: "",
				rating: "",
				specification: "",
			})
			//removeRowData()
			setFormSubmit(false)
		}
	}

	const validateForm = () => {
		let formIsValid = true
		let errorMsg = []
		setFormSubmit(true)
		const {
			part_group_code,
			part_group_description,
			part_code_description,
			uom,
			part_code,
			part_price,
		} = obj

		if (part_group_code === "") {
			formIsValid = false
			errorMsg.push({ part_group_code: "Part group code error" })
			setIsError(!isError)
		} else {
			setIsError(false)
		}

		if (part_group_description === "") {
			formIsValid = false
			errorMsg.push({ part_grop_description: "Part group description error" })
			setIsError(!isError)
		}

		if (part_code_description === "") {
			errorMsg.push({ part_code_description: "part code description error" })
			formIsValid = false
			setIsError(!isError)
		}

		if (uom === "") {
			formIsValid = false
			errorMsg.push({ uom: "UOM error" })
			setIsError(!isError)
		}

		if (part_code === "") {
			formIsValid = false
			errorMsg.push({ part_code: "part code error" })
			setIsError(!isError)
		}

		if (part_price === "") {
			formIsValid = false
			errorMsg.push({ part_price: "part price error" })
			setIsError(!isError)
		}
		setErrorMessages(errorMsg)
		return formIsValid
	}

	const removeRowData = () => {
		setSelectedUOMOption(null)
		removeRow(true)
		setObj({
			part_group_code: "",
			part_group_description: "",
			mfr_part: "",
			part_code_description: "",
			uom: "",
			part_code: "",
			part_price: "",
			rating: "",
			specification: "",
		})
	}

	let uomOptions = [{}]

	if (UOMList !== undefined) {
		Object.entries(UOMList).forEach(([key, value]) =>
			uomOptions.push({
				value: `${key}`,
				label: `${value}`,
			})
		)
	}

	const handleUOMChange = (uom_option) => {
		setSelectedUOMOption(uom_option)
		obj.uom = uom_option.value
	}

	return (
		<div className={cx("table-body", tableBodyClassName)} id="table-body">
			{addNewRow ? (
				<div className="table-row">
					<div className="table-cell">
						<input
							type="text"
							name="part_group_code"
							value={obj.part_group_code}
							onChange={(e) => handleRowData(e)}
							className={
								formSubmit && obj.part_group_code === ""
									? "part-text-box errorClass"
									: "part-text-box"
							}
						/>
					</div>
					<div className="table-cell isDesc">
						<input
							type="text"
							name="part_group_description"
							value={obj.part_group_description}
							onChange={(e) => handleRowData(e)}
							className={
								formSubmit && obj.part_group_description === ""
									? "part-text-box errorClass"
									: "part-text-box"
							}
						/>
					</div>
					<div className="table-cell">
						<input
							type="text"
							name="part_code"
							value={obj.part_code}
							onChange={(e) => handleRowData(e)}
							className={
								formSubmit && obj.part_code === ""
									? "part-text-box errorClass"
									: "part-text-box"
							}
						/>
					</div>
					<div className="table-cell isDesc">
						<input
							type="text"
							name="part_code_description"
							value={obj.part_code_description}
							onChange={(e) => handleRowData(e)}
							className={
								formSubmit && obj.part_code_description === ""
									? "part-text-box errorClass"
									: "part-text-box"
							}
						/>
					</div>

					<div className="table-cell isUOM">
						<Select
							value={selectedUOMOption}
							onChange={handleUOMChange}
							options={uomOptions}
							className="uom_dropdown"
							styles={customStyles}
							placeholder={"UOM"}
						/>
					</div>

					<div className="table-cell">
						<input
							type="text"
							name="mfr_part"
							value={obj.mfr_part}
							onChange={(e) => handleRowData(e)}
							className="part-text-box"
						/>
					</div>
					<div className="table-cell">
						<CurrencyInput
							placeholder="0.00"
							type="text"
							name="part_price"
							className={
								(formSubmit && obj.part_price === "") ||
								(formSubmit && obj.part_price === null)
									? "part-text-box errorClass"
									: "part-text-box"
							}
							value={obj.part_price || null}
							onChange={(e) => handleRowData(e)}
						/>
					</div>
					<div className="table-cell">
						<input
							type="text"
							name="rating"
							value={obj.rating}
							onChange={(e) => handleRowData(e)}
							className={"part-text-box"}
						/>
					</div>
					<div className="table-cell">
						<input
							type="text"
							name="specification"
							value={obj.specification}
							onChange={(e) => handleRowData(e)}
							className={"part-text-box"}
						/>
					</div>
					<div className="table-cell btn-div">
						<button onClick={submitForm} className="btn-regular btn-blue">
							Add parts
						</button>
						&nbsp;&nbsp;
						<button className="btn-regular btn-red" onClick={removeRowData}>
							Remove
						</button>
					</div>
					<div className="spare-div">&nbsp;</div>
				</div>
			) : null}

			{showEmptyMsg && (
				<div className="parts-no-record">
					<span>No data</span>
				</div>
			)}

			<Cell
				headerData={headerData}
				rowData={rowData}
				rowClassName={rowClassName}
				columnClassName={columnClassName}
				stickyCellIndex={stickyCellIndex}
				onCellClick={onCellClick}
				checked={checked}
				onSelect={onSelect}
				actionButtons={actionButtons}
				matchParams={matchParams}
				isRowClickable={isRowClickable}
				onRowClick={onRowClick}
				columnClicks={columnClicks}
				onSaveClick={onSaveClick}
				onRemoveClick={onRemoveClick}
				onShowClick={onShowClick}
				UOMList={UOMList}
				onAssociateClick={onAssociateClick}
				associateStatus={associateStatus}
			></Cell>
		</div>
	)
}
TableBody.propTypes = {
	headerData: PropTypes.array,
	rowData: PropTypes.array,
	rowClassName: PropTypes.string,
	columnClassName: PropTypes.string,
	tableBodyClassName: PropTypes.string,
	stickyCellIndex: PropTypes.bool,
	onCellClick: PropTypes.func,
	checked: PropTypes.array,
	actionButtons: PropTypes.array,
	matchParams: PropTypes.object,
	isRowClickable: PropTypes.bool,
	onRowClick: PropTypes.func,
	columnClicks: PropTypes.func,
	addNewRow: PropTypes.bool,
	newRowData: PropTypes.func,
}
TableBody.defaultProps = {
	headerData: [],
	rowData: [],
	rowClassName: "",
	columnClassName: "",
	tableBodyClassName: "",
	isRowClickable: false,
	UOMList: {},
}

const Table = ({ children, tableClassName, ...restProps }) => {
	return (
		<Fragment>
			<div className={cx("sticky-table", tableClassName)} {...restProps}>
				<div className="table-content">{children}</div>
			</div>
		</Fragment>
	)
}
Table.propTypes = {
	children: PropTypes.node,
	tableClassName: PropTypes.string,
	onPageChanged: PropTypes.func,
	totalRecords: PropTypes.number,
	pageLimit: PropTypes.number,
}
Table.defaultProps = {
	tableClassName: "",
}

export default withLoader(Table)
