import React from 'react';
import PropTypes from 'prop-types';

const CRUDHeader = ({ headerModel }) => {
  return (
    <div className="col-12 content-header">
      {headerModel && headerModel.length > 0
        ? headerModel.map(
          ({ name, label, columnWidth, isActionButtons }) =>
            isActionButtons ? null : (
              <div
                key={`head-${name}`}
                className={`content-header-element col ${columnWidth}`}
              >
                {label}
              </div>
            )
        )
        : null}
    </div>
  )
}

CRUDHeader.propTypes = {
  headerModel: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default CRUDHeader;
