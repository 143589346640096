import React, { useState, useEffect } from "react"
import { instance } from "../core/api"
import Select from "react-select"

import FetchSystems from "./FetchSystems"
import FetchSubSystems from "./FetchSubSystems"
import FetchServiceList from "./FetchServiceList"

import FetchVMRSSystems from "./FetchVMRSSystems"
import FetchAssemblyList from "./FetchAssemblyList"
import FetchComponentList from "./FetchComponentList"

import { Formik, Field, Form, FieldArray } from "formik"

const AddAssociates = (props) => {
	const [service_types, setServiceTypes] = useState({})

	const [currentAARSystem, setCurrentAARSystem] = useState(null)
	const [currentAARSubSystem, setCurrentAARSubSystem] = useState(null)
	const [currentAARServiceList, setCurrentAARServiceList] = useState(null)
	const [currentVMRSSystem, setCurrentVMRSSystem] = useState(null)
	const [currentVMRSAssembly, setCurrentVMRSAssembly] = useState(null)
	const [currentVMRSComponent, setCurrentVMRSComponent] = useState(null)
	const [selectedServiceType, setSelectedServiceType] = useState({})
	const [formValues, setFormValues] = useState([])
	const [aarSystemError, setAARSystemError] = useState(false)
	const [vmrsSystemError, setVMRSSystemError] = useState(false)
	const [vmrsAssemblyError, setVMRSAssemblyError] = useState(false)
	const [serviceTypeError, setServiceTypeError] = useState(false)
	const [errorsList, setErrorsList] = useState([])

	let { id } = props.selectedPart
	let { closePopup } = props

	useEffect(() => {
		instance
			.get("/service_types")
			.then((res) => {
				let service_types = res.data
				if (service_types !== undefined) {
					setServiceTypes(service_types)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}, [
		props,
		errorsList,
		serviceTypeError,
		aarSystemError,
		vmrsSystemError,
		vmrsAssemblyError,
	])

	const getNames = {
		service_type: "",
		aar_system: null,
		aar_sub_system: null,
		aar_service: null,
		vmrs_system: null,
		vmrs_assembly: null,
		vmrs_component: null,
	}

	const partsAssociates = {
		associates: [getNames],
	}

	const options = [
		{ value: service_types.aar, label: service_types.aar },
		{ value: service_types.vmrs, label: service_types.vmrs },
	]

	let payload = []

	const getValues = (formValue) => {
		formValue.associates.map((associate, index) => {
			let serviceType = associate.service_type.value
			payload.push({
				service_type:
					serviceType !== undefined ? serviceType.toLowerCase() : null,
				aar_system: {
					service_type: associate.service_type.value === "AAR" ? "aar" : null,
					name:
						associate.aar_system !== null ? associate.aar_system.label : null,
					level: associate.service_type.value === "AAR" ? "system" : null,
					code: null,
					source_system_id:
						associate.aar_system !== null
							? parseInt(associate.aar_system.value)
							: null,
				},
				aar_subsystem: {
					service_type: associate.service_type.value === "AAR" ? "aar" : null,
					name:
						associate.aar_sub_system !== null
							? associate.aar_sub_system.label
							: null,
					level: associate.service_type.value === "AAR" ? "subsystem" : null,
					code: null,
					source_system_id:
						associate.aar_sub_system !== null
							? parseInt(associate.aar_sub_system.value)
							: null,
				},
				aar_service: {
					service_type: associate.service_type.value === "AAR" ? "aar" : null,
					name:
						associate.aar_service !== null ? associate.aar_service.label : null,
					level: associate.service_type.value === "AAR" ? "service" : null,
					code: null,
					source_system_id:
						associate.aar_service !== null
							? parseInt(associate.aar_service.value)
							: null,
				},
				vmrs_system: {
					service_type: associate.service_type.value === "VMRS" ? "vmrs" : null,
					name:
						associate.vmrs_system !== null ? associate.vmrs_system.label : null,
					level: associate.service_type.value === "VMRS" ? "system" : null,
					code:
						associate.vmrs_system !== null ? associate.vmrs_system.value : null,
					source_system_id: null,
				},
				vmrs_assembly: {
					service_type: associate.service_type.value === "VMRS" ? "vmrs" : null,
					name:
						associate.vmrs_assembly !== null
							? associate.vmrs_assembly.label
							: null,
					level: associate.service_type.value === "VMRS" ? "assembly" : null,
					code:
						associate.vmrs_assembly !== null
							? associate.vmrs_assembly.value
							: null,
					source_system_id: null,
				},
				vmrs_component: {
					service_type: associate.service_type.value === "VMRS" ? "vmrs" : null,
					name:
						associate.vmrs_component !== null
							? associate.vmrs_component.label
							: null,
					level: associate.service_type.value === "VMRS" ? "component" : null,
					code:
						associate.vmrs_component !== null
							? associate.vmrs_component.value
							: null,
					source_system_id: null,
				},
			})
		})

		let errors = []
		let validate = true

		payload.map((data, index) => {
			if (data.service_type === null) {
				setServiceTypeError(!serviceTypeError)
				errors.push({
					err: "Please choose service type in row " + (index + 1),
				})
				validate = false
			}

			if (data.service_type === "aar" && data.aar_system.name === null) {
				setAARSystemError(!aarSystemError)
				errors.push({
					err: "Please choose AAR system in row " + (index + 1),
				})
				validate = false
			}

			if (data.service_type === "vmrs" && data.vmrs_system.name === null) {
				setVMRSSystemError(!vmrsSystemError)
				errors.push({
					err: "Please choose VMRS System in row " + (index + 1),
				})
				closePopup(false)
				validate = false
			}

			if (data.service_type === "vmrs" && data.vmrs_assembly.name === null) {
				setVMRSAssemblyError(!vmrsAssemblyError)
				errors.push({
					err: "Please choose VMRS Assembly in row " + (index + 1),
				})
				closePopup(false)
				validate = false
			}
		})

		setErrorsList(errors)

		if (validate) {
			instance
				.put(`/parts/${id}/upsert`, {
					parts_services: payload,
				})
				.then((res) => {
					if (res.data !== undefined || res.data !== null) {
						closePopup(true)
					}
				})
				.catch((error) => {
					console.log(error)
					closePopup(false)
				})
		}
	}

	return (
		<div className="popup-content-container">
			<div className="popup-title">Associate Parts</div>
			<div className="popup-body">
				<Formik
					initialValues={partsAssociates}
					onSubmit={(values) => {
						getValues(values)
					}}
					validate={(values) => {
						if (values.associates.length === 0) {
							return { arr: "Required least one associated part" }
						}
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						handleReset,
					}) => {
						return (
							<Form>
								<div className="associate-details">
									<FieldArray
										name="associates"
										render={({ insert, remove, push }) => (
											<div>
												<div className="add-rows">
													<div className="info">
														<i>
															<sup>*</sup> Fields are mandatory
														</i>
													</div>
													<div className="btn-add-service">
														<button
															type="button"
															className="btn-regular btn-blue"
															onClick={() => push(getNames)}
														>
															Add Services
														</button>
													</div>
												</div>
												<div className="errors-list">
													<ul>
														{errorsList &&
															errorsList.map((error, index) => {
																return <li key={index}>{error.err}</li>
															})}
													</ul>
												</div>
												{values.associates.length > 0 &&
													values.associates.map((associate, index) => (
														<div key={index} className="row-block">
															<div className="input-elements btn-close">
																<button
																	type="button"
																	className="btn-regular btn-red"
																	onClick={() => remove(index)}
																>
																	X
																</button>
															</div>

															<div className="input-row">
																<div className="flex-grid header">
																	<div className="service-type-title">
																		Select Type
																	</div>
																	{values.associates &&
																		values.associates[index].service_type
																			.value === "AAR" && (
																			<>
																				<div className="async-dd-title">
																					AAR System <sup>*</sup>
																				</div>
																				<div className="async-dd-title">
																					AAR Subsystem
																				</div>
																				<div className="async-dd-title">
																					AAR Service
																				</div>
																			</>
																		)}

																	{values.associates &&
																		values.associates[index].service_type
																			.value === "VMRS" && (
																			<>
																				<div className="async-dd-title">
																					VMRS System <sup>*</sup>
																				</div>
																				<div className="async-dd-title">
																					VMRS Assembly <sup>*</sup>
																				</div>
																				<div className="async-dd-title">
																					VMRS Component
																				</div>
																			</>
																		)}
																</div>
																<div className="flex-grid">
																	<div className="input-elements service-dropdown">
																		<Field
																			name={`associates.${index}.service_type`}
																		>
																			{({ field, form, meta }) => (
																				<Select
																					onChange={(option) => {
																						form.setFieldValue(
																							field.name,
																							option
																						)
																						setSelectedServiceType(option)
																						setServiceTypeError(
																							!serviceTypeError
																						)
																					}}
																					options={options}
																					className="service_dropdown"
																					placeholder="Type"
																				/>
																			)}
																		</Field>
																	</div>

																	{values.associates &&
																		values.associates[index].service_type
																			.value === "AAR" && (
																			<>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.aar_system`}
																					>
																						{({ field, form, meta }) => (
																							<FetchSystems
																								value={
																									values.associates[index]
																										.aar_system
																								}
																								onChange={(option) => {
																									console.log(option)
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentAARSystem(option)
																									setCurrentAARSubSystem(null)
																									setCurrentAARServiceList(null)
																									setAARSystemError(
																										!aarSystemError
																									)
																								}}
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.aar_sub_system`}
																					>
																						{({ field, form, meta }) => (
																							<FetchSubSystems
																								value={
																									values.associates[index]
																										.aar_sub_system
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentAARSubSystem(option)
																									setCurrentAARServiceList(null)
																									setCurrentAARSystem({
																										label:
																											option.data
																												.service_component_name,
																										value:
																											option.data
																												.service_component_id,
																									})
																								}}
																								serviceComponentId={
																									values.associates[index]
																										.aar_system !== null
																										? values.associates[index]
																												.aar_system.value
																										: null
																								}
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.aar_service`}
																					>
																						{({ field, form, meta }) => (
																							<FetchServiceList
																								value={
																									values.associates[index]
																										.aar_service
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentAARServiceList(
																										option
																									)
																									setCurrentAARSystem({
																										label:
																											option.data
																												.service_component_name,
																										value:
																											option.data
																												.service_component_id,
																									})
																									setCurrentAARSubSystem({
																										label:
																											option.data
																												.sub_component_name,
																										value:
																											option.data
																												.sub_component_id,
																									})
																								}}
																								currentSystemId={
																									values.associates[index]
																										.aar_system !== null
																										? values.associates[index]
																												.aar_system.value
																										: null
																								}
																								currentSubSystemId={
																									values.associates[index]
																										.aar_sub_system !== null
																										? values.associates[index]
																												.aar_sub_system.value
																										: null
																								}
																							/>
																						)}
																					</Field>
																				</div>
																			</>
																		)}

																	{values.associates &&
																		values.associates[index].service_type
																			.value === "VMRS" && (
																			<>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.vmrs_system`}
																					>
																						{({ field, form, meta }) => (
																							<FetchVMRSSystems
																								value={
																									values.associates[index]
																										.vmrs_system
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									),
																										setCurrentVMRSSystem(option)
																									setCurrentVMRSAssembly(null)
																									setCurrentVMRSComponent(null)
																									setVMRSSystemError(
																										!vmrsSystemError
																									)
																								}}
																								className="service_dropdown"
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.vmrs_assembly`}
																					>
																						{({ field, form, meta }) => (
																							<FetchAssemblyList
																								value={
																									values.associates[index]
																										.vmrs_assembly
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentVMRSAssembly(option)
																									setCurrentVMRSComponent(null)
																									setCurrentVMRSSystem({
																										label:
																											option &&
																											option.data
																												.system_description,
																										value:
																											option &&
																											option.data.system_code,
																									})
																									setVMRSAssemblyError(
																										!vmrsAssemblyError
																									)
																								}}
																								currentVMRSSystemCode={
																									values.associates[index]
																										.vmrs_system !== null
																										? values.associates[index]
																												.vmrs_system.value
																										: null
																								}
																								className="service_dropdown"
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.vmrs_component`}
																					>
																						{({ field, form, meta }) => (
																							<FetchComponentList
																								value={
																									values.associates[index]
																										.vmrs_component
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentVMRSComponent(
																										option
																									)
																									setCurrentVMRSSystem({
																										label:
																											option &&
																											option.data
																												.system_description,
																										value:
																											option &&
																											option.data.system_code,
																									})
																									setCurrentVMRSAssembly({
																										label:
																											option &&
																											option.data
																												.assembly_description,
																										value:
																											option &&
																											option.data.assembly_code,
																									})
																								}}
																								currentVMRSSystemCode={
																									values.associates[index]
																										.vmrs_system !== null
																										? values.associates[index]
																												.vmrs_system.value
																										: null
																								}
																								currentVMRSAssemblyCode={
																									values.associates[index].vmrs_assembly !== null 
																									? values.associates[index].vmrs_assembly.value 
																									: null
																								}
																								className="service_dropdown"
																							/>
																						)}
																					</Field>
																				</div>
																			</>
																		)}
																</div>
															</div>
														</div>
													))}
											</div>
										)}
									/>
									{errors.arr && <div className="hint">{errors.arr}</div>}
								</div>
								<br />
								<div className="cta-buttons">
									<button type="submit" className="btn-regular btn-blue">
										Submit
									</button>
								</div>
							</Form>
						)
					}}
				</Formik>
			</div>
		</div>
	)
}

export default AddAssociates
