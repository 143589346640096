import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const FetchAssemblyList = (props) => {
  const [VMRSSystemCode, setVMRSSystemCode] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const accessToken = queryParams.get("userAuthToken");
  const [selectedValue, setSelectedValue] = useState(null);
  useEffect(() => {
    if (props.currentVMSRSSystemCode) {
      setVMRSSystemCode(props.currentVMSRSSystemCode)
    }

    if (typeof (props.value) === 'string') {
      setSelectedValue({
        value: props.id,
        label: props.value
      })
    } else {
      setSelectedValue(props.value)
    }

  }, [props.value, props.currentVMSRSSystemCode])

  const loadOptions = async (searchQuery, _, { page }) => {

    const response = await fetch(
      `/api/v1/service_codes/vmrs/assemblies?vmrs_system_code=${VMRSSystemCode ? VMRSSystemCode : ''}&search=${searchQuery}&page=${page}`, {
      method: 'GET',
      headers: {
        'Access-Token': accessToken,
      },
    }
    );
    const responseJSON = await response.json();

    let options = responseJSON.vmrs_services.map((option) => ({
      label: `${option.assembly_description}`,
      value: `${option.code}`,
      data: option,
    }))

    return {
      options: options,
      hasMore: responseJSON.meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#5195FB',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };

  return (
    <>
      {typeof (props.value) === 'string' ?
        <AsyncPaginate
          key={JSON.stringify(VMRSSystemCode)}
          defaultValue={{ value: props.value, label: props.value }}
          loadOptions={loadOptions}
          onChange={onChange}
          isSearchable={true}
          placeholder="Assembly"
          className='service_dropdown'
          styles={customStyles}
          debounceTimeout={500}
          additional={{
            page: 1,
          }}
        /> :
        <AsyncPaginate
          key={JSON.stringify(VMRSSystemCode)}
          value={props.value === null ? props.currentVMRSComponentList : props.value}
          loadOptions={loadOptions}
          onChange={onChange}
          isSearchable={true}
          placeholder="Assembly"
          className='service_dropdown'
          styles={customStyles}
          debounceTimeout={500}
          additional={{
            page: 1,
          }}
        />
      }
    </>
  );
};

FetchAssemblyList.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default FetchAssemblyList;
