import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const FetchAssemblyList = (props) => {
  const [VMRSSystemCode, setVMRSSystemCode] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const accessToken = queryParams.get("userAuthToken");

  useEffect(() => {
    setVMRSSystemCode(props.currentVMRSSystemCode)
  }, [props.currentVMRSSystemCode])

  const loadOptions = async (searchQuery, _, { page }) => {

    const response = await fetch(
      `/api/v1/service_codes/vmrs/assemblies?vmrs_system_code=${VMRSSystemCode ? VMRSSystemCode : ''}&search=${searchQuery}&page=${page}`, {
      method: 'GET',
      headers: {
        'Access-Token': accessToken,
      },
    }
    );
    const responseJSON = await response.json();

    let options = responseJSON.vmrs_services.map((option) => ({
      label: `${option.assembly_description}`,
      value: `${option.code}`,
      data: option,
    }))

    return {
      options: options,
      hasMore: responseJSON.meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: props.showError ? '#ff0000' : '#cccccc',
    }),
  };

  return (
    <>
      <AsyncPaginate
        key={JSON.stringify(VMRSSystemCode)}
        value={props.value}
        loadOptions={loadOptions}
        onChange={onChange}
        isSearchable={true}
        placeholder="Please select"
        className='service_dropdown async-dd'
        styles={customStyles}
        isClearable={true}
        debounceTimeout={500}
        additional={{
          page: 1,
        }}
      />

    </>
  );
};

FetchAssemblyList.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default FetchAssemblyList;
