import React, { useEffect, useState } from "react";
import { FlatfileButton } from "@flatfile/react";
import { instance } from "../core/api";

const ImportLaborRates = (props) => {
  const { userInfo, service_types } = props;
  const licenseKey = process.env.PLT_FLATFILE_LICENSE_KEY;
  
  let serviceTypeOptions = [
    { value: "aar", label: "aar" },
    { value: "vmrs", label: "vmrs" }
  ]

  if (service_types !== undefined) {
    Object.entries(service_types).forEach(([key, value]) => {
      serviceTypeOptions.unshift({
				value: `${key}`,
				label: `${value}`,
      });
    })
  }
  
  const flatfileLaborConfig = {
    type: "LaborRates",
    fields: [
      {
        label: "Regular Rate/Hour",
        key: "regular_hours_rate",
        validators: [
          {
            validate: "regex_matches",
            regex: "^(?=.*[0-9])[0-9]*[.]?[0-9]{1,2}$",
            error: "Accepts a number with two decimal values",
          },
        ],
      },
      {
        label: "After hour Rate / Hour",
        key: "after_hours_rate",
        validators: [
          {
            validate: "regex_matches",
            regex: "^(?=.*[0-9])[0-9]*[.]?[0-9]{1,2}$",
            error: "Accepts a number with two decimal values",
          },
        ],
      },
      {
        label: "Holiday Rate / Hour",
        key: "holiday_rate",
        validators: [
          {
            validate: "regex_matches",
            regex: "^(?=.*[0-9])[0-9]*[.]?[0-9]{1,2}$",
            error: "Accepts a number with two decimal values",
          },
        ],
      },
      {
        label: "Labor Rate for Services",
        key: "service_type",
        type: "select",
        options: serviceTypeOptions,
        validators: [{ validate: "required" }],
      },
      {
        label: "AAR System",
        key: "aar_system_ss_id",
      },
      {
        label: "AAR SubSystem",
        key: "aar_subsystem_ss_id",
      },
      {
        label: "AAR Service",
        key: "aar_service_ss_id",
      },
      {
        label: "AAR Mapping",
        key: "aar_mapping",
      },
      {
        label: "VMRS System",
        key: "vmrs_system_code",
      },
      {
        label: "VMRS Assembly",
        key: "vmrs_assembly_code",
      },
      {
        label: "VMRS Component",
        key: "vmrs_component_code",
      },
      {
        label: "VMRS Mapping",
        key: "vmrs_mapping",
      },
      {
        label: "Row Number",
        key: "source_row_id",
      },
    ],
    allowInvalidSubmit: false,
    managed: false,
    allowCustom: true,
    disableManualInput: true,
    title: 'Bulk Upload LaborRates',
  };

  return (
    <div>
      <FlatfileButton
        licenseKey={licenseKey}
        customer={{ userId: userInfo.username, email: userInfo.email }}
        settings={flatfileLaborConfig}
        className="btn-regular"
        fieldHooks={{
          service_type: async (values) => {
            let serverResults = [];
            await instance
              .get("/labor/standard_services")
              .then((res) => {
                if (res !== undefined) {
                  Object.entries(res.data).forEach(([key, value]) => {
                    serverResults.push(key)
                  });
                }
              })
              .catch((error) => {
                console.log(error)
              })
            let changeValues = [];
            let standard_services = [];
            values.forEach((item) => {
              if (standard_services.includes(item[0])) {
                changeValues.push([
                  {
                    info: [
                      {
                        message: "Duplicate Record",
                        level: "error",
                      },
                    ],
                  },
                  item[1],
                ]);
              }
              else if (item[0].trim() && (item[0].trim() != 'aar' && item[0].trim() != 'vmrs')){
                standard_services.push(item[0])
              }
            });
            return changeValues;
          },
        }}
        onData={async (results) => {
          const updatedImportData = results.validData.map((record, index) => {
            record["source_row_id"] = index + 2;
            if (record.aar_mapping.trim()) {
              let source = record.aar_mapping.split(/\-/);
              (record.aar_system_ss_id = source[0] || null),
                (record.aar_subsystem_ss_id = source[1] || null),
                (record.aar_service_ss_id = source[2] || null);
            }
            if (record.vmrs_mapping.trim()) {
              (record.vmrs_system_code = record.vmrs_mapping.match(
                /^\d{3,}/
              )[0]),
                (record.vmrs_assembly_code =
                  record.vmrs_mapping.match(/^\d{3,}-\d{3,}/)[0] || null),
                (record.vmrs_component_code =
                  (record.vmrs_mapping.match(/^\d{3,}-\d{3,}-\d{3,}$/) &&
                    record.vmrs_mapping.match(/^\d{3,}-\d{3,}-\d{3,}$/)[0]) ||
                  null);
            }
            return record;
          });
          instance
            .post(`/labor/upload`, { labor: updatedImportData })
            .then((res) => {
              if (res.status === 201) {
                props.reloadLaborIndex(true);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          return "Upload Successful!!";
        }}
      >
        {" "}
        Upload labor
      </FlatfileButton>
    </div>
  );
};

export default ImportLaborRates;
