import React, { Component } from "react";
import axios from "axios";
import Loader from "../components/Loader";
import Tab from "../components/core/Tabs";

class Home extends Component {
  state = { loading: true, errorStatus: null, userInfo: {} };

  fetchOnlineRequestAccess() {
    const queryParams = new URLSearchParams(window.location.search);    
    const accessToken = queryParams.get("userAuthToken");
    const haveOnlineFormAccess = "/api/v1/bootstrap";
    axios
      .get(haveOnlineFormAccess, {
        params: {},
        headers: {
          "Content-Type": "application/json",
          "Access-Token": accessToken,
        },
      })
      .then((res) => {
        this.setState({
          onlineRequest: true,
          loading: false,
          userInfo: res.data
        });
      })
      .catch((error) => {
        this.setState({
          onlineRequest: false,
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.fetchOnlineRequestAccess();
  }

  render() {
    if (this.state.loading) return <Loader />;

    if (this.state.onlineRequest) {
      return (
        <Tab userInfo={this.state.userInfo} />
      );
    }

  }
}

export default Home;
