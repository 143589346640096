import React, { useState, useEffect, useContext } from "react";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import PartsContainer from '../Parts/PartsContainer';
import LaborRates from '../Labor/LaborRates';
import ServiceBundles from '../ServiceBundles/ServiceBundles';

const ControlledTabs = (props) => {
  const {userInfo} = props;
  return (
    <div className="custom-tab-ui">
      <Tabs
        defaultActiveKey="parts"
        transition={false}
        id="noanim-tab-example"
        unmountOnExit={true}
      >
        <Tab eventKey="parts" title="Parts">
          <PartsContainer userInfo={userInfo}/>
        </Tab>
        <Tab eventKey="labor" title="Labor">
          <LaborRates userInfo={userInfo}/>
        </Tab>
        <Tab eventKey="service_bundle" title="Service Bundles">
          <ServiceBundles userInfo={userInfo}/>
        </Tab>
      </Tabs>
    </div>
  );
}

export default ControlledTabs;
