import React from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const FetchParts = (props) => {

  const queryParams = new URLSearchParams(window.location.search);
  const accessToken = queryParams.get("userAuthToken");

  const loadOptions = async (searchQuery, _, { page }) => {

    const response = await fetch(
      `/api/v1/parts/enabled?search=${searchQuery}&page=${page}`, {
      method: 'GET',
      headers: {
        'Access-Token': accessToken,
      },
    }
    );
    const responseJSON = await response.json();

    let options = responseJSON.parts.map((option) => ({
        label: `${option.part_code} - ${option.part_code_description}`,
        value: `${option.id}`,
        data: option,
    }))

    return {
      options: options,
      hasMore: responseJSON.meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: props.showError ? '#ff0000' : '#cccccc',
    }),
  };

  return (
    <AsyncPaginate
      value={props.value}
      loadOptions={loadOptions}
      onChange={onChange}
      isSearchable={true}
      placeholder="Select part"
      className='service_dropdown'
      styles={customStyles}
      debounceTimeout={300}
      isMulti
      additional={{
        page: 1,
      }}
    />
  );
};

FetchParts.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default FetchParts;
