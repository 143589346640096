import React, { useEffect, useState } from "react";
import { FlatfileButton } from "@flatfile/react";
import { instance } from "../core/api";

const ImportServiceBundles = (props) => {
  const { userInfo, equipmentTypes, oems } = props;
  let equipmentTypeOptions = [];
  let oemOptions = [{ value: "", label: "" }];

  Object.entries(oems).forEach(([key, value]) => {
    oemOptions.push({
      value: `${key}`,
      label: `${value}`,
    });
  });

  Object.entries(equipmentTypes).forEach(([key, value]) => {
    equipmentTypeOptions.push({
      value: `${value.code}`,
      label: `${value.description}`,
    });
  });

  let equipmentValues = [];
  Object.entries(equipmentTypeOptions).forEach(([key, value]) => {
    equipmentValues.push(value.value);
  });

  let oemValues = [];
  Object.entries(oemOptions).forEach(([key, value]) => {
    if (value) oemValues.push(value.value);
  });

  const licenseKey = process.env.PLT_FLATFILE_LICENSE_KEY;
  const flatfileRuleConfig = {
    type: "ServiceBundleRules",
    fields: [
      {
        label: "Equipment Type",
        key: "equipment_type",
        type: "select",
        options: equipmentTypeOptions,
        validators: [{ validate: "required" }],
      },
      {
        label: "OEM",
        key: "oem",
        type: "select",
        options: oemOptions,
      },
      {
        label: "Specification",
        key: "specification",
      },
      {
        label: "Services Type",
        key: "service_type",
      },
      {
        label: "AAR System",
        key: "aar_system_ss_id",
      },
      {
        label: "AAR SubSystem",
        key: "aar_subsystem_ss_id",
      },
      {
        label: "AAR Service",
        key: "aar_service_ss_id",
      },
      {
        label: "AAR Job",
        key: "aar_job_ss_id",
        validators: [
          {
          validate: "required_with",
          fields: ["aar_mapping"]
          }
        ]
      },
      {
        label: "AAR Mapping",
        key: "aar_mapping",
        validators: [
          { validate: "unique" }
        ],
      },
      {
        label: "VMRS System",
        key: "vmrs_system_code",
      },
      {
        label: "VMRS Assembly",
        key: "vmrs_assembly_code",
      },
      {
        label: "VMRS Component",
        key: "vmrs_component_code",
      },
      {
        label: "VMRS Work Accomplished",
        key: "vmrs_work_accomplished_code",
        validators: [
          {
          validate: "required_with",
          fields: ["vmrs_mapping"]
          }
        ]
      },
      {
        label: "VMRS Mapping",
        key: "vmrs_mapping"
      },
      {
        label: "Row ID",
        key: "source_row_id",
      },
      {
        label: "Default Parts",
        key: "default_parts",
      },
      {
        label: "Add-on Parts",
        key: "addons_parts",
      },
      {
        label: "ServiceBundle Parts",
        key: "service_bundle_parts",
      },
    ],
    allowInvalidSubmit: false,
    managed: false,
    allowCustom: true,
    disableManualInput: true,
    title: 'Bulk Upload ServiceBundles',
  };

  return (
    <div>
      <FlatfileButton
        licenseKey={licenseKey}
        customer={{ userId: userInfo.username, email: userInfo.email }}
        settings={flatfileRuleConfig}
        className="btn-regular"
        fieldHooks={{
          equipment_type: async (values) => {
            let changeValues = [];
            values.forEach((item) => {
              if (!equipmentValues.includes(item[0])) {
                changeValues.push([
                  {
                    info: [
                      {
                        message:
                          "Invalid EquipmentType, AAR should be Chassis/Container and VMRS should be Trailer/Power Unit",
                        level: "error",
                      },
                    ],
                  },
                  item[1],
                ]);
              }
            });
            return changeValues;
          },
          oem: async (values) => {
            let changeValues = [];
            values.forEach((item) => {
              if (!(item && oemValues.includes(item[0]))) {
                changeValues.push([
                  {
                    info: [
                      {
                        message: "Invalid OEM",
                        level: "error",
                      },
                    ],
                  },
                  item[1],
                ]);
              }
            });
            return changeValues;
          },
        }}
        onData={async (results) => {
          const updatedImportData = results.validData.map((record, index) => {
            let bundleParts = [];
            if (record.aar_mapping.trim()) {
              let source = record.aar_mapping.split(/\-/);
              record.service_type = "aar";
              record.aar_system_ss_id = source[0];
              record.aar_subsystem_ss_id = source[1] || null;
              record.aar_service_ss_id = source[2] || null;
            }
            if (record.vmrs_mapping.trim()) {
              record.service_type = "vmrs";
              (record.vmrs_system_code = record.vmrs_mapping.match(
                /^\d{3,}/
              )[0]),
                (record.vmrs_assembly_code =
                  record.vmrs_mapping.match(/^\d{3,}-\d{3,}/)[0] || null),
                (record.vmrs_component_code =
                  (record.vmrs_mapping.match(/^\d{3,}-\d{3,}-\d{3,}$/) &&
                    record.vmrs_mapping.match(/^\d{3,}-\d{3,}-\d{3,}$/)[0]) ||
                  null);
            }

            record.default_parts.split(/;/).forEach((part) => {
              if (part.trim()) {
                bundleParts.push({
                  part_id: part,
                  add_on: false,
                });
              }
            });
            record.addons_parts.split(/;/).forEach((part) => {
              if (part.trim()) {
                bundleParts.push({
                  part_id: part,
                  add_on: true,
                });
              }
            });
            record.service_bundle_parts = bundleParts;
            record.source_row_id = index + 2;
            return record;
          });
          instance
            .post(`/service_bundles/upload`, {
              service_bundle_rules: updatedImportData,
            })
            .then((res) => {
              if (res.status === 201) {
                props.reloadRulesIndex(true);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          return "Upload Successful!!";
        }}
      >
        {" "}
        Upload ServiceBundles
      </FlatfileButton>
    </div>
  );
};

export default ImportServiceBundles;
