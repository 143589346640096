import axios from 'axios';

const queryParams = new URLSearchParams(window.location.search);    
const accessToken = queryParams.get("userAuthToken");

export const instance = axios.create({
  baseURL: '/api/v1/',
  headers: { 'Access-Token': accessToken },
})

export const aar = axios.create({
  baseURL: '/api/v1/service_codes/aar/',
  headers: { 'Access-Token': accessToken },
})

export const partsEnabled = axios.create({
  baseURL: '/api/v1/parts/enabled',
  headers: { 'Access-Token': accessToken },
})

export const getEquipment = axios.create({
  baseURL: '/api/v1/service_codes/',
  headers: { 'Access-Token': accessToken },
})

export const bootstrap = axios.create({
  baseURL: '/api/v1/bootstrap',
  headers: { 'Access-Token': accessToken },
})
