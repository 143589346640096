import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { instance, bootstrap } from "../core/api"
import PopupServiceBundle from "../core/PopupServiceBundle"
import Table, { TableHeader, TableBody } from "./CRUDList"
import addPartsLabels from "./addPartsLabels"
import Pagination from "../core/Pagination"
import { omit } from "lodash"
import Popup from "../core/Popup"
import Loader from "react-loader-spinner"
import PartsAssociate from "./PartsAssociate"
import EditPartsAssociate from "./EditPartsAssociate"
import ImportParts from "./ImportParts"

const AddParts = (props) => {
	const { userInfo } = props
	const [search, setSearch] = useState("")
	const [currentData, setCurrentData] = useState([])
	const [currentPage, setCurrentPage] = useState(null)
	const [totalPages, setTotalPages] = useState(null)
	const [showMessage, setShowMessage] = useState(false)
	const [updateFailureMsg, setUpdateFailureMsg] = useState(false)
	const [noResponse, setNoResponse] = useState(false)
	const [partsData, setPartsData] = useState(null)
	const [metaData, setMetaData] = useState({})
	const [addNewRow, setAddNewRow] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [selectedRecord, setSelectedRecord] = useState("")
	const [checked, setChecked] = useState([])
	const [partsStatusMessage, setPartsStatusMessage] = useState("")
	const [showLoader, setShowLoader] = useState(false)
	const [UOMList, setUOMList] = useState({})
	const [showDropdown, setShowDropdown] = useState(false)

	const [deleteSuccess, setDeleteSuccess] = useState(false)
	const [enableStatusMessage, setEnableStatusMessage] = useState(false)
	const [addRecord, setAddRecord] = useState(false)
	const [isAssociatePopupOpen, setIsAssociatePopupOpen] = useState(false)
	const [isEditAssociatePopupOpen, setIsEditAssociatePopupOpen] =
		useState(false)
	const [selectedPart, setSelectedPart] = useState(null)
	const [recordEditSuccess, setRecordEditSuccess] = useState(false)
	const [errorList, setErrorList] = useState([])
	const [openErrorPopup, setOpenErrorPopup] = useState(false)
	const [downloadStarted, setDownloadStarted] = useState(false)
	const [hq_btn_status, set_hq_btn_status] = useState(null)
	const [showLoaderForListing, setShowLoaderForListing] = useState(true)
	const [showEmptyMsg, setShowEmptyMsg] = useState(false)

	let current_page = metaData.current_page
	let next_page = metaData.next_page
	let per_page = metaData.per_page
	let total_count = metaData.total_count
	let total_pages = metaData.total_pages

	const node = useRef()

	useEffect(() => {
		document.addEventListener("mousedown", handleClick)
		bootstrap
			.get("/")
			.then((res) => {
				if (res.data !== undefined) {
					const { tenant } = res.data;
					set_hq_btn_status(tenant)
				}
			})
			.catch((error) => {
				console.log(error)
			})
		instance
			.get(`/parts/?page=${metaData.current_page}&search=${search}`)
			.then((res) => {
				setShowLoaderForListing(true)
				if (res.status === 200 && res.data.parts.length > 0) {
					setShowLoaderForListing(false)
					let data = res.data
					setPartsData(data.parts)
					setMetaData(data.meta)
					setShowEmptyMsg(false)
				} else {
					setShowLoaderForListing(false)
					setShowEmptyMsg(true)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		instance.get("/parts/uoms").then((res) => {
			if (res !== undefined) {
				setUOMList(res.data)
			}
		})

		const timer = setTimeout(() => {
			setShowMessage(false)
			setRecordEditSuccess(false)
			setAddRecord(false)
			setDeleteSuccess(false)
			setEnableStatusMessage(false)
			setDownloadStarted(false)
		}, 1000)

		// To clear or cancel a timer, you call the clearTimeout(); method,
		// passing in the timer object that you created into clearTimeout().

		//return () => clearTimeout(timer)
		return () => {
			document.removeEventListener("mousedown", handleClick)
			clearTimeout(timer)
		}
	}, [showMessage, userInfo])

	const updateParts = (id, payload) => {
		instance
			.put(`/parts/${id}`, { parts: { ...payload } })
			.then((res) => {
				if (res.status === 304 && res.status === 422) {
					setUpdateFailureMsg(!updateFailureMsg)
				}
				if (res.status === 200) {
					setShowMessage(!showMessage)
					setRecordEditSuccess(!recordEditSuccess)
					instance
						.get(`/parts/?page=${metaData.current_page}`)
						.then((res) => {
							setShowLoader(!showLoader)
							if (res.status === 200) {
								setShowLoader(false)
							}
							if (res !== undefined) {
								let data = res.data
								setPartsData(data.parts)
								setMetaData(data.meta)
							}
						})
						.catch((error) => {
							console.log(error)
						})
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const deletePart = (id) => {
		instance
			.delete(`/parts/${id}`)
			.then((res) => {
				if (res.status === 200) {
					setShowMessage(true)
					setDeleteSuccess(true)
					let currPage
					let remainder = metaData.total_count % 10
					if (remainder === 1) {
						currPage = metaData.total_pages - 1
					} else {
						currPage = metaData.current_page
					}
					instance
						.get(`/parts/?page=${currPage}&search=${search}`)
						.then((res) => {
							if (res !== undefined) {
								let data = res.data
								setPartsData(data.parts)
								setMetaData(data.meta)
							}
						})
						.catch((error) => {
							console.log(error)
						})
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleSearchInput = (e) => {
		e.preventDefault()
		let searchText = e.target.value
		setSearch(searchText)
		if (search === "" || search.length === 1) {
			instance
				.get("/parts")
				.then((res) => {
					if (res !== undefined) {
						let data = res.data
						setPartsData(data.parts)
						setMetaData(data.meta)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		} else {
			fetchParts(search, metaData.current_page)
		}
	}

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			fetchParts(search, metaData.current_page)
		}
	}

	const fetchParts = (search, current_page) => {
		instance
			.get("/parts", {
				params: {
					search: search,
					page: current_page,
				},
			})
			.then((res) => {
				setPartsData(null)
				let parts = res.data
				if (parts !== undefined || parts.length > 0) {
					setPartsData(parts.parts)
					setMetaData(parts.meta)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleSearchSubmit = (e) => {
		e.preventDefault()
		if (search !== "") {
			instance
				.get("/parts", {
					params: {
						search: search.replace(/\s+/g, "_"),
						page: metaData.current_page,
					},
				})
				.then((res) => {
					if (res !== undefined) {
						let data = res.data
						setPartsData(data.parts)
						setMetaData(data.meta)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const onSaveClick = (rowValue, headerValue, id, updatedRes, errorList) => {
		setErrorList(errorList)
		if (errorList && errorList.length === 0) {
			if (Object.keys(updatedRes).length !== 0) {
				let filterObj = omit(updatedRes, "id", "hq")
				updateParts(id, filterObj)
			} else {
				setNoResponse(true)
			}
		} else {
			setOpenErrorPopup(!openErrorPopup)
		}
	}

	const showNewRow = () => {
		setAddNewRow(!addNewRow)
		setShowEmptyMsg(false)
	}

	const removeRow = () => {
		setAddNewRow(false)
	}

	const onRemoveClick = (id) => {
		setSelectedRecord(id)
		togglePopup()
	}

	const handleRemovePart = () => {
		deletePart(selectedRecord)
		togglePopup()
	}

	const togglePopup = () => {
		setIsOpen(!isOpen)
	}

	const toggleAssociatePopup = () => {
		setIsAssociatePopupOpen(!isAssociatePopupOpen)
	}
	const toggleEditAssociatePopup = () => {
		setIsEditAssociatePopupOpen(!isEditAssociatePopupOpen)
	}
	const toggleErrorPopup = () => {
		setOpenErrorPopup(!openErrorPopup)
	}

	const onAssociateClick = (selectedPart) => {
		if (selectedPart.parts_services !== undefined) {
			toggleEditAssociatePopup()
		} else {
			toggleAssociatePopup()
		}
		setSelectedPart(selectedPart)
	}

	const newRowData = (newRowData) => {
		instance
			.post(`/parts`, { parts: { ...newRowData } })
			.then((res) => {
				if (res.status === 201) {
					instance
						.get("/parts")
						.then((res) => {
							if (res !== undefined) {
								let data = res.data
								setPartsData(data.parts)
								setMetaData(data.meta)
								setAddRecord(true)
								setShowMessage(true)
							}
						})
						.catch((error) => {
							console.log(error)
						})
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleSelect = (id, status) => {
		let selectCheckbox = [...checked]
		const index = selectCheckbox.indexOf(id)
		if (index === -1) {
			selectCheckbox.push(id)
		} else {
			selectCheckbox.splice(index, 1)
		}
		setChecked(selectCheckbox)

		instance
			.put(`/parts/${id}/toggle?enabled=${status}`)
			.then((res) => {
				if (res.status === 200) {
					setPartsStatusMessage(res.data.message)
					setEnableStatusMessage(true)
					setShowMessage(true)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const onPageChanged = (data) => {
		const { currentPage, totalPages, pageLimit } = data
		const offset = (currentPage - 1) * pageLimit
		const currData =
			partsData !== undefined ? partsData.slice(offset, offset + pageLimit) : ""
		setCurrentPage(currentPage)
		setTotalPages(totalPages)
		setCurrentData(currData)

		if (search !== "") {
			instance
				.get(`/parts/`, {
					params: {
						page: currentPage,
						search: search,
					},
				})
				.then((res) => {
					let parts = res.data
					if (parts !== undefined) {
						setPartsData(parts.parts)
						setMetaData(parts.meta)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		} else {
			instance
				.get(`/parts/`, {
					params: {
						page: currentPage,
					},
				})
				.then((res) => {
					let parts = res.data
					if (parts !== undefined) {
						setPartsData(parts.parts)
						setMetaData(parts.meta)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const closePopup = (status) => {
		if (status) {
			setIsAssociatePopupOpen(false)
			setIsEditAssociatePopupOpen(false)
			instance
				.get(`/parts/?page=${metaData.current_page}`)
				.then((res) => {
					setShowLoader(!showLoader)
					if (res.status === 200) {
						setShowLoader(false)
					}
					if (res !== undefined) {
						let data = res.data
						setPartsData(data.parts)
						setMetaData(data.meta)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const reloadPartsIndex = (arg) => {

		if (arg) {
			instance
				.get(`/parts/?page=${current_page}`)
				.then((res) => {
					setShowLoader(!showLoader)
					if (res.status === 200) {
						setShowEmptyMsg(false)
						setShowLoader(false)
					}
					if (res !== undefined) {
						let data = res.data
						setPartsData(data.parts)
						setMetaData(data.meta)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const downloadParts = () => {
		instance
			.get("/parts/download", { responseType: "arraybuffer" })
			.then((res) => {
				if (res.status === 200) {
					setShowMessage(true)
					setDownloadStarted(true)
				}
				const url = window.URL.createObjectURL(new Blob([res.data]))
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", "PartsUploadTemplate.csv")
				document.body.appendChild(link)
				link.click()
				link.parentNode.removeChild(link)
				setShowDropdown(false)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const downloadReferenceExcel = () => {
		instance
			.get("/service_codes/download", { responseType: "arraybuffer" })
			.then((res) => {
				if (res.status === 200) {
					setShowMessage(true)
					setDownloadStarted(true)
				}
				const url = window.URL.createObjectURL(new Blob([res.data]))
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", "ReferenceSheet.xls")
				document.body.appendChild(link)
				link.click()
				link.parentNode.removeChild(link)
				setShowDropdown(false)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleDropdown = (e) => {
		e.preventDefault()
		setShowDropdown(!showDropdown)
	}

	const handleClick = (e) => {
		if (node.current.contains(e.target)) {
			// inside click
			return
		}
		// outside click
		setShowDropdown(false)
	}

	const getHQParts = (e) => {
		e.preventDefault()
		let tenantHQParts = {
			tenants: {
				use_hq_parts: true,
			},
		}
		instance
			.put(`/tenants/${userInfo.tenant.id}`, tenantHQParts, {
				headers: { "Content-Type": "application/json" },
			})
			.then((res) => {
				if (res.status === 200) {
					setShowEmptyMsg(false)
					document.querySelector(".btnHq").style.display = "none"
					instance
						.get(`/parts/?page=${metaData.current_page}&search=${search}`)
						.then((res) => {
							setShowLoader(!showLoader)
							if (res.status === 200) {
								setShowLoader(false)
							}
							if (res !== undefined) {
								let data = res.data
								setPartsData(data.parts)
								setMetaData(data.meta)
							}
						})
						.catch((error) => {
							console.log(error)
						})
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	return (
		<>
			<div className="parts-list">
				{showMessage && addRecord ? (
					<div className={"message"}>Parts updated successfully</div>
				) : null}

				{showMessage && deleteSuccess ? (
					<div className={`message`}>Record deleted successfully</div>
				) : null}

				{showMessage && updateFailureMsg && noResponse && (
					<div className={`message`}>No updates found</div>
				)}

				{showMessage && enableStatusMessage && (
					<div className={`message`}>{partsStatusMessage}</div>
				)}

				{showMessage && recordEditSuccess && (
					<div className={`message`}>Record Edited successfully</div>
				)}

				{showMessage && downloadStarted && (
					<div className={`message`}>Download Started...</div>
				)}

				<div className="col-xs-12">
					<div className="listing-header">
						<div>
							<button className="btn-regular" onClick={showNewRow}>
								Add Parts / Row
							</button>
						</div>
						<div className="menu-outer" ref={node}>
							<button
								className="btn-regular btn-dd"
								onClick={(e) => handleDropdown(e)}
							>
								Download Template <i className="fas fa-sort-down"></i>
							</button>
							{showDropdown && (
								<div className="dd-container">
									<ul>
										<li onClick={downloadParts}>CSV Template</li>
										<li onClick={downloadReferenceExcel}>Reference Sheet</li>
									</ul>
								</div>
							)}
						</div>
						<div>
							{Object.keys(UOMList).length ? (
								<div>
									<ImportParts
										reloadPartsIndex={reloadPartsIndex}
										UOMList={UOMList}
										userInfo={userInfo}
									/>
								</div>
							) : null}
						</div>
						{hq_btn_status &&
							hq_btn_status.use_hq_parts === false &&
							hq_btn_status &&
							hq_btn_status.hq_id !== null && (
								<div>
									<button
										className="btn-regular btnHq"
										onClick={(e) => getHQParts(e)}
									>
										Import HQ Parts
									</button>
								</div>
							)}
						<div>
							<input
								type="text"
								className="text-box"
								placeholder="Type and search"
								onChange={(e) => handleSearchInput(e)}
								onKeyPress={handleKeyPress}
							/>
							<button
								className="btn-search"
								id="btn-search"
								onClick={(e) => handleSearchSubmit(e)}
							>
								Search
							</button>
						</div>
					</div>
				</div>

				<Table>
					<TableHeader headerData={addPartsLabels} />
					{showLoaderForListing && (
						<div className="preloader">
							<Loader type="Puff" color="#00BFFF" height={50} width={50} />
						</div>
					)}
					<TableBody
						headerData={addPartsLabels}
						rowData={partsData || []}
						onSaveClick={{ onSaveClick: onSaveClick }}
						onRemoveClick={{ onRemoveClick: onRemoveClick }}
						onAssociateClick={{ onAssociateClick: onAssociateClick }}
						addNewRow={addNewRow}
						newRowData={newRowData}
						removeRow={removeRow}
						onSelect={handleSelect}
						UOMList={UOMList}
						checked={checked}
						showEmptyMsg={showEmptyMsg}
					/>
				</Table>
				<div className="pagination-container">
					{total_count !== undefined && total_count > 10 ? (
						<>
							<div className="col-4 sec-1">
								Page: {metaData.current_page} / {metaData.total_pages}
							</div>
							<div className="col-4 sec-2">
								<Pagination
									totalRecords={metaData.total_count}
									pageLimit={metaData.per_page}
									pageNeighbours={1}
									onPageChanged={onPageChanged}
									totalPages={Math.ceil(metaData.total_count / 10)}
									currentPage={metaData.current_page}
								/>
							</div>
							<div className="col-4 sec-3">
								Total Rows: {metaData.total_count}
							</div>
						</>
					) : null}
				</div>

				{isOpen && (
					<Popup
						content={
							<>
								<div className="popup-content-container">
									<div className="popup-title">Delete confirmation</div>
									<div className="popup-body">
										<h3>Are you sure?</h3>
										<p>This action cannot be undone!</p>
									</div>
									<div className="popup-footer">
										<p className="action-buttons">
											<span>
												<button
													className="btn-regular btn-grey"
													onClick={togglePopup}
												>
													Cancel
												</button>
											</span>
											<span>
												<button
													className="btn-regular btn-blue"
													onClick={handleRemovePart}
												>
													Confirm
												</button>
											</span>
										</p>
									</div>
								</div>
							</>
						}
						handleClose={togglePopup}
					/>
				)}

				{isAssociatePopupOpen && (
					<PopupServiceBundle
						content={
							<>
								<PartsAssociate
									selectedPart={selectedPart}
									closePopup={closePopup}
								/>
							</>
						}
						handleClose={toggleAssociatePopup}
					/>
				)}

				{isEditAssociatePopupOpen && (
					<PopupServiceBundle
						content={
							<>
								<EditPartsAssociate
									selectedPart={selectedPart}
									closePopup={closePopup}
								/>
							</>
						}
						handleClose={toggleEditAssociatePopup}
					/>
				)}
				{openErrorPopup && (
					<Popup
						content={
							<>
								<div className="popup-content-container">
									<div className="popup-title error-popup-title">
										Please fill the below missing values
									</div>
									<div className="popup-body">
										<div className="error-list">
											<ul>
												{errorList &&
													errorList.map((error, index) => {
														return <li key={index}>{error.err}</li>
													})}
											</ul>
										</div>
									</div>
									<div className="popup-footer">
										<p className="action-buttons">
											<span>
												<button
													className="btn-regular btn-grey"
													onClick={toggleErrorPopup}
												>
													Close
												</button>
											</span>
										</p>
									</div>
								</div>
							</>
						}
						handleClose={toggleErrorPopup}
					/>
				)}
			</div>
		</>
	)
}

AddParts.propTypes = {
	partsData: PropTypes.array,
	fetchParts: PropTypes.func,
}

export default AddParts
