const addPartsLabels = [
  {
    name: 'part_group_code',
    label: 'Part Group Code',
    isEdit: true,
  },
  {
    name: 'part_group_description',
    label: 'Part Group Description',
    isEdit: true,
    isVline: true,
    isDesc: true,
    isTooltip: true,
  },
  {
    name: 'part_code',
    label: 'Part Code',
    isEdit: true,
    isVline: true,
  },
  {
    name: 'part_code_description',
    label: 'Part Code Description',
    isEdit: true,
    isVline: true,
    isDesc: true,
    isTooltip: true
  },
  {
    name: 'uom',
    label: 'UOM',
    isEdit: true,
    isVline: true,
    isUOM: true,
  },
  {
    name: "mfr_part",
    label: "Mfr. Part",
    isEdit: true,
    isVline: true,
  },
  {
    name: 'part_price',
    label: 'Part Price',
    isEdit: true,
    isVline: true,
    isCurrency: true
  },
  {
    name: 'rating',
    label: 'Rating',
    isEdit: true,
    isVline: true,
  },
  {
    name: 'specification',
    label: 'Specification',
    isEdit: true,
    isVline: true,
  },
  // {
  //   name: 'hq',
  //   label: '',
  //   isVline: false,
  // },
  {
    name: 'show',
    label: 'Show',
    isCheckbox: true,
    enabled: 'enabled',
    isVline: true,
  },
  {
    name: 'parts_service',
    label: 'Associate',
    isVline: true,
    isAssociate: true,
    associateClickFunction: 'onAssociateClick',
  },
  {
    name: 'edit',
    label: '',
    isBtn: true,
  },
  {
    name: 'save',
    label: '',
    saveClickFunction: 'onSaveClick',
    showIcon: true,
    isBtn: true,
  },
  {
    name: 'delete',
    label: 'Action',
    removeClickFunction: 'onRemoveClick',
    isBtn: true,
  },
];

export default addPartsLabels;
