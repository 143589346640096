import React, { useState, Fragment, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import withLoader from "../core/withLoader"
import Select from "react-select"
import ReactTooltip from "react-tooltip"
import cx from "classnames"
import FetchSystems from "./FetchSystems"
import FetchSubSystems from "./FetchSubSystems"
import FetchServiceList from "./FetchServiceList"

import FetchVMRSSystems from "./FetchVMRSSystems"
import FetchAssemblyList from "./FetchAssemblyList"
import FetchComponentList from "./FetchComponentList"

import CurrencyInput from "../core/CurrencyInput"

import { omit } from "lodash"

export const Row = ({ children, rowClassName, id, onRowClick }) => {
	return (
		<>
			<div
				className={cx("table-row", rowClassName)}
				onClick={() => onRowClick(id)}
			>
				{children}
			</div>
		</>
	)
}

Row.propTypes = {
	children: PropTypes.node,
	rowClassName: PropTypes.string,
	onRowClick: PropTypes.func,
	id: PropTypes.number,
}
Row.defaultProps = {
	rowClassName: "",
	onRowClick: () => {},
}

export const Cell = ({
	headerData,
	rowData,
	isRowClickable,
	onRowClick,
	onSaveClick,
	onRemoveClick,
	service_types,
}) => {
	const [rowId, setRowId] = useState(null)
	// const [resObj, setResObj] = useState({});
	const [showTextBox, setShowTextBox] = useState(false)
	const [showEdit, setShowEdit] = useState(false)
	const [showClose, setShowclose] = useState(false)
	const [selectedOption, setSelectedOption] = useState("")
	const [isError, setIsError] = useState(false)
	const [showAARSystemDD, setShowAARSystemDD] = useState(false)
	const [showVMRSSystem, setShowVMRSSystemDD] = useState(false)

	const [currentSystem, setCurrentSystem] = useState(null)
	const [currentSubSystem, setCurrentSubSystem] = useState(null)
	const [currentServiceList, setCurrentServiceList] = useState(null)

	const [currentVMRSSystem, setCurrentVMRSSystem] = useState(null)
	const [currentVMRSAssemblyList, setCurrentVMRSAssemblyList] = useState(null)
	const [currentVMRSComponentList, setCurrentVMRSComponentList] = useState(null)
	const [resObj, setResObj] = useState({})

	const columnClassNames = cx(`table-cell`)
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: "#fff",
			minHeight: "30px",
			height: "30px",
			boxShadow: state.isFocused ? null : null,
		}),

		valueContainer: (provided, state) => ({
			...provided,
			height: "30px",
			padding: "0 6px",
		}),

		input: (provided, state) => ({
			...provided,
			margin: "0px",
		}),
		indicatorSeparator: (state) => ({
			display: "none",
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			height: "30px",
		}),
	}

	let options = [
		{ value: "aar", label: "AAR" },
		{ value: "vmrs", label: "VMRS" },
	]

	if (service_types !== undefined) {
		Object.entries(service_types).forEach(([key, value]) =>
			options.unshift({
				value: `${key}`,
				label: `${value}`,
			})
		)
	}

	const onChangeSystemSelect = (item) => {
		setCurrentSystem(item)
		setCurrentSubSystem(null)
		setCurrentServiceList(null)
		setResObj({
			...resObj,
			aar_system: {
				service_type: "aar",
				name: item.data.name,
				level: "system",
				code: null,
				source_system_id: item.data.id,
			},
		})
	}

	const onChangeSubSystemSelect = (item) => {
		setCurrentSubSystem(item)
		setResObj({
			...resObj,
			aar_subsystem: {
				service_type: "aar",
				name: item.data.name,
				level: "subsystem",
				code: null,
				source_system_id: item.data.id,
			},
		})
	}

	const onChangeServiceListSelect = (item) => {
		setCurrentServiceList(item)
		setResObj({
			...resObj,
			aar_service: {
				service_type: "aar",
				name: item.label,
				level: "service",
				code: null,
				source_system_id: item.data.id,
			},
		})
	}

	const onChangeVMRSSystemSelect = (item) => {
		setCurrentVMRSSystem(item)
		setCurrentVMRSAssemblyList(null);
		setCurrentVMRSComponentList(null)
		setResObj({
			...resObj,
			vmrs_system: {
				service_type: "vmrs",
				name: item.data.system_description,
				level: "system",
				code: item.data.code,
				source_system_id: null,
			},
		})
	}

	const onChangeVMRSAssemblyListSelect = (item) => {
		setCurrentVMRSAssemblyList(item)
		setCurrentVMRSComponentList(null);
		if (currentVMRSSystem && currentVMRSSystem.data === undefined)
		setCurrentVMRSSystem({
			label: item.data.system_description,
			value: item.data.system_code
		})
		setResObj({
			...resObj,
			vmrs_assembly: {
				service_type: "vmrs",
				name: item.data.assembly_description,
				level: "assembly",
				code: item.data.code,
				source_system_id: null,
			},
		})
	}
	const onChangeVMRSComponentListSelect = (item) => {
		setCurrentVMRSComponentList(item)
		setResObj({
			...resObj,
			vmrs_component: {
				service_type: "vmrs",
				name: item.data.component_description,
				level: "component",
				code: item.data.code,
				source_system_id: null,
			},
		})
	}

	let filteredResObj

	if (resObj.service_type !== "vmrs" && resObj.service_type !== "aar") {
		filteredResObj = omit(resObj, "aar")
	}

	const closeEdit = () => {
		setShowTextBox(false)
	}

	const formatPrice = (val) => {
		let formatValue = val && val.toString().replace("$", "").replace(",", "")
		return formatValue
	}

	const handleRowData = (e) => {
		let value = formatPrice(e.target.value)
		setResObj({ ...resObj, [e.target.name]: value })
	}

	return rowData && rowData.length ? (
		rowData.map((rowValue, rowKey) => {
			return (
				<Row
					key={rowKey}
					rowClassName={isRowClickable ? "clickable-row" : null}
					id={rowValue.id}
					onRowClick={onRowClick}
					updatedData={resObj}
				>
					{headerData.map(
						(headerValue) =>
							headerValue.name && (
								<div
									key={`${headerValue.name}${rowKey}`}
									className={
										headerValue.className === "service_type"
											? cx(columnClassNames) + " service_type"
											: headerValue.className === "labor_rates"
											? cx(columnClassNames) + " labor_rates"
											: headerValue.className === "icon"
											? cx(columnClassNames) + " icon"
											: cx(columnClassNames)
									}
									onClick={(e) => {
										e.preventDefault()
										if (headerValue.name === "edit") {
											if (showTextBox) {
											} else {
												setRowId(rowValue.id)
												if (rowValue.service_type === "AAR") {
													setResObj({
														service_type: rowValue.service_type,
														regular_hours_rate: rowValue.regular_hours_rate,
														holiday_rate: rowValue.holiday_rate,
														after_hours_rate: rowValue.after_hours_rate,
														aar_system: {
															service_type: "aar",
															name:
																rowValue.aar.system !== undefined
																	? rowValue.aar.system.name
																	: null,
															level: "system",
															code: null,
															source_system_id:
																rowValue.aar.system !== undefined
																	? rowValue.aar.system.source_system_id
																	: null,
														},
														aar_subsystem: {
															service_type: "aar",
															name:
																rowValue.aar.subsystem !== undefined
																	? rowValue.aar.subsystem.name
																	: null,
															level: "subsystem",
															code: null,
															source_system_id:
																rowValue.aar.subsystem !== undefined
																	? rowValue.aar.subsystem.source_system_id
																	: null,
														},
														aar_service: {
															service_type: "aar",
															name:
																rowValue.aar.service !== undefined
																	? rowValue.aar.service.name
																	: null,
															level: "service",
															code: null,
															source_system_id:
																rowValue.aar.service !== undefined
																	? rowValue.aar.service.source_system_id
																	: null,
														},
													})
												}

												if (rowValue.service_type === "VMRS") {
													setResObj({
														service_type: rowValue.service_type,
														regular_hours_rate: rowValue.regular_hours_rate,
														holiday_rate: rowValue.holiday_rate,
														after_hours_rate: rowValue.after_hours_rate,
														vmrs_system: {
															service_type: "vmrs",
															name:
																rowValue.vmrs.system !== undefined
																	? rowValue.vmrs.system.name
																	: null,
															level: "system",
															code: 
															  rowValue.vmrs.system !== undefined
															    ? rowValue.vmrs.system.code
															    : null,
															source_system_id: null,
														},
														vmrs_assembly: {
															service_type: "vmrs",
															name:
																rowValue.vmrs.assembly !== undefined
																	? rowValue.vmrs.assembly.name
																	: null,
															level: "assembly",
															code: 
															  rowValue.vmrs.assembly !== undefined
															    ? rowValue.vmrs.assembly.code
															    : null,
															source_system_id: null,
														},
														vmrs_component: {
															service_type: "vmrs",
															name:
																rowValue.vmrs.component !== undefined
																	? rowValue.vmrs.component.name
																	: null,
															level: "component",
															code: 
															  rowValue.vmrs.component !== undefined
																	? rowValue.vmrs.component.code
																	: null,
															source_system_id: null,
														},
													})
												}

												if (
													rowValue.service_type !== "AAR" &&
													rowValue.service_type !== "VMRS"
												) {
													setResObj({
														service_type: rowValue.service_type,
														regular_hours_rate: rowValue.regular_hours_rate,
														holiday_rate: rowValue.holiday_rate,
														after_hours_rate: rowValue.after_hours_rate,
													})
												}
												setShowTextBox(true)
												setShowclose(true)
												if (rowValue.aar !== undefined) {
													setSelectedOption("AAR")
												}
												if (rowValue.vmrs !== undefined) {
													setSelectedOption("VMRS")
												}
											}
										}

										if (
											(rowId === rowValue.id && headerValue.name) === "save"
										) {
											let formIsValid = true
											let errorMessage = []

											let regularHoursRate = filteredResObj.regular_hours_rate
											let holidayRate = filteredResObj.holiday_rate
											let afterHoursRate = filteredResObj.after_hours_rate

											if (
												regularHoursRate === 0 &&
												holidayRate === 0 &&
												afterHoursRate === 0
											) {
												formIsValid = false
												errorMessage.push({
													err: "Please fill at lease one labor rate per hour",
												})
											}

											if (
												regularHoursRate === "" &&
												holidayRate === 0 &&
												afterHoursRate === 0
											) {
												formIsValid = false
												errorMessage.push({
													err: "Please fill at lease one labor rate per hour",
												})
											}

											if (
												regularHoursRate === 0 &&
												holidayRate === "" &&
												afterHoursRate === 0
											) {
												formIsValid = false
												errorMessage.push({
													err: "Please fill at lease one labor rate per hour",
												})
											}

											if (
												regularHoursRate === 0 &&
												holidayRate === 0 &&
												afterHoursRate === ""
											) {
												formIsValid = false
												errorMessage.push({
													err: "Please fill at lease one labor rate per hour",
												})
											}

											if (
												regularHoursRate === "" &&
												holidayRate === "" &&
												afterHoursRate === ""
											) {
												formIsValid = false
												errorMessage.push({
													err: "Please fill at lease one labor rate per hour",
												})
											}

											if (formIsValid) {
												setShowTextBox(false)
											}

											onSaveClick[headerValue.saveClickFunction](
												rowValue,
												rowValue.id,
												filteredResObj,
												errorMessage
											)
										}

										if (headerValue.name === "delete") {
											onRemoveClick[headerValue.removeClickFunction](
												rowValue.id
											)
										}
									}}
								>
									{rowValue ? (
										headerValue.name === "edit" ? (
											<>
												{rowId === rowValue.id && showTextBox ? (
													<span className="icon-close" onClick={closeEdit}>
														<strong>X</strong>
													</span>
												) : (
													""
												)}{" "}
												<i className="fas fa-pencil-alt"></i>
											</>
										) : headerValue.name === "delete" ? (
											<i className="far fa-trash-alt"></i>
										) : headerValue.name === "save" ? (
											<i className="fas fa-save"></i>
										) : rowId === rowValue.id ? (
											showTextBox ? (
												headerValue.name === "service_type" ? (
													<>
														<div className="table-cell">
															<span
																className={
																	service_types === "aar"
																		? "aar"
																		: service_types === "vmrs"
																		? "vmrs"
																		: ""
																}
															>
																{rowValue.service_type}
															</span>
														</div>

														{selectedOption === "AAR" &&
															rowValue.aar !== undefined && (
																<>
																	<FetchSystems
																		value={
																			currentSystem !== null
																				? currentSystem
																				: rowValue.aar.system !== undefined
																				? rowValue.aar.system.name
																				: currentSystem
																		}
																		onChange={(system) =>
																			onChangeSystemSelect(system)
																		}
																		className="service_dropdown"
																		styles={customStyles}
																	/>
																	<FetchSubSystems
																		value={
																			currentSystem !== null
																				? currentSubSystem
																				: rowValue.aar.subsystem !== undefined
																				? rowValue.aar.subsystem.name
																				: currentSubSystem
																		}
																		onChange={(subSystem) =>
																			onChangeSubSystemSelect(subSystem)
																		}
																		styles={customStyles}
																		className="service_dropdown"
																		serviceComponentId={
																			currentSystem !== null
																				? currentSystem && currentSystem.data.id
																				: rowValue.aar.system !== undefined
																				? rowValue.aar.system.source_system_id
																				: null
																		}
																	/>
																	<FetchServiceList
																		value={
																			currentSystem !== null
																				? currentServiceList
																				: rowValue.aar.service !== undefined
																				? rowValue.aar.service.name
																				: currentServiceList
																		}
																		onChange={(serviceList) =>
																			onChangeServiceListSelect(serviceList)
																		}
																		styles={customStyles}
																		className="service_dropdown"
																		currentSystemCode={
																			currentSystem !== null
																				? currentSystem.data.id
																				: rowValue.aar.system !== undefined
																				? rowValue.aar.system.source_system_id
																				: null
																		}
																		currentSubSystemCode={
																			currentSubSystem !== null
																				? currentSubSystem.data.id
																				: rowValue.aar.subsystem !== undefined
																				? rowValue.aar.subsystem.source_system_id
																				: null
																		}
																	/>
																</>
															)}
														{selectedOption === "VMRS" &&
															rowValue.vmrs !== undefined && (
																<>
																	<FetchVMRSSystems
																		value={
																			currentVMRSSystem !== null
																				? currentVMRSSystem
																				: rowValue.vmrs.system !== undefined
																				? rowValue.vmrs.system.name
																				: currentVMRSSystem
																		}
																		onChange={(system) =>
																			onChangeVMRSSystemSelect(system)
																		}
																		className="service_dropdown"
																		styles={customStyles}
																	/>
																	<FetchAssemblyList
																		value={
																			currentVMRSSystem !== null
																				? currentVMRSAssemblyList
																				: rowValue.vmrs.assembly !== undefined
																				? rowValue.vmrs.assembly.name
																				: currentVMRSAssemblyList
																		}
																		onChange={(system) =>
																			onChangeVMRSAssemblyListSelect(system)
																		}
																		currentVMSRSSystemCode={
																			currentVMRSSystem !== null
																				? currentVMRSSystem &&
																				  currentVMRSSystem.data &&
																				  currentVMRSSystem.data.code
																				: rowValue.vmrs.system !== undefined
																				? rowValue.vmrs.system.code
																				: null
																		}
																		className="service_dropdown"
																		styles={customStyles}
																	/>
																	<FetchComponentList
																		value={
																			currentVMRSSystem !== null
																				? currentVMRSComponentList
																				: rowValue.vmrs.component !== undefined
																				? rowValue.vmrs.component.name
																				: currentVMRSComponentList
																		}
																		onChange={(system) =>
																			onChangeVMRSComponentListSelect(system)
																		}
																		vmrsSystemCode={
																			currentVMRSSystem &&
																			currentVMRSSystem.data !== undefined
																				? currentVMRSSystem.data.code
																				: rowValue.vmrs.system !== undefined
																				? rowValue.vmrs.system.code
																				: null
																		}
																		vmrsAssemblyCode={
																			currentVMRSAssemblyList &&
																			currentVMRSAssemblyList.data !== undefined
																				? currentVMRSAssemblyList.data.code
																				: rowValue.vmrs.assembly !== undefined
																				? rowValue.vmrs.assembly.code
																				: null
																		}
																		className="service_dropdown"
																		styles={customStyles}
																	/>
																</>
															)}
													</>
												) : (
													<CurrencyInput
														placeholder="$0.00"
														type="text"
														className={
															isError
																? "rate-text-box errorClass"
																: "rate-text-box"
														}
														name={headerValue.name}
														defaultValue={
															rowValue[headerValue.name] === 0
																? 0
																: rowValue[headerValue.name].toFixed(2)
														}
														onChange={handleRowData}
													/>
												)
											) : rowValue[headerValue.name] === "AAR" ? (
												<>
													<span className="aar">
														{rowValue[headerValue.name]}
													</span>
													{rowValue.aar.system !== undefined ||
													rowValue.aar.subsystem !== undefined ||
													rowValue.aar.service !== undefined ? (
														<span className="right-arrow">&gt;</span>
													) : (
														""
													)}
													<span className="service-type-name">
														{rowValue.aar.system !== undefined ? (
															<a
																data-for="main"
																data-tip={rowValue.aar.system.name}
																data-iscapture="true"
															>
																{rowValue.aar.system.name}
															</a>
														) : (
															""
														)}
													</span>
													<ReactTooltip
														id="main"
														place="top"
														type="info"
														effect="float"
														multiline={true}
													/>
													{rowValue.aar.subsystem !== undefined ? (
														<span>{">"}</span>
													) : (
														""
													)}
													<span className="service-type-name">
														{rowValue.aar.subsystem !== undefined ? (
															<>
																<a
																	data-for="main"
																	data-tip={rowValue.aar.subsystem.name}
																	data-iscapture="true"
																>
																	{rowValue.aar.subsystem.name}
																</a>

																<ReactTooltip
																	id="main"
																	place="top"
																	type="info"
																	effect="float"
																	multiline={true}
																/>
															</>
														) : (
															""
														)}
													</span>
													{rowValue.aar.service !== undefined ? (
														<span>{">"}</span>
													) : (
														""
													)}
													<span className="service-type-name">
														{rowValue.aar.service !== undefined ? (
															<>
																<a
																	data-for="main"
																	data-tip={rowValue.aar.service.name}
																	data-iscapture="true"
																>
																	{rowValue.aar.service.name}
																</a>

																<ReactTooltip
																	id="main"
																	place="top"
																	type="info"
																	effect="float"
																	multiline={true}
																/>
															</>
														) : (
															""
														)}
													</span>
												</>
											) : rowValue[headerValue.name] === "VMRS" ? (
												<>
													<span className="vmrs">
														{rowValue[headerValue.name]}
													</span>
													{rowValue.vmrs.system !== undefined ||
													rowValue.vmrs.assembly !== undefined ||
													rowValue.vmrs.component !== undefined ? (
														<span className="right-arrow">&gt;</span>
													) : null}
													<span className="service-type-name">
														{rowValue.vmrs.system !== undefined ? (
															<>
																<a
																	data-for="main"
																	data-tip={rowValue.vmrs.system.name}
																	data-iscapture="true"
																>
																	{rowValue.vmrs.system.name}
																</a>
																<ReactTooltip
																	id="main"
																	place="top"
																	type="info"
																	effect="float"
																	multiline={true}
																/>
															</>
														) : (
															""
														)}
													</span>
													{rowValue.vmrs.assembly !== undefined ? (
														<span>{">"}</span>
													) : (
														""
													)}
													<span className="service-type-name">
														{rowValue.vmrs.assembly !== undefined ? (
															<>
																<a
																	data-for="main"
																	data-tip={rowValue.vmrs.assembly.name}
																	data-iscapture="true"
																>
																	{rowValue.vmrs.assembly.name}
																</a>
																<ReactTooltip
																	id="main"
																	place="top"
																	type="info"
																	effect="float"
																	multiline={true}
																/>
															</>
														) : (
															""
														)}
													</span>
													{rowValue.vmrs.component !== undefined ? (
														<span>{">"}</span>
													) : (
														""
													)}
													<span className="service-type-name">
														{rowValue.vmrs.component !== undefined ? (
															<>
																<a
																	data-for="main"
																	data-tip={rowValue.vmrs.component.name}
																	data-iscapture="true"
																>
																	{rowValue.vmrs.component.name}
																</a>
																<ReactTooltip
																	id="main"
																	place="top"
																	type="info"
																	effect="float"
																	multiline={true}
																/>
															</>
														) : (
															""
														)}
													</span>
												</>
											) : typeof rowValue[headerValue.name] ===
											  "number" ? (
												rowValue[headerValue.name].toFixed(2)
											) : (
												rowValue[headerValue.name]
											)
										) : headerValue.currency ? (
											rowValue[headerValue.name].toFixed(2)
										) : rowValue[headerValue.name] === "AAR" ? (
											<>
												<span className="aar">
													{rowValue[headerValue.name]}
												</span>
												{rowValue.aar.system !== undefined ||
												rowValue.aar.subsystem !== undefined ||
												rowValue.aar.service !== undefined ? (
													<span className="right-arrow">&gt;</span>
												) : null}
												<span className="service-type-name">
													{rowValue.aar.system !== undefined ? (
														<>
															<a
																data-for="main"
																data-tip={rowValue.aar.system.name}
																data-iscapture="true"
															>
																{rowValue.aar.system.name}
															</a>
															<ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
														</>
													) : (
														""
													)}
												</span>
												{rowValue.aar.subsystem !== undefined ? (
													<span>{">"}</span>
												) : (
													""
												)}
												<span className="service-type-name">
													{rowValue.aar.subsystem !== undefined ? (
														<>
															<a
																data-for="main"
																data-tip={rowValue.aar.subsystem.name}
																data-iscapture="true"
															>
																{rowValue.aar.subsystem.name}
															</a>
															<ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
														</>
													) : (
														""
													)}
												</span>
												{rowValue.aar.service !== undefined ? (
													<span>{">"}</span>
												) : (
													""
												)}
												<span className="service-type-name">
													{rowValue.aar.service !== undefined ? (
														<>
															<a
																data-for="main"
																data-tip={rowValue.aar.service.name}
																data-iscapture="true"
															>
																{rowValue.aar.service.name}
															</a>
															<ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
														</>
													) : (
														""
													)}
												</span>
											</>
										) : rowValue[headerValue.name] === "VMRS" ? (
											<>
												<span className="vmrs">
													{rowValue[headerValue.name]}
												</span>
												{rowValue.vmrs.system !== undefined ||
												rowValue.vmrs.assembly !== undefined ||
												rowValue.vmrs.component !== undefined ? (
													<span className="right-arrow">&gt;</span>
												) : null}
												<span className="service-type-name">
													{rowValue.vmrs.system !== undefined ? (
														<>
															<a
																data-for="main"
																data-tip={rowValue.vmrs.system.name}
																data-iscapture="true"
															>
																{rowValue.vmrs.system.name}
															</a>
															<ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
														</>
													) : (
														""
													)}
												</span>
												{rowValue.vmrs.assembly !== undefined ? (
													<span>{">"}</span>
												) : (
													""
												)}
												<span className="service-type-name">
													{rowValue.vmrs.assembly !== undefined ? (
														<>
															<a
																data-for="main"
																data-tip={rowValue.vmrs.assembly.name}
																data-iscapture="true"
															>
																{rowValue.vmrs.assembly.name}
															</a>
															<ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
														</>
													) : (
														""
													)}
												</span>
												{rowValue.vmrs.component !== undefined ? (
													<span>{">"}</span>
												) : (
													""
												)}
												<span className="service-type-name">
													{rowValue.vmrs.component !== undefined ? (
														<>
															<a
																data-for="main"
																data-tip={rowValue.vmrs.component.name}
																data-iscapture="true"
															>
																{rowValue.vmrs.component.name}
															</a>
															<ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
														</>
													) : (
														""
													)}
												</span>
											</>
										) : (
											rowValue[headerValue.name]
										)
									) : (
										"NA"
									)}
								</div>
							)
					)}
				</Row>
			)
		})
	) : (
		null
	)
}

Cell.propTypes = {
	headerData: PropTypes.array,
	rowData: PropTypes.array,
	isRowClickable: PropTypes.bool,
	onSaveClick: PropTypes.object,
	onRemoveClick: PropTypes.object,
}
Cell.defaultProps = {
	headerData: [],
	rowData: [],
	isRowClickable: false,
}

export const TableHeader = ({
	headerData,
	tableHeaderClassName,
	rowClassName,
}) => {
	return (
		<div className={cx("table-header", tableHeaderClassName)}>
			<Row className={rowClassName}>
				{headerData.map(({ name, label, isVline, className }) => (
					<div
						key={`title-${name}`}
						className={cx(
							`table-cell ${
								className === "service_type"
									? "service_type"
									: className === "icon"
									? "icon"
									: "labor_rates"
							}`
						)}
					>
						{isVline ? <span className="vline"></span> : ""} {label}
					</div>
				))}
			</Row>
		</div>
	)
}
TableHeader.propTypes = {
	headerData: PropTypes.array,
	tableHeaderClassName: PropTypes.string,
	rowClassName: PropTypes.string,
}
TableHeader.defaultProps = {
	tableHeaderClassName: "",
	rowClassName: "",
	headerData: [],
}

export const TableBody = ({
	headerData,
	rowData,
	rowClassName,
	tableBodyClassName,
	onSaveClick,
	onRemoveClick,
	addNewRow,
	newRowData,
	removeRow,
	service_types,
	showEmptyMsg,
}) => {
	const [obj, setObj] = useState({
		service_type: null,
		regular_hours_rate: null,
		holiday_rate: null,
		after_hours_rate: null,
	})

	const [selectedOption, setSelectedOption] = useState(null)
	const [isError, setIsError] = useState(false)
	const [showAARSystemDD, setShowAARSystemDD] = useState(false)
	const [showVMRSSystem, setShowVMRSSystemDD] = useState(false)

	const [currentSystem, setCurrentSystem] = useState(null)
	const [currentSubSystem, setCurrentSubSystem] = useState(null)
	const [currentServiceList, setCurrentServiceList] = useState(null)

	const [currentVMRSSystem, setCurrentVMRSSystem] = useState(null)
	const [currentVMRSAssemblyList, setCurrentVMRSAssemblyList] = useState(null)
	const [currentVMRSComponentList, setCurrentVMRSComponentList] = useState(null)
	const [isSystemValue, setIsSystemValue] = useState(false)
	const [isSubSystemvalue, setIsSubSystemValue] = useState(false)
	const [isReset, setIsReset] = useState(false)
	const [submit, setSubmit] = useState(false)
	const selectInputRef = useRef()

	useEffect(() => {
		if (addNewRow === false) {
			setObj({
				regular_hours_rate: null,
				holiday_rate: null,
				after_hours_rate: null,
			})
			setCurrentSystem(null)
			setCurrentSubSystem(null)
			setCurrentServiceList(null)
			setCurrentVMRSSystem(null)
			setCurrentVMRSAssemblyList(null)
			setCurrentVMRSComponentList(null)
			setSelectedOption(null)
			setIsError(false)
		}
	}, [
		addNewRow,
		selectedOption,
		currentSystem,
		currentSubSystem,
		currentServiceList,
		isError,
	])

	let payload = {
		...obj,
		aar_system: {
			service_type: currentSystem !== null ? "aar" : null,
			name:
				currentSystem !== null ? currentSystem && currentSystem.label : null,
			level: currentSystem !== null ? "system" : null,
			code: null,
			source_system_id:
				currentSystem !== null ? currentSystem && currentSystem.value : null,
		},
		aar_subsystem: {
			service_type: currentSubSystem !== null ? "aar" : null,
			name:
				currentSubSystem !== null
					? currentSubSystem && currentSubSystem.label
					: null,
			level: currentSubSystem !== null ? "subsystem" : null,
			code: null,
			source_system_id:
				currentSubSystem !== null
					? currentSubSystem && currentSubSystem.value
					: null,
		},
		aar_service: {
			service_type: currentServiceList !== null ? "aar" : null,
			name:
				currentServiceList !== null
					? currentServiceList && currentServiceList.data.name
					: null,
			level: currentServiceList !== null ? "service" : null,
			code: null,
			source_system_id:
				currentServiceList !== null
					? currentServiceList && currentServiceList.data.id
					: null,
		},
		vmrs_system: {
			service_type:
				selectedOption !== null &&
				selectedOption.value === "vmrs" &&
				currentVMRSSystem !== null
					? "vmrs"
					: null,
			name:
				currentVMRSSystem !== null
					? currentVMRSSystem && currentVMRSSystem.label
					: null,
			level: currentVMRSSystem !== null ? "system" : null,
			code:
				currentVMRSSystem !== null
					? currentVMRSSystem && currentVMRSSystem.value
					: null,
			source_system_id: null,
		},
		vmrs_assembly: {
			service_type: currentVMRSAssemblyList !== null ? "vmrs" : null,
			name:
				currentVMRSAssemblyList !== null
					? currentVMRSAssemblyList && currentVMRSAssemblyList.label
					: null,
			level: currentVMRSAssemblyList !== null ? "assembly" : null,
			code:
				currentVMRSAssemblyList !== null
					? currentVMRSAssemblyList && currentVMRSAssemblyList.value
					: null,
			source_system_id: null,
		},
		vmrs_component: {
			service_type: currentVMRSComponentList !== null ? "vmrs" : null,
			name:
				currentVMRSComponentList !== null
					? currentVMRSComponentList &&
					  currentVMRSComponentList.data.component_description
					: null,
			level: currentVMRSComponentList !== null ? "component" : null,
			code:
				currentVMRSComponentList !== null
					? currentVMRSComponentList && currentVMRSComponentList.data.code
					: null,
			source_system_id: null,
		},
	}

	const formatPrice = (val) => {
		let formatValue = val && val.toString().replace("$", "")
		return formatValue
	}

	const handleRowData = (e) => {
		let value = formatPrice(e.target.value)
		setObj({
			...obj,
			[e.target.name]: value,
		})
	}

	// manual injection for AAR and VMRS options into the defautl AAMR service type dropdown

	let options = [
		{
			value: "aar",
			label: (
				<>
					<div className="aar-dd-option">
						<span>
							<i className="fas fa-plus"></i>
						</span>{" "}
						<span>AAR</span>
					</div>
				</>
			),
		},
		{
			value: "vmrs",
			label: (
				<>
					<div className="vmrs-dd-option">
						<span>
							<i className="fas fa-plus"></i>
						</span>{" "}
						<span>VMRS</span>
					</div>
				</>
			),
		},
	]

	if (service_types !== undefined) {
		Object.entries(service_types).forEach(([key, value]) =>
			options.unshift({
				value: `${key}`,
				label: `${value}`,
			})
		)
	}

	const handleChange = (selectedOption) => {
		if (selectedOption) {
			if (selectedOption.value === "aar") {
				setShowAARSystemDD(true)
			} else {
				setShowAARSystemDD(false)
			}
			if (selectedOption.value === "vmrs") {
				setShowVMRSSystemDD(true)
			} else {
				setShowVMRSSystemDD(false)
			}
			setSelectedOption(selectedOption)
			obj.service_type = selectedOption.value
		}
	}

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: "#fff",
			borderColor: isError ? "#ff0000" : "#5195FB",
			minHeight: "30px",
			height: "30px",
			boxShadow: state.isFocused ? null : null,
		}),

		valueContainer: (provided, state) => ({
			...provided,
			height: "30px",
			padding: "0 6px",
		}),

		input: (provided, state) => ({
			...provided,
			margin: "0px",
		}),
		indicatorSeparator: (state) => ({
			display: "none",
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			height: "30px",
		}),
	}

	const submitRowValue = () => {
		let formIsValid = true

		setIsError(false)
		const { service_type, regular_hours_rate, holiday_rate, after_hours_rate } =
			obj

		if (service_type === undefined) {
			alert("Please choose service type")
			formIsValid = false
			setIsError(!isError)
		}

		if (service_type) {
			const words = service_type.split("_")

			let serviceType = words
				.map((word) => {
					return word[0].toUpperCase() + word.substring(1)
				})
				.join(" ")

			let checkServicetypeExistence = rowData.filter((data) => {
				return data.service_type === serviceType
			})

			if (checkServicetypeExistence.length > 0) {
				formIsValid = false
				setIsError(!isError)
				alert("Service type has already been taken")
			}
		}

		if (
			regular_hours_rate === null &&
			holiday_rate === null &&
			after_hours_rate === null
		) {
			alert("Please choose any one labor rate")
			formIsValid = false
			setIsError(!isError)
		}

		// if (holiday_rate === "") {
		//   formIsValid = false;
		//   setIsError(!isError);
		// }

		// if (after_hours_rate === "") {
		//   formIsValid = false;
		//   setIsError(!isError);
		// }

		if (selectedOption !== null && selectedOption.value === "aar") {
			if (
				currentSystem === null &&
				currentSubSystem === null &&
				currentServiceList === null
			) {
				alert("Please choose one at leaset service")
				formIsValid = false
			}
		}

		if (selectedOption !== null && selectedOption.value === "vmrs") {
			if (
				currentVMRSSystem === null &&
				currentVMRSComponentList === null &&
				currentVMRSAssemblyList === null
			) {
				alert("Please choose at leaset one VMRS service")
				formIsValid = false
			}
		}

		if (formIsValid) {
			newRowData(payload)
			setIsError(false)
			setObj({
				service_type: null,
				regular_hours_rate: null,
				holiday_rate: null,
				after_hours_rate: null,
			})
			setSelectedOption(null)
			setCurrentSystem(null)
			setCurrentSubSystem(null)
			setCurrentServiceList(null)
			setCurrentVMRSSystem(null)
			setCurrentVMRSAssemblyList(null)
			setCurrentVMRSComponentList(null)
			setShowAARSystemDD(false)
			setShowVMRSSystemDD(false)
		}
		selectInputRef.current.select.clearValue()
	}

	const removeRowData = () => {
		removeRow(true)
		setObj({
			service_type: null,
			regular_hours_rate: null,
			holiday_rate: null,
			after_hours_rate: null,
		})
		setSelectedOption(null)
		setCurrentSystem(null)
		setCurrentSubSystem(null)
		setCurrentServiceList(null)
		setCurrentVMRSSystem(null)
		setCurrentVMRSAssemblyList(null)
		setCurrentVMRSComponentList(null)
	}

	// AAR onChange event

	const onChangeSystemSelect = (item) => {
		setCurrentSystem(item)
		setIsSystemValue(true)
		setCurrentSubSystem(null)
		setCurrentServiceList(null)
	}

	const onChangeSubSystemSelect = (item) => {
		setCurrentSubSystem(item)
		setIsSubSystemValue(true)
		setCurrentServiceList(null)

		if (currentSystem === null) {
			setCurrentSystem({
				label: item.data.service_component_name,
				value: item.data.service_component_id,
			})
		}
	}

	const onChangeServiceList = (item) => {
		setCurrentServiceList(item)
		if (currentSystem === null) {
			setCurrentSystem({
				label: item && item.data.service_component_name,
				value: item && item.data.service_component_id,
			})
		}

		if (currentSubSystem === null) {
			setCurrentSubSystem({
				label: item && item.data.service_component_name,
				value: item && item.data.service_component_id,
			})
		}
	}

	// VMRS onChange event

	const onChangeVMRSSystemSelect = (item) => {
		setCurrentVMRSAssemblyList(null)
		setCurrentVMRSComponentList(null)
		setCurrentVMRSSystem(item)
		setCurrentServiceList(null)
	}

	const onChangeVMRSAssemblyListSelect = (item) => {
		setCurrentVMRSAssemblyList(item)
		setCurrentVMRSComponentList(null)
		if (currentVMRSSystem === null) {
			setCurrentVMRSSystem({
				label: item.data.system_description,
				value: item.data.system_code,
			})
		}
	}

	const onChangeVMRSComponentListSelect = (item) => {
		setCurrentVMRSComponentList(item)

		if (currentVMRSSystem === null) {
			setCurrentVMRSSystem({
				label: item && item.data.system_description,
				value: item && item.data.system_code,
			})
		}

		if (currentVMRSAssemblyList === null) {
			setCurrentVMRSAssemblyList({
				label: item && item.data.assembly_description,
				value: item && item.data.assembly_code,
			})
		}
	}

	return (
		<div className={cx("table-body", tableBodyClassName)} id="table-body">
			{addNewRow ? (
				<div className="table-row">
					<div className="table-cell table-cell-auto-width service_type addition">
						<Select
							value={selectedOption || null}
							ref={selectInputRef}
							onChange={handleChange}
							options={options}
							className="service_dropdown"
							styles={customStyles}
							placeholder={"Service type"}
						/>
						{showAARSystemDD && (
							<>
								<FetchSystems
									value={currentSystem || null}
									onChange={(system) => onChangeSystemSelect(system)}
									className="service_dropdown"
									styles={customStyles}
									currentServiceList={
										currentServiceList !== null
											? {
													label:
														currentServiceList &&
														currentServiceList.data.service_component_name,
													value:
														currentServiceList &&
														currentServiceList.data.service_component_id,
											  }
											: currentSystem
									}
									currentSubSystem={
										currentSubSystem !== null
											? {
													label:
														currentSubSystem &&
														currentSubSystem.data &&
														currentSubSystem.data.service_component_name,
													value:
														currentSubSystem &&
														currentSubSystem.data &&
														currentSubSystem.data.service_component_id,
											  }
											: currentSystem
									}
								/>
								<FetchSubSystems
									value={currentSubSystem || null}
									onChange={(subSystem) => onChangeSubSystemSelect(subSystem)}
									serviceComponentId={
										currentSystem !== null
											? currentSystem && currentSystem.value
											: null
									}
									styles={customStyles}
									className="service_dropdown"
									currentServiceList={
										currentServiceList !== null
											? {
													label:
														currentServiceList &&
														currentServiceList.data.sub_component_name,
													value:
														currentServiceList &&
														currentServiceList.data.sub_component_id,
											  }
											: currentSubSystem
									}
								/>
								<FetchServiceList
									value={currentServiceList || null}
									onChange={(serviceList) => onChangeServiceList(serviceList)}
									currentServiceList={currentServiceList}
									styles={customStyles}
									currentSystemCode={
										currentSystem === null
											? currentSubSystem &&
											  currentSubSystem.data &&
											  currentSubSystem.data.service_component_id
											: currentSystem && currentSystem.value
									}
									currentSubSystemCode={
										currentSubSystem !== null
											? currentSubSystem && currentSubSystem.value
											: null
									}
									className="service_dropdown"
								/>
							</>
						)}
						{showVMRSSystem && (
							<>
								<FetchVMRSSystems
									value={currentVMRSSystem || null}
									onChange={(system) => onChangeVMRSSystemSelect(system)}
									className="service_dropdown"
									styles={customStyles}
									isReset={isReset}
									currentVMRSComponentList={
										currentVMRSComponentList !== null
											? {
													label:
														currentVMRSComponentList &&
														currentVMRSComponentList.data.system_description,
													value:
														currentVMRSComponentList &&
														currentVMRSComponentList.data.system_code,
											  }
											: currentVMRSSystem
									}
									currentVMRSAssemblyList={
										currentVMRSAssemblyList !== null
											? {
													label:
														currentVMRSAssemblyList &&
														currentVMRSAssemblyList.data &&
														currentVMRSAssemblyList.data.system_description,
													value:
														currentVMRSAssemblyList &&
														currentVMRSAssemblyList.data &&
														currentVMRSAssemblyList.data.system_code,
											  }
											: currentVMRSSystem
									}
								/>
								<FetchAssemblyList
									value={currentVMRSAssemblyList || null}
									onChange={(system) => onChangeVMRSAssemblyListSelect(system)}
									currentVMSRSSystemCode={
										currentVMRSSystem !== null
											? currentVMRSSystem && currentVMRSSystem.value
											: null
									}
									className="service_dropdown"
									styles={customStyles}
									isReset={isReset}
									currentVMRSComponentList={
										currentVMRSComponentList !== null
											? {
													label:
														currentVMRSComponentList &&
														currentVMRSComponentList.data.assembly_description,
													value:
														currentVMRSComponentList &&
														currentVMRSComponentList.data.assembly_code,
											  }
											: currentVMRSAssemblyList
									}
								/>
								<FetchComponentList
									value={currentVMRSComponentList || null}
									onChange={(system) => onChangeVMRSComponentListSelect(system)}
									vmrsSystemCode={
										currentVMRSSystem === null
											? currentVMRSAssemblyList &&
											  currentVMRSAssemblyList.data.system_code
											: currentVMRSSystem && currentVMRSSystem.value
									}
									vmrsAssemblyCode={
										currentVMRSAssemblyList !== null
											? currentVMRSAssemblyList && currentVMRSAssemblyList.value
											: null
									}
									className="service_dropdown"
									styles={customStyles}
									isReset={isReset}
								/>
							</>
						)}
					</div>

					<div className="table-cell table-cell-auto-width isDesc isCurrency">
						<CurrencyInput
							placeholder="0.00"
							type="text"
							className={isError ? "rate-text-box errorClass" : "rate-text-box"}
							name="regular_hours_rate"
							value={obj !== null ? obj.regular_hours_rate : null}
							onChange={(e) => handleRowData(e)}
						/>
					</div>
					<div className="table-cell table-cell-auto-width isCurrency">
						<CurrencyInput
							placeholder="0.00"
							type="text"
							className={"rate-text-box"}
							name="after_hours_rate"
							value={obj !== null ? obj.after_hours_rate : null}
							onChange={(e) => handleRowData(e)}
						/>
					</div>
					<div className="table-cell table-cell-auto-width isDesc isCurrency">
						<CurrencyInput
							placeholder="0.00"
							type="text"
							className={"rate-text-box"}
							name="holiday_rate"
							value={obj !== null ? obj.holiday_rate : null}
							onChange={(e) => handleRowData(e)}
						/>
					</div>

					<div className="table-cell table-cell-auto-width btn-div">
						<button className="btn-regular btn-blue" onClick={submitRowValue}>
							Add
						</button>
						&nbsp;&nbsp;
						<button className="btn-regular btn-red" onClick={removeRowData}>
							Remove
						</button>
					</div>
				</div>
			) : null}

			{showEmptyMsg && (
				<div className="no-record">
					<span>No data</span>
				</div>
			)}

			<Cell
				headerData={headerData}
				rowData={rowData}
				rowClassName={rowClassName}
				onSaveClick={onSaveClick}
				onRemoveClick={onRemoveClick}
				service_types={service_types}
			></Cell>
		</div>
	)
}
TableBody.propTypes = {
	headerData: PropTypes.array,
	rowData: PropTypes.array,
	rowClassName: PropTypes.string,
	tableBodyClassName: PropTypes.string,
	addNewRow: PropTypes.bool,
	newRowData: PropTypes.func,
}
TableBody.defaultProps = {
	headerData: [],
	rowData: [],
	rowClassName: "",
	tableBodyClassName: "",
	isRowClickable: false,
}

const Table = ({ children, tableClassName, ...restProps }) => {
	return (
		<Fragment>
			<div className={cx("sticky-table", tableClassName)} {...restProps}>
				{children}
			</div>
		</Fragment>
	)
}
Table.propTypes = {
	children: PropTypes.node,
	tableClassName: PropTypes.string,
	onPageChanged: PropTypes.func,
	totalRecords: PropTypes.number,
	pageLimit: PropTypes.number,
}
Table.defaultProps = {
	tableClassName: "",
}

export default withLoader(Table)
