import React, { useState, useEffect } from "react"
import { instance } from "../core/api"
import Select from "react-select"
import * as Yup from "yup"
import FetchSystems from "./FetchSystems"
import FetchSubSystems from "./FetchSubSystems"
import FetchServiceList from "./FetchServiceList"
import FetchVMRSSystems from "./FetchVMRSSystems"
import FetchAssemblyList from "./FetchAssemblyList"
import FetchComponentList from "./FetchComponentList"
import FetchVMRSTask from "./FetchVMRSTask"
import FetchParts from "./FetchParts"
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik"

const EditServiceBundle = ({
	selectedEditData,
	closePopup,
	editSucccessful,
}) => {
	const [selectedServiceType, setSelectedServiceType] = useState(null)
	const [service_types, setServiceTypes] = useState({})
	const [equipmentTypes, setEquipmentTypes] = useState([])
	const [oems, setOems] = useState({})
	const [currentAARSystem, setCurrentAARSystem] = useState(null)
	const [currentAARSubSystem, setCurrentAARSubSystem] = useState(null)
	const [currentAARServiceList, setCurrentAARServiceList] = useState(null)
	const [currentVMRSSystem, setCurrentVMRSSystem] = useState(null)
	const [currentVMRSAssembly, setCurrentVMRSAssembly] = useState(null)
	const [currentVMRSComponent, setCurrentVMRSComponent] = useState(null)
	const [aarTask, setAARTask] = useState(null)
	const [vmrsTask, setVMRSTask] = useState(null)
	const [aarCurrentTask, setAARCurrentTask] = useState(null)
	const [defaultPartsSelected, setDefaultPartsSelected] = useState([])
	const [addonPartsSelected, setAddonPartsSelected] = useState([])
	const [tireSize, setTireSize] = useState("")
	const [selectedOEM, setSelectedOEM] = useState(null)
	const [defaultError, setDefaultError] = useState(false)
	const [partsErrorMsg, setPartsErrorMsg] = useState([])
	const [showPartsErrorMsg, setShowPartsErrorMsg] = useState(false)

	useEffect(() => {
		instance
			.get("/service_types")
			.then((res) => {
				let service_types = res.data
				if (service_types !== undefined) {
					setServiceTypes(service_types)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		instance
			.get("/oems")
			.then((res) => {
				if (res !== undefined) {
					setOems(res.data)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		if (selectedEditData[0].service_type === "vmrs") {
			instance
				.get("/service_codes/equipment_types", {
					params: {
						service_type: selectedEditData[0].service_type,
						vmrs_system_code:
							currentVMRSSystem !== null
								? currentVMRSSystem.value
								: selectedEditData[0].vmrs.system.code,
						vmrs_assembly_code:
							currentVMRSAssembly !== null
								? currentVMRSAssembly.value
								: selectedEditData[0].vmrs.assembly.code,
						vmrs_component_code:
							currentVMRSComponent !== null
								? currentVMRSComponent.value
								: selectedEditData[0].vmrs.component !== undefined
								? selectedEditData[0].vmrs.component.code
								: null,
					},
				})
				.then((res) => {
					const { equipment_types } = res.data
					if (equipment_types !== undefined) {
						setEquipmentTypes(equipment_types)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}

		if (selectedEditData[0].service_type === "aar") {
			instance
				.get("/service_codes/equipment_types", {
					params: {
						service_type: selectedEditData[0].service_type,
						service_component_id:
							currentAARSystem !== null
								? parseInt(currentAARSystem.value)
								: selectedEditData[0].aar.system.source_system_id,
						sub_service_component_id:
							currentAARSubSystem !== null
								? parseInt(currentAARSubSystem.value)
								: selectedEditData[0].aar.subsystem.source_system_id,
						service_id:
							currentAARServiceList !== null
								? parseInt(currentAARServiceList.value)
								: selectedEditData[0].aar.service.source_system_id,
					},
				})
				.then((res) => {
					const { equipment_types } = res.data
					if (equipment_types !== undefined) {
						setEquipmentTypes(equipment_types)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}, [])

	let associate = []
	let defaltPartsArr = []
	let defaultAddOnArr = []

	if (selectedEditData && selectedEditData.length > 0) {
		selectedEditData.map((data, index) => {
			let defaultPartsObj = []
			let addonPartsObj = []
			if (data.service_bundle_parts !== undefined) {
				data.service_bundle_parts.filter((default_part) => {
					if (default_part.add_on === false) {
						defaultPartsObj.push({
							record_id: default_part.id,
							label: default_part.parts.part_code_description,
							value: default_part.parts.part_id,
						})
					}
					if (default_part.add_on) {
						addonPartsObj.push({
							record_id: default_part.id,
							label: default_part.parts.part_code_description,
							value: default_part.parts.part_id,
						})
					}
				})
			}
			defaltPartsArr.push(defaultPartsObj)
			defaultAddOnArr.push(addonPartsObj)
		})
	}

	if (selectedEditData && selectedEditData.length > 0) {
		for (let i = 0; i < selectedEditData.length; i++) {
			associate.push({
				equipment_name: {
					label: selectedEditData[i].equipment_type,
					value:
						selectedEditData[i].equipment_type !== null
							? selectedEditData[i].equipment_type.toLowerCase()
							: null,
				},
				task: {
					label:
						selectedEditData[i].aar !== undefined
							? selectedEditData[i].aar.job.code +
							  "-" +
							  selectedEditData[i].aar.job.name
							: selectedEditData[i].vmrs.work_accomplished !== undefined
							? selectedEditData[i].vmrs.work_accomplished.details
							: null,
					value:
						selectedEditData[i].aar !== undefined
							? selectedEditData[i].aar.job.source_system_id
							: selectedEditData[i].vmrs.work_accomplished !== undefined
							? selectedEditData[i].vmrs.work_accomplished.code
							: null,
					source_system_id:
						selectedEditData[i].vmrs !== undefined &&
						selectedEditData[i].vmrs.work_accomplished !== undefined
							? selectedEditData[i].vmrs.work_accomplished.source_system_id
							: null,
					vmrs_task_name:
						selectedEditData[i].vmrs !== undefined &&
						selectedEditData[i].vmrs.work_accomplished !== undefined
							? selectedEditData[i].vmrs.work_accomplished.name
							: null,
					vmrs_task_code:
						selectedEditData[i].vmrs !== undefined &&
						selectedEditData[i].vmrs.work_accomplished !== undefined
							? selectedEditData[i].vmrs.work_accomplished.code
							: null,
				},
				default_parts: defaltPartsArr[i],
				addon_parts: defaultAddOnArr[i],
			})
		}
	}

	const getNames = {
		equipment_name: null,
		task: null,
		default_parts: null,
		addon_parts: null,
	}

	const partsAssociates = {
		service_type: selectedEditData[0].service_type.toUpperCase(),
		oem: selectedEditData[0].oem,
		tire_size: selectedEditData[0].specification,
		aar_system: {
			label:
				selectedEditData[0].aar !== undefined
					? selectedEditData[0].aar.system.name
					: null,
			value:
				selectedEditData[0].aar !== undefined
					? selectedEditData[0].aar.system.source_system_id
					: null,
		},
		aar_sub_system: {
			label:
				selectedEditData[0].aar !== undefined
					? selectedEditData[0].aar.subsystem.name
					: null,
			value:
				selectedEditData[0].aar !== undefined
					? selectedEditData[0].aar.subsystem.source_system_id
					: null,
		},
		aar_service: {
			label:
				selectedEditData[0].aar !== undefined
					? selectedEditData[0].aar.service.name
					: null,
			value:
				selectedEditData[0].aar !== undefined
					? selectedEditData[0].aar.service.source_system_id
					: null,
		},
		vmrs_system: {
			label:
				selectedEditData[0].vmrs !== undefined
					? selectedEditData[0].vmrs.system.name
					: null,
			value:
				selectedEditData[0].vmrs !== undefined
					? selectedEditData[0].vmrs.system.code
					: null,
		},
		vmrs_assembly: {
			label:
				selectedEditData[0].vmrs !== undefined
					? selectedEditData[0].vmrs.assembly.name
					: null,
			value:
				selectedEditData[0].vmrs !== undefined
					? selectedEditData[0].vmrs.assembly.code
					: null,
		},
		vmrs_component: {
			label:
				selectedEditData[0].vmrs !== undefined &&
				selectedEditData[0].vmrs.component !== undefined
					? selectedEditData[0].vmrs.component.name
					: null,
			value:
				selectedEditData[0].vmrs !== undefined &&
				selectedEditData[0].vmrs.component !== undefined
					? selectedEditData[0].vmrs.component.code
					: null,
		},
		associates: associate,
	}

	let OEM_options = []
	for (const [key, value] of Object.entries(oems)) {
		OEM_options.push({
			value: key,
			label: value,
		})
	}

	let equipmentTypesOptions = []
	equipmentTypes.map((equipment) => {
		equipmentTypesOptions.push({
			value: equipment.id,
			label: equipment.description,
		})
	})

	let aarServiceType = []
	equipmentTypes.map((equipment) => {
		aarServiceType.push({
			value: equipment.id,
			label: equipment.description,
		})
	})

	let aarTaskOption = []

	if (selectedEditData[0].service_type === "aar") {
		if (aarTask !== null) {
			aarTaskOption.push({
				label: aarTask.code + " - " + aarTask.description,
				value: aarTask.id,
			})
		} else {
			aarTaskOption.push({
				label:
					selectedEditData[0].aar.job.code +
					"-" +
					selectedEditData[0].aar.job.name,
				value: selectedEditData[0].aar.job.source_system_id,
			})
		}
	}

	let payload = []

	const getValues = (formValue) => {
		// setFormValue(formValue)
		let getParts = []
		if (formValue.associates) {
			formValue.associates.map((associate, i) => {
				let editedParts = []

				if (
					formValue.associates[i].default_parts !== null &&
					formValue.associates[i].default_parts.length > 0
				) {
					formValue.associates[i].default_parts.map((parts) => {
						editedParts.push({
							id: parseInt(parts.id),
							part_id: parts.part_id,
							add_on: parts.add_on,
						})
					})
				}

				if (
					formValue.associates[i].default_parts !== null &&
					formValue.associates[i].default_parts.length > 0
				) {
					formValue.associates[i].default_parts.map((parts) => {
						if (parts.data !== undefined) {
							editedParts.push({
								part_id: parseInt(parts.value),
								add_on: false,
							})
						} else {
							editedParts.push({
								id: parseInt(parts.record_id),
								part_id: parseInt(parts.value),
								add_on: false,
							})
						}
					})
				}

				if (
					formValue.associates[i].addon_parts !== null &&
					formValue.associates[i].addon_parts.length > 0
				) {
					formValue.associates[i].addon_parts.map((parts) => {
						if (parts.add_on === true) {
							editedParts.push({
								id: parseInt(parts.id),
								part_id: parts.part_id,
								add_on: parts.add_on,
							})
						}
					})
				}

				if (
					formValue.associates[i].addon_parts !== null &&
					formValue.associates[i].addon_parts.length > 0
				) {
					formValue.associates[i].addon_parts.map((parts) => {
						if (parts.data !== undefined) {
							editedParts.push({
								part_id: parseInt(parts.value),
								add_on: true,
							})
						} else {
							editedParts.push({
								id: parseInt(parts.record_id),
								part_id: parseInt(parts.value),
								add_on: true,
							})
						}
					})
				}

				if (selectedEditData[i] !== undefined) {
					payload.push({
						id:
							selectedEditData[i] !== undefined &&
							selectedEditData[i].id !== undefined
								? selectedEditData[i].id
								: null,
						service_type: formValue.service_type === "AAR" ? "aar" : "vmrs",
						equipment_type:
							formValue.associates[i].equipment_name !== null
								? formValue.associates[i].equipment_name.label
								: null,
						specification:
							formValue.tire_size !== "" ? formValue.tire_size : null,
						oem:
							formValue.oem && formValue.oem.value !== undefined
								? formValue.oem.value
								: formValue.oem,
						aar_system: {
							service_type: formValue.service_type === "AAR" ? "aar" : null,
							name:
								formValue.aar_system.label !== null
									? formValue.aar_system.label
									: null,
							level: formValue.service_type === "AAR" ? "system" : null,
							code: null,
							source_system_id:
								formValue.aar_system.label !== null
									? parseInt(formValue.aar_system.value)
									: null,
						},
						aar_subsystem: {
							service_type: formValue.service_type === "AAR" ? "aar" : null,
							name:
								formValue.aar_sub_system.label !== null
									? formValue.aar_sub_system.label
									: null,
							level: formValue.service_type === "AAR" ? "subsystem" : null,
							code: null,
							source_system_id:
								formValue.aar_sub_system.label !== null
									? parseInt(formValue.aar_sub_system.value)
									: null,
						},
						aar_service: {
							service_type: formValue.service_type === "AAR" ? "aar" : null,
							name:
								formValue.aar_service.label !== null
									? formValue.aar_service.label
									: null,
							level: formValue.service_type === "AAR" ? "service" : null,
							code: null,
							source_system_id:
								formValue.aar_service.label !== null
									? parseInt(formValue.aar_service.value)
									: null,
						},

						aar_job: {
							service_type: formValue.service_type === "AAR" ? "aar" : null,
							name:
								formValue.service_type === "AAR" &&
								formValue.associates[i].task !== null
									? formValue.associates[i].task.label
									: null,
							level: formValue.service_type === "AAR" ? "aarJob" : null,
							code:
								formValue.service_type === "AAR"
									? formValue.associates[i].task.value
									: null,
							source_system_id:
								formValue.service_type === "AAR" &&
								formValue.associates[i].task !== null
									? formValue.associates[i].task.value
									: null,
						},
						vmrs_system: {
							service_type: formValue.service_type === "VMRS" ? "vmrs" : null,
							name:
								formValue.vmrs_system && formValue.vmrs_system.label !== null
									? formValue.vmrs_system.label
									: null,
							level: formValue.service_type === "VMRS" ? "system" : null,
							code:
								formValue.vmrs_system.label !== null
									? formValue.vmrs_system.value
									: null,
							source_system_id: null,
						},
						vmrs_assembly: {
							service_type: formValue.service_type === "VMRS" ? "vmrs" : null,
							name:
								formValue.vmrs_assembly &&
								formValue.vmrs_assembly.label !== null
									? formValue.vmrs_assembly.label
									: null,
							level: formValue.service_type === "VMRS" ? "assembly" : null,
							code:
								formValue.vmrs_assembly.label !== null
									? formValue.vmrs_assembly.value
									: null,
							source_system_id: null,
						},
						vmrs_component: {
							service_type: formValue.service_type === "VMRS" ? "vmrs" : null,
							name:
								formValue.vmrs_component !== undefined &&
								formValue.vmrs_component.label !== null
									? formValue.vmrs_component.label
									: null,

							level: formValue.service_type === "VMRS" ? "component" : null,
							code:
								formValue.vmrs_component !== undefined &&
								formValue.vmrs_component.label !== null
									? formValue.vmrs_component.value
									: null,
							source_system_id: null,
						},
						vmrs_work_accomplished: {
							service_type: formValue.service_type === "VMRS" ? "vmrs" : null,
							name:
								formValue.service_type === "VMRS" &&
								formValue.associates[i].task.data !== undefined
									? formValue.associates[i].task.data.description
									: formValue.associates[i].task.vmrs_task_name,
							level:
								formValue.service_type === "VMRS" ? "workAccomplished" : null,
							code:
								formValue.service_type === "VMRS" &&
								formValue.associates[i].task.data !== undefined
									? formValue.associates[i].task.data.code
									: formValue.associates[i].task.value,
							source_system_id:
								formValue.service_type === "VMRS" &&
								formValue.associates[i].task.data !== undefined
									? formValue.associates[i].task.data.id
									: formValue.associates[i].task.source_system_id,
						},
						service_bundle_parts:
							editedParts.length > 0 ? editedParts : getParts,
					})
				} else {
					payload.push({
						service_type: formValue.service_type === "AAR" ? "aar" : "vmrs",
						equipment_type:
							formValue.associates[i].equipment_name !== null
								? formValue.associates[i].equipment_name.label
								: null,
						specification:
							formValue.tire_size !== "" ? formValue.tire_size : null,
						oem:
							formValue.oem && formValue.oem.value !== undefined
								? formValue.oem.value
								: formValue.oem,
						aar_system: {
							service_type: formValue.service_type === "AAR" ? "aar" : null,
							name:
								formValue.aar_system.label !== null
									? formValue.aar_system.label
									: null,
							level: formValue.service_type === "AAR" ? "system" : null,
							code: null,
							source_system_id:
								formValue.aar_system.label !== null
									? parseInt(formValue.aar_system.value)
									: null,
						},
						aar_subsystem: {
							service_type: formValue.service_type === "AAR" ? "aar" : null,
							name:
								formValue.aar_sub_system.label !== null
									? formValue.aar_sub_system.label
									: null,
							level: formValue.service_type === "AAR" ? "subsystem" : null,
							code: null,
							source_system_id:
								formValue.aar_sub_system.label !== null
									? parseInt(formValue.aar_sub_system.value)
									: null,
						},
						aar_service: {
							service_type: formValue.service_type === "AAR" ? "aar" : null,
							name:
								formValue.aar_service.label !== null
									? formValue.aar_service.label
									: null,
							level: formValue.service_type === "AAR" ? "service" : null,
							code: null,
							source_system_id:
								formValue.aar_service.label !== null
									? parseInt(formValue.aar_service.value)
									: null,
						},

						aar_job: {
							service_type: formValue.service_type === "AAR" ? "aar" : null,
							name:
								formValue.service_type === "AAR" &&
								formValue.associates[i].task !== null
									? formValue.associates[i].task.label
									: null,
							level: formValue.service_type === "AAR" ? "aarJob" : null,
							code:
								formValue.service_type === "AAR"
									? formValue.associates[i].task.value
									: null,
							source_system_id:
								formValue.service_type === "AAR" &&
								formValue.associates[i].task !== null
									? formValue.associates[i].task.value
									: null,
						},
						vmrs_system: {
							service_type: formValue.service_type === "VMRS" ? "vmrs" : null,
							name:
								formValue.vmrs_system && formValue.vmrs_system.label !== null
									? formValue.vmrs_system.label
									: null,
							level: formValue.service_type === "VMRS" ? "system" : null,
							code:
								formValue.vmrs_system.label !== null
									? formValue.vmrs_system.value
									: null,
							source_system_id: null,
						},
						vmrs_assembly: {
							service_type: formValue.service_type === "VMRS" ? "vmrs" : null,
							name:
								formValue.vmrs_assembly &&
								formValue.vmrs_assembly.label !== null
									? formValue.vmrs_assembly.label
									: null,
							level: formValue.service_type === "VMRS" ? "assembly" : null,
							code:
								formValue.vmrs_assembly.label !== null
									? formValue.vmrs_assembly.value
									: null,
							source_system_id: null,
						},
						vmrs_component: {
							service_type: formValue.service_type === "VMRS" ? "vmrs" : null,
							name:
								formValue.vmrs_component !== undefined &&
								formValue.vmrs_component.label !== null
									? formValue.vmrs_component.label
									: null,

							level: formValue.service_type === "VMRS" ? "component" : null,
							code:
								formValue.vmrs_component !== undefined &&
								formValue.vmrs_component.label !== null
									? formValue.vmrs_component.value
									: null,
							source_system_id: null,
						},
						vmrs_work_accomplished: {
							service_type: formValue.service_type === "VMRS" ? "vmrs" : null,
							name:
								formValue.service_type === "VMRS" &&
								formValue.associates[i].task.data !== undefined
									? formValue.associates[i].task.data.description
									: formValue.associates[i].task.vmrs_task_name,
							level:
								formValue.service_type === "VMRS" ? "workAccomplished" : null,
							code:
								formValue.service_type === "VMRS" &&
								formValue.associates[i].task.data !== undefined
									? formValue.associates[i].task.data.code
									: formValue.associates[i].task.value,
							source_system_id:
								formValue.service_type === "VMRS" &&
								formValue.associates[i].task.data !== undefined
									? formValue.associates[i].task.data.id
									: formValue.associates[i].task.source_system_id,
						},
						service_bundle_parts:
							editedParts.length > 0 ? editedParts : getParts,
					})
				}
				
			})
		}

		let errorMsg = []
		payload &&
			payload.map((data, index) => {
				if (data.service_bundle_parts.length === 0) {
					setShowPartsErrorMsg(true)
					errorMsg.push({
						err: `Please choose either Default parts or Addon parts in row ${
							index + 1
						}`,
					})
				}
			})

		setPartsErrorMsg(errorMsg)

		if (errorMsg.length === 0) {
			instance
				.post("/service_bundles/upsert", {
					service_bundle_rules: payload,
				})
				.then((res) => {
					if (res.status === 201) {
						closePopup(true)
						editSucccessful(true)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	let filterSelecteRecord = []
	let defaultEquipmentType = []
	let defaultAARJobs = []
	let defaultVMRSWorkAccomblished = []

	if (selectedEditData && selectedEditData.length > 0) {
		selectedEditData.map((selectedData) => {
			filterSelecteRecord.push({
				service_type: selectedData.service_type,
				oem: selectedData.oem,
				specification: selectedData.specification,

				aarSystem:
					selectedData.aar !== undefined ||
					selectedData.aar !== "" ||
					selectedData.aar !== null
						? selectedData.aar && selectedData.aar.system.name
						: null,
				aarSubSystem:
					selectedData.aar !== undefined ||
					selectedData.aar !== "" ||
					selectedData.aar !== null
						? selectedData.aar && selectedData.aar.subsystem.name
						: null,
				aarService:
					selectedData.aar !== undefined ||
					selectedData.aar !== "" ||
					selectedData.aar !== null
						? selectedData.aar && selectedData.aar.service.name
						: null,

				vmrsSystem:
					selectedData.vmrs !== undefined ||
					selectedData.vmrs !== "" ||
					selectedData.vmrs !== null
						? selectedData.vmrs && selectedData.vmrs.system.name
						: null,
				vmrsAssembly:
					selectedData.vmrs !== undefined ||
					selectedData.vmrs !== "" ||
					selectedData.vmrs !== null
						? selectedData.vmrs && selectedData.vmrs.assembly.name
						: null,
				vmrsComponent:
					selectedData.vmrs !== undefined ||
					selectedData.vmrs !== "" ||
					(selectedData.vmrs !== null &&
						selectedData.vmrs.component !== undefined)
						? selectedData.vmrs &&
						  selectedData.vmrs.component &&
						  selectedData.vmrs.component.name
						: null,
			})
			defaultEquipmentType.push({
				value: selectedData.equipment_type,
				label:
					selectedData.equipment_type === "tractor"
						? "Power Unit"
						: selectedData.equipment_type,
			})
			if (selectedData.aar !== undefined) {
				defaultAARJobs.push({
					value: selectedData.aar.job.source_system_id,
					label: selectedData.aar.job.name,
				})
			}
			if (
				selectedData.vmrs !== undefined &&
				selectedData.vmrs.work_accomplished !== undefined
			) {
				defaultVMRSWorkAccomblished.push({
					value: selectedData.vmrs.work_accomplished.code,
					label: selectedData.vmrs.work_accomplished.name,
				})
			}
		})
	}

	let defaultSeriveType = {
		value: filterSelecteRecord[0].service_type,
		label: filterSelecteRecord[0].service_type.toUpperCase(),
	}

	let defaultOEM = {
		value: filterSelecteRecord[0].oem,
		label: filterSelecteRecord[0].oem,
	}

	let defaultSpecification = filterSelecteRecord[0].specification

	// let defaultAARSystem = {
	// 	value: filterSelecteRecord[0].aarSystem,
	// 	label: filterSelecteRecord[0].aarSystem,
	// }

	// let defaultAARSubSystem = {
	// 	value: filterSelecteRecord[0].aarSubSystem,
	// 	label: filterSelecteRecord[0].aarSubSystem,
	// }

	// let defaultAARService = {
	// 	value: filterSelecteRecord[0].aarService,
	// 	label: filterSelecteRecord[0].aarService,
	// }

	// let defaultVMRSSystem = {
	// 	value: filterSelecteRecord[0].vmrsSystem,
	// 	label: filterSelecteRecord[0].vmrsSystem,
	// }

	// let defaultVMRSAssembly = {
	// 	value: filterSelecteRecord[0].vmrsAssembly,
	// 	label: filterSelecteRecord[0].vmrsAssembly,
	// }

	// if (filterSelecteRecord[0].vmrsComponent === undefined) {
	// 	defaultVMRSComponent = null
	// } else {
	// 	defaultVMRSComponent = {
	// 		value: filterSelecteRecord[0].vmrsComponent,
	// 		label: filterSelecteRecord[0].vmrsComponent,
	// 	}
	// }

	let updateParts_default = defaultPartsSelected
	let updateParts_addon = addonPartsSelected

	const insertDefaultPartsAt = (array, index, elementsArray) => {
		let parts = array.splice(index, 0, elementsArray)
		setDefaultPartsSelected(parts)
	}

	const insertAddOnPartsAt = (array, index, elementsArray) => {
		let parts = array.splice(index, 0, elementsArray)
		setAddonPartsSelected(parts)
	}

	const updateDefaultParts = (selectedOption, index) => {
		insertDefaultPartsAt(updateParts_default, index, selectedOption)
	}

	const updateAddonParts = (selectedOption, index) => {
		insertAddOnPartsAt(updateParts_addon, index, selectedOption)
	}

	let validateSchema

	validateSchema = Yup.object({
		associates: Yup.array().of(
			Yup.object().shape({
				equipment_name: Yup.object()
					.shape({
						label: Yup.string(),
						value: Yup.string(),
					})
					.nullable()
					.required("Required"),
				task: Yup.object()
					.shape({
						label: Yup.string(),
						value: Yup.string(),
					})
					.nullable()
					.required("Required"),
				//default_parts: Yup.array().nullable().required("Required"),
			})
		),
	})

	return (
		<div>
			<Formik
				enableReinitialize={true}
				initialValues={partsAssociates}
				validationSchema={validateSchema}
				onSubmit={async (values) => {
					await new Promise((r) => setTimeout(r, 500))
					getValues(values)
				}}
				validate={(values) => {
					if (values.associates.length === 0) {
						return { arr: "Required least one associated part" }
					}
				}}
			>
				{({
					values,
					errors,
					// touched,
					// handleChange,
					// handleBlur,
					// handleSubmit,
					// isSubmitting,
					// setFieldValue,
				}) => {
					return (
						<Form>
							<div className="popup-content-container">
								<div className="popup-title">Edit Associated Parts</div>
								<div className="popup-body">
									<div className="parts-details">
										<div className="input-row">
											<div className="chose-service-type">
												<div className="label">
													Selected type:{" "}
													<span
														className={
															defaultSeriveType.label === "AAR"
																? "service-type-bg-aar"
																: "service-type-bg-vmrs"
														}
													>
														{defaultSeriveType.label}
													</span>
												</div>
												<div className="hint">
													<sup>*</sup> Mandatory Fields
												</div>
											</div>
										</div>
										{selectedServiceType === "AAR" ||
											(defaultSeriveType.value === "aar" && (
												<div className="input-row">
													<div className="flex-grid header">
														<div>
															AAR System <sup>*</sup>
														</div>
														<div>
															AAR Sub system <sup>*</sup>
														</div>
														<div>
															AAR Service <sup>*</sup>
														</div>
													</div>
													<div className="flex-grid">
														<div className="input-elements async-dd-elements">
															<Field name={"aar_system"}>
																{({ field, form, meta }) => (
																	<FetchSystems
																		value={values.aar_system}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option)
																			setCurrentAARSystem(option)
																			setCurrentAARSubSystem(null)
																			setCurrentAARServiceList(null)
																			setAARCurrentTask(null)
																		}}
																	/>
																)}
															</Field>
														</div>
														<div className="input-elements async-dd-elements">
															<Field name={"aar_sub_system"}>
																{({ field, form, meta }) => (
																	<FetchSubSystems
																		value={values.aar_sub_system}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option)
																			setCurrentAARSubSystem(option)
																			setCurrentAARServiceList(null)
																			setCurrentAARSystem({
																				label:
																					option.data.service_component_name,
																				value: option.data.service_component_id,
																			})
																			setAARCurrentTask(null)
																		}}
																		serviceComponentId={
																			currentAARSystem !== null
																				? currentAARSystem &&
																				  currentAARSystem.value
																				: ""
																		}
																	/>
																)}
															</Field>
														</div>
														<div className="input-elements async-dd-elements">
															<Field name={"aar_service"}>
																{({ field, form, meta }) => (
																	<FetchServiceList
																		value={values.aar_service}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option)
																			setCurrentAARServiceList(option)
																			setAARTask(option.data.jobs[0])
																			setCurrentAARSystem({
																				label:
																					option.data.service_component_name,
																				value: option.data.service_component_id,
																			})
																			setCurrentAARSubSystem({
																				label: option.data.sub_component_name,
																				value: option.data.sub_component_id,
																			})
																		}}
																		currentSystemId={
																			currentAARSystem !== null &&
																			currentAARSystem.data !== undefined
																				? currentAARSystem.data.id
																				: ""
																		}
																		currentSubSystemId={
																			currentAARSubSystem !== null &&
																			currentAARSubSystem.data !== undefined
																				? currentAARSubSystem.data.id
																				: ""
																		}
																	/>
																)}
															</Field>
														</div>
													</div>
												</div>
											))}

										{selectedServiceType === "VMRS" ||
											(defaultSeriveType.value === "vmrs" && (
												<div className="input-row">
													<div className="flex-grid header">
														<div>
															VMRS System <sup>*</sup>
														</div>
														<div>
															VMRS Assembly <sup>*</sup>
														</div>
														<div>VMRS Component</div>
													</div>
													<div className="flex-grid">
														<div className="input-elements async-dd-elements">
															<Field name={"vmrs_system"}>
																{({ field, form, meta }) => (
																	<FetchVMRSSystems
																		value={values.vmrs_system}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option),
																				setCurrentVMRSSystem(option)
																			setCurrentVMRSAssembly(null)
																			setCurrentVMRSComponent(null)
																		}}
																		className="service_dropdown"
																	/>
																)}
															</Field>
														</div>
														<div className="input-elements async-dd-elements">
															<Field name={"vmrs_assembly"}>
																{({ field, form, meta }) => (
																	<FetchAssemblyList
																		value={values.vmrs_assembly}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option)
																			setCurrentVMRSAssembly(option)
																			setCurrentVMRSComponent(null)
																			if (currentVMRSSystem === null) {
																				setCurrentVMRSSystem({
																					label: option.data.system_description,
																					value: option.data.system_code,
																				})
																			}
																		}}
																		currentVMRSSystemCode={
																			currentVMRSSystem !== null &&
																			currentVMRSSystem.value !== undefined
																				? currentVMRSSystem.value
																				: selectedEditData[0].vmrs !== undefined
																				? selectedEditData[0].vmrs.system.code
																				: ""
																		}
																		className="service_dropdown"
																	/>
																)}
															</Field>
														</div>
														<div className="input-elements async-dd-elements">
															<Field name={"vmrs_component"}>
																{({ field, form, meta }) => (
																	<FetchComponentList
																		value={values.vmrs_component}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option)
																			setCurrentVMRSComponent(option)
																			setCurrentVMRSSystem({
																				label: option.data.system_description,
																				value: option.data.system_code,
																			})
																			setCurrentVMRSAssembly({
																				label: option.data.assembly_description,
																				value: option.data.assembly_code,
																			})
																		}}
																		currentVMRSSystemCode={
																			currentVMRSSystem !== null &&
																			currentVMRSSystem.value !== undefined
																				? currentVMRSSystem.value
																				: selectedEditData[0].vmrs !== undefined
																				? selectedEditData[0].vmrs.system.code
																				: ""
																		}
																		currentVMRSAssemblyCode={
																			currentVMRSAssembly !== null &&
																			currentVMRSAssembly.value !== undefined
																				? currentVMRSAssembly.value
																				: selectedEditData[0].vmrs !== undefined
																				? selectedEditData[0].vmrs.assembly.code
																				: null
																		}
																		className="service_dropdown"
																	/>
																)}
															</Field>
														</div>
													</div>
												</div>
											))}

										<div className="input-row">
											<div className="flex-grid header">
												<div className="input-elements">OEM</div>
												<div className="input-elements">Specification</div>
											</div>
											<div className="flex-grid">
												<div className="input-elements">
													<Field name={"oem"}>
														{({ field, form, meta }) => (
															<Select
																defaultValue={
																	defaultOEM.label !== null
																		? defaultOEM
																		: selectedOEM
																}
																options={OEM_options}
																onChange={(option) => {
																	setSelectedOEM(option)
																	form.setFieldValue(field.name, option)
																}}
																isClearable={true}
																className="service_dropdown"
																placeholder="Select OEM"
															/>
														)}
													</Field>
												</div>
												<div className="input-elements">
													<Field name={"tire_size"}>
														{({ field, form, meta }) => (
															<input
																type="text"
																placeholder="Specification"
																className="textbox"
																defaultValue={
																	defaultSpecification !== undefined
																		? defaultSpecification
																		: tireSize
																}
																onChange={(v) => {
																	form.setFieldValue(field.name, v.target.value)
																	setTireSize(v.target.value)
																}}
																handleChange={(e) =>
																	setTireSize(e.target.value)
																}
															/>
														)}
													</Field>
												</div>
											</div>
										</div>

										<div className="input-row">
											<FieldArray name="associates">
												{({ insert, remove, push }) => (
													<>
														<div className="cta-add">
															<button
																type="button"
																className="btn-regular btn-blue"
																onClick={() => push(getNames)}
															>
																Add Parts
															</button>
														</div>

														<div className="flex-grid header add-parts-header">
															<div>
																Equipments <sup>*</sup>
															</div>

															{selectedServiceType === "AAR" ||
															selectedServiceType === "VMRS" ||
															defaultSeriveType.value === "aar" ||
															defaultSeriveType.value === "vmrs" ? (
																<div>
																	Task <sup>*</sup>
																</div>
															) : (
																""
															)}

															<div>
																Default Parts <sup>*</sup>
															</div>
															<div>AddOn Parts</div>
														</div>
														<ul className="errorMessageList">
															{showPartsErrorMsg &&
																partsErrorMsg &&
																partsErrorMsg.map((error, index) => {
																	return (
																		<li key={index} className="hint">
																			{error.err}
																		</li>
																	)
																})}
														</ul>
														{values.associates &&
															values.associates.map((associate, index) => (
																<>
																	<div className="parts-addition">
																		<div className="flex-grid" key={index}>
																			<div className="input-elements">
																				<Field
																					name={`associates.${index}.equipment_name`}
																				>
																					{({ field, form, meta }) => (
																						<Select
																							defaultValue={
																								associate.equipment_name
																							}
																							options={equipmentTypesOptions}
																							onChange={(option) => {
																								form.setFieldValue(
																									field.name,
																									option
																								)
																							}}
																							className="service_dropdown"
																							placeholder="Select Equipment"
																						/>
																					)}
																				</Field>
																				<ErrorMessage
																					name={`associates.${index}.equipment_name`}
																				>
																					{(msg) => (
																						<div
																							style={{
																								color: "red",
																								fontSize: "1.2rem",
																							}}
																						>
																							{msg}
																						</div>
																					)}
																				</ErrorMessage>
																			</div>

																			{selectedServiceType === "AAR" ||
																				(defaultSeriveType.value === "aar" && (
																					<div className="input-elements">
																						<Field
																							name={`associates.${index}.task`}
																						>
																							{({ field, form, meta }) => (
																								<Select
																									defaultValue={associate.task}
																									options={aarTaskOption}
																									onChange={(option) => {
																										form.setFieldValue(
																											field.name,
																											option
																										)
																										setAARCurrentTask(option)
																									}}
																									placeholder="Select task"
																									className="service_dropdown"
																								/>
																							)}
																						</Field>
																						<ErrorMessage
																							name={`associates.${index}.task`}
																						>
																							{(msg) => (
																								<div
																									style={{
																										color: "red",
																										fontSize: "1.2rem",
																									}}
																								>
																									{msg}
																								</div>
																							)}
																						</ErrorMessage>
																					</div>
																				))}
																			{selectedServiceType === "VMRS" ||
																				(defaultSeriveType.value === "vmrs" && (
																					<div className="input-elements">
																						<Field
																							name={`associates.${index}.task`}
																						>
																							{({ field, form, meta }) => (
																								<>
																									<FetchVMRSTask
																										value={associate.task}
																										onChange={(option) => {
																											form.setFieldValue(
																												field.name,
																												option
																											)
																											setVMRSTask(option)
																										}}
																										className="service_dropdown"
																										vmrsAssemblyCode={
																											currentVMRSAssembly !==
																												null &&
																											currentVMRSAssembly.data !==
																												undefined
																												? currentVMRSAssembly
																														.data.code
																												: selectedEditData[0]
																														.vmrs.system.code
																										}
																										vmrsComponentCode={
																											currentVMRSComponent !==
																												null &&
																											currentVMRSComponent.data !==
																												undefined
																												? currentVMRSComponent
																														.data.code
																												: selectedEditData[0]
																														.vmrs.assembly.code
																										}
																									/>
																								</>
																							)}
																						</Field>
																						<ErrorMessage
																							name={`associates.${index}.task`}
																						>
																							{(msg) => (
																								<div
																									style={{
																										color: "red",
																										fontSize: "1.2rem",
																									}}
																								>
																									{msg}
																								</div>
																							)}
																						</ErrorMessage>
																					</div>
																				))}

																			<div className="input-elements">
																				<Field
																					name={`associates.${index}.default_parts`}
																				>
																					{({ field, form, meta }) => (
																						<>
																							<FetchParts
																								value={associate.default_parts}
																								onChange={(defaultParts) => {
																									form.setFieldValue(
																										field.name,
																										defaultParts
																									)
																									updateDefaultParts(
																										defaultParts,
																										index
																									)
																									setDefaultError(!defaultError)
																								}}
																							/>
																						</>
																					)}
																				</Field>
																				<ErrorMessage
																					name={`associates.${index}.default_parts`}
																				>
																					{(msg) => (
																						<div
																							style={{
																								color: "red",
																								fontSize: "1.2rem",
																							}}
																						>
																							{msg}
																						</div>
																					)}
																				</ErrorMessage>
																			</div>

																			<div className="input-elements">
																				<Field
																					name={`associates.${index}.addon_parts`}
																				>
																					{({ field, form, meta }) => (
																						<>
																							<FetchParts
																								value={associate.addon_parts}
																								onChange={(addOnParts) => {
																									form.setFieldValue(
																										field.name,
																										addOnParts
																									)
																									updateAddonParts(
																										addOnParts,
																										index
																									)
																								}}
																							/>
																						</>
																					)}
																				</Field>
																			</div>
																			<div className="close-cta">
																				<button
																					type="button"
																					className="btn-regular btn-red"
																					onClick={() => remove(index)}
																				>
																					Delete
																				</button>
																			</div>
																		</div>
																	</div>
																</>
															))}
													</>
												)}
											</FieldArray>
											{errors.arr && <div className="hint">{errors.arr}</div>}
										</div>
									</div>
								</div>
							</div>
							<div className="btn-save">
								<button type="submit" className="btn-regular btn-blue">
									Save
								</button>
							</div>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
}

export default EditServiceBundle
