import React, { useState, useEffect } from "react"
import { instance } from "../core/api"
import Select from "react-select"
import FetchSystems from "./FetchSystems"
import FetchSubSystems from "./FetchSubSystems"
import FetchServiceList from "./FetchServiceList"
import FetchVMRSSystems from "./FetchVMRSSystems"
import FetchAssemblyList from "./FetchAssemblyList"
import FetchComponentList from "./FetchComponentList"
import { Formik, Field, Form, FieldArray } from "formik"

const EditPartsAssociate = (props) => {
	const [selectedServiceType, setSelectedServiceType] = useState("")
	const [service_types, setServiceTypes] = useState({})
	const [currentAARSystem, setCurrentAARSystem] = useState(null)
	const [currentAARSubSystem, setCurrentAARSubSystem] = useState(null)
	const [currentAARServiceList, setCurrentAARServiceList] = useState(null)
	const [currentVMRSSystem, setCurrentVMRSSystem] = useState(null)
	const [currentVMRSAssembly, setCurrentVMRSAssembly] = useState(null)
	const [currentVMRSComponent, setCurrentVMRSComponent] = useState(null)
	const [showError, setShowError] = useState(false)
	const [aarSystemError, setAARSystemError] = useState(false)
	const [vmrsSystemError, setVMRSSystemError] = useState(false)
	const [vmrsAssemblyError, setVMRSAssemblyError] = useState(false)
	const [serviceTypeError, setServiceTypeError] = useState(false)
	const [errorsList, setErrorsList] = useState([])

	const { id, parts_services, part_code } = props.selectedPart
	const { closePopup } = props

	useEffect(() => {
		instance
			.get("/service_types")
			.then((res) => {
				let service_types = res.data
				if (service_types !== undefined) {
					setServiceTypes(service_types)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}, [props, showError])

	let associate = []

	if (parts_services && parts_services.length > 0) {
		for (let i = 0; i < parts_services.length; i++) {
			associate.push({
				service_type: parts_services[i].service_type,
				aar_system:
					parts_services[i].aar !== undefined &&
					parts_services[i].aar.system !== undefined
						? {
								label:
									parts_services[i].aar && parts_services[i].aar.system.name,
								value:
									parts_services[i].aar &&
									parts_services[i].aar.system.source_system_id,
						  }
						: null,
				aar_subsystem:
					parts_services[i].aar !== undefined &&
					parts_services[i].aar.subsystem !== undefined
						? {
								label:
									parts_services[i].aar && parts_services[i].aar.subsystem.name,
								value:
									parts_services[i].aar &&
									parts_services[i].aar.subsystem.source_system_id,
						  }
						: null,
				aar_service:
					parts_services[i].aar !== undefined &&
					parts_services[i].aar.service !== undefined
						? {
								label:
									parts_services[i].aar && parts_services[i].aar.service.name,
								value:
									parts_services[i].aar &&
									parts_services[i].aar.service.source_system_id,
						  }
						: null,
				vmrs_system:
					parts_services[i].vmrs !== undefined &&
					parts_services[i].vmrs.system !== undefined
						? {
								label:
									parts_services[i].vmrs && parts_services[i].vmrs.system.name,
								value:
									parts_services[i].vmrs && parts_services[i].vmrs.system.code,
						  }
						: null,
				vmrs_assembly:
					parts_services[i].vmrs !== undefined &&
					parts_services[i].vmrs.assembly !== undefined
						? {
								label:
									parts_services[i].vmrs &&
									parts_services[i].vmrs.assembly.name,
								value:
									parts_services[i].vmrs &&
									parts_services[i].vmrs.assembly.code,
						  }
						: null,
				vmrs_component:
					parts_services[i].vmrs !== undefined &&
					parts_services[i].vmrs.component !== undefined
						? {
								label:
									parts_services[i].vmrs &&
									parts_services[i].vmrs.component.name,
								value:
									parts_services[i].vmrs &&
									parts_services[i].vmrs.component.code,
						  }
						: null,
			})
		}
	}

	const getNames = {
		service_type: {
			label: null,
			value: null,
		},
		aar_system: null,
		aar_subsystem: null,
		aar_service: null,
		vmrs_system: null,
		vmrs_assembly: null,
		vmrs_component: null,
	}

	const partsAssociates = {
		associates: associate,
	}

	const options = [
		{ value: service_types.aar, label: service_types.aar },
		{ value: service_types.vmrs, label: service_types.vmrs },
	]

	let payload = []

	const getValues = (formValue) => {
		formValue.associates.map((associate, index) => {
			let serviceType
			if (associate.service_type === "AAR") {
				serviceType = "aar"
			} else if (associate.service_type.value === "AAR") {
				serviceType = "aar"
			} else if (associate.service_type === "VMRS") {
				serviceType = "vmrs"
			} else if (associate.service_type.value === "VMRS") {
				serviceType = "vmrs"
			} else {
				serviceType = ""
			}

			payload.push({
				service_type: serviceType,
				aar_system: {
					service_type: serviceType === "aar" ? "aar" : null,
					name:
						associate.aar_system !== null ? associate.aar_system.label : null,
					level: serviceType === "aar" ? "system" : null,
					code: null,
					source_system_id:
						associate.aar_system !== null
							? parseInt(associate.aar_system.value)
							: null,
				},
				aar_subsystem: {
					service_type: serviceType === "aar" ? "aar" : null,
					name:
						associate.aar_subsystem !== null
							? associate.aar_subsystem.label
							: null,
					level: serviceType === "aar" ? "subsystem" : null,
					code: null,
					source_system_id:
						associate.aar_subsystem !== null
							? parseInt(associate.aar_subsystem.value)
							: null,
				},
				aar_service: {
					service_type: serviceType === "aar" ? "aar" : null,
					name:
						associate.aar_service !== null ? associate.aar_service.label : null,
					level: serviceType === "aar" ? "service" : null,
					code: null,
					source_system_id:
						associate.aar_service !== null
							? parseInt(associate.aar_service.value)
							: null,
				},
				vmrs_system: {
					service_type: serviceType === "vmrs" ? "vmrs" : null,
					name:
						associate.vmrs_system !== null ? associate.vmrs_system.label : null,
					level: serviceType === "vmrs" ? "system" : null,
					code:
						associate.vmrs_system !== null ? associate.vmrs_system.value : null,
					source_system_id: null,
				},
				vmrs_assembly: {
					service_type: serviceType === "vmrs" ? "vmrs" : null,
					name:
						associate.vmrs_assembly !== null
							? associate.vmrs_assembly.label
							: null,
					level: serviceType === "vmrs" ? "assembly" : null,
					code:
						associate.vmrs_assembly !== null
							? associate.vmrs_assembly.value
							: null,
					source_system_id: null,
				},
				vmrs_component: {
					service_type: serviceType === "vmrs" ? "vmrs" : null,
					name:
						associate.vmrs_component !== null
							? associate.vmrs_component.label
							: null,
					level: serviceType === "vmrs" ? "component" : null,
					code:
						associate.vmrs_component !== null
							? associate.vmrs_component.value
							: null,
					source_system_id: null,
				},
			})
		})

		let errors = []
		let validate = true

		payload.map((data, index) => {
			if (data.service_type === "") {
				setServiceTypeError(!serviceTypeError)
				errors.push({
					err: "Please choose service type in row " + (index + 1),
				})
				validate = false
			}
			if (data.service_type === "aar" && data.aar_system.name === null) {
				setAARSystemError(!aarSystemError)
				errors.push({
					err: "Please choose AAR system in row " + (index + 1),
				})
				validate = false
			}

			if (data.service_type === "vmrs" && data.vmrs_system.name === null) {
				setVMRSSystemError(!vmrsSystemError)
				errors.push({
					err: "Please choose VMRS System in row " + (index + 1),
				})
				closePopup(false)
				validate = false
			}

			if (data.service_type === "vmrs" && data.vmrs_assembly.name === null) {
				setVMRSAssemblyError(!vmrsAssemblyError)
				errors.push({
					err: "Please choose VMRS Assembly in row " + (index + 1),
				})
				closePopup(false)
				validate = false
			}
		})

		setErrorsList(errors)

		if (validate) {
			instance
				.put(`/parts/${id}/upsert`, {
					parts_services: payload,
				})
				.then((res) => {
					if (res.data !== undefined || res.data !== null) {
						closePopup(true)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	let defaultServiceType = []

	parts_services &&
		parts_services.map((part_service) => {
			defaultServiceType.push({
				value: part_service.service_type,
				label: part_service.service_type,
			})
		})

	return (
		<div className="popup-content-container">
			<div className="popup-title">
				Edit <span className="highlight">{part_code}</span> Associate Parts
			</div>
			<div className="popup-body">
				<Formik
					enableReinitialize={true}
					initialValues={partsAssociates}
					onSubmit={async (values) => {
						await new Promise((r) => setTimeout(r, 500))
						getValues(values)
					}}
					validate={(values) => {
						if (values.associates.length === 0) {
							return { arr: "Required at least one associated part" }
						}
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						handleReset,
					}) => {
						return (
							<Form>
								<div className="associate-details">
									<FieldArray
										name="associates"
										render={({ insert, remove, push }) => (
											<div>
												<div className="add-rows">
													<div className="info">
														<i>
															<sup>*</sup> Fields are mandatory
														</i>
													</div>
													<div className="btn-add-service">
														<button
															type="button"
															className="btn-regular btn-blue"
															onClick={() => push(getNames)}
														>
															Add Services
														</button>
													</div>
												</div>
												<div className="errors-list">
													<ul>
														{errorsList &&
															errorsList.map((error, index) => {
																return <li key={index}>{error.err}</li>
															})}
													</ul>
												</div>
												{values.associates.length > 0 &&
													values.associates.map((associate, index) => (
														<div key={index} className="row-block">
															<div className="input-elements btn-close">
																<button
																	type="button"
																	className="btn-regular btn-red"
																	onClick={() => {
																		defaultServiceType = []
																		remove(index)
																	}}
																>
																	X
																</button>
															</div>

															<div className="input-row">
																<div className="flex-grid header">
																	<div className="service-type-title">
																		Select Type <sup>*</sup>
																	</div>
																	{values.associates &&
																		values.associates[index].service_type ===
																			"AAR" && (
																			<>
																				<div className="async-dd-title">
																					AAR System <sup>*</sup>
																				</div>
																				<div className="async-dd-title">
																					AAR Subsystem
																				</div>
																				<div className="async-dd-title">
																					AAR Service
																				</div>
																			</>
																		)}

																	{values.associates &&
																		values.associates[index].service_type
																			.value === "AAR" && (
																			<>
																				<div className="async-dd-title">
																					AAR System <sup>*</sup>
																				</div>
																				<div className="async-dd-title">
																					AAR Subsystem
																				</div>
																				<div className="async-dd-title">
																					AAR Service
																				</div>
																			</>
																		)}

																	{values.associates &&
																		values.associates[index].service_type ===
																			"VMRS" && (
																			<>
																				<div className="async-dd-title">
																					VMRS System <sup>*</sup>
																				</div>
																				<div className="async-dd-title">
																					VMRS Assembly <sup>*</sup>
																				</div>
																				<div className="async-dd-title">
																					VMRS Component
																				</div>
																			</>
																		)}

																	{values.associates &&
																		values.associates[index].service_type
																			.value === "VMRS" && (
																			<>
																				<div className="async-dd-title">
																					VMRS System <sup>*</sup>
																				</div>
																				<div className="async-dd-title">
																					VMRS Assembly <sup>*</sup>
																				</div>
																				<div className="async-dd-title">
																					VMRS Component
																				</div>
																			</>
																		)}
																</div>
																<div className="flex-grid">
																	<div className="input-elements service-dropdown">
																		<Field
																			name={`associates.${index}.service_type`}
																		>
																			{({ field, form, meta }) => (
																				<Select
																					defaultValue={
																						defaultServiceType[index]
																					}
																					onChange={(option) => {
																						form.setFieldValue(
																							field.name,
																							option
																						)
																						setSelectedServiceType(option)
																					}}
																					options={options}
																					className="service_dropdown"
																					placeholder="Type"
																				/>
																			)}
																		</Field>
																	</div>

																	{values.associates &&
																		values.associates[index].service_type ===
																			"AAR" && (
																			<>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.aar_system`}
																					>
																						{({ field, form, meta }) => (
																							<FetchSystems
																								value={
																									values.associates[index]
																										.aar_system
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentAARSystem(option)
																									setCurrentAARSubSystem(null)
																									setCurrentAARServiceList(null)
																								}}
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.aar_subsystem`}
																					>
																						{({ field, form, meta }) => (
																							<FetchSubSystems
																								value={
																									values.associates[index]
																										.aar_subsystem
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentAARSubSystem(option)
																									setCurrentAARServiceList(null)
																									setCurrentAARSystem({
																										label:
																											option.data
																												.service_component_name,
																										value:
																											option.data
																												.service_component_id,
																									})
																								}}
																								serviceComponentId={
																									values.associates[index]
																										.aar_system !== null
																										? values.associates[index]
																												.aar_system.value
																										: currentAARSystem !==
																												null &&
																										  currentAARSystem.data !==
																												undefined
																										? currentAARSystem.data.id
																										: null
																									// 	currentAARSystem !== null &&
																									// 	currentAARSystem.data !==
																									// 		undefined
																									// 		? currentAARSystem.data.id
																									// 		: parts_services[index] !==
																									// 				undefined &&
																									// 		  parts_services[index]
																									// 				.aar !== undefined
																									// 		? parts_services[index].aar
																									// 				.system.source_system_id
																									// 		: null
																								}
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.aar_service`}
																					>
																						{({ field, form, meta }) => (
																							<FetchServiceList
																								value={
																									values.associates[index]
																										.aar_service
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentAARServiceList(
																										option
																									)
																									setCurrentAARSystem({
																										label:
																											option.data
																												.service_component_name,
																										value:
																											option.data
																												.service_component_id,
																									})
																									setCurrentAARSubSystem({
																										label:
																											option.data
																												.sub_component_name,
																										value:
																											option.data
																												.sub_component_id,
																									})
																								}}
																								currentSystemId={
																									values.associates[index]
																										.aar_system !== null
																										? values.associates[index]
																												.aar_system.value
																										: currentAARSystem !==
																												null &&
																										  currentAARSystem.data !==
																												undefined
																										? currentAARSystem.data.id
																										: null
																									// currentAARSystem !== null &&
																									// currentAARSystem.data !==
																									// 	undefined
																									// 	? currentAARSystem.data.id
																									// 	: parts_services[index] !==
																									// 			undefined &&
																									// 	  parts_services[index].aar
																									// 			.system !== undefined
																									// 	? parts_services[index].aar
																									// 			.system.source_system_id
																									// 	: null
																								}
																								currentSubSystemId={
																									values.associates[index]
																										.aar_subsystem !== null
																										? values.associates[index]
																												.aar_subsystem.value
																										: currentAARSubSystem !==
																												null &&
																										  currentAARSubSystem.data !==
																												undefined
																										? currentAARSubSystem.data
																												.id
																										: null
																									// currentAARSubSystem !==
																									// 	null &&
																									// currentAARSubSystem.data !==
																									// 	undefined
																									// 	? currentAARSubSystem.data
																									// 			.id
																									// 	: parts_services[index] !==
																									// 			undefined &&
																									// 	  parts_services[index].aar
																									// 			.subsystem !== undefined
																									// 	? parts_services[index].aar
																									// 			.subsystem
																									// 			.source_system_id
																									// 	: null
																								}
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																			</>
																		)}

																	{values.associates &&
																		values.associates[index].service_type
																			.value === "AAR" && (
																			<>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.aar_system`}
																					>
																						{({ field, form, meta }) => (
																							<FetchSystems
																								value={
																									values.associates[index]
																										.aar_system
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentAARSystem(option)
																									setCurrentAARSubSystem(null)
																									setCurrentAARServiceList(null)
																								}}
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.aar_subsystem`}
																					>
																						{({ field, form, meta }) => (
																							<FetchSubSystems
																								value={
																									values.associates[index]
																										.aar_subsystem
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentAARSubSystem(option)
																									setCurrentAARServiceList(null)
																									setCurrentAARSystem({
																										label:
																											option.data
																												.service_component_name,
																										value:
																											option.data
																												.service_component_id,
																									})
																								}}
																								serviceComponentId={
																									values.associates[index]
																										.aar_system !== null
																										? values.associates[index]
																												.aar_system.value
																										: currentAARSystem !==
																												null &&
																										  currentAARSystem.data !==
																												undefined
																										? currentAARSystem.data.id
																										: null
																									// 	currentAARSystem !== null &&
																									// 	currentAARSystem.data !==
																									// 		undefined
																									// 		? currentAARSystem.data.id
																									// 		: parts_services[index] !==
																									// 				undefined &&
																									// 		  parts_services[index]
																									// 				.aar !== undefined
																									// 		? parts_services[index].aar
																									// 				.system.source_system_id
																									// 		: null
																								}
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.aar_service`}
																					>
																						{({ field, form, meta }) => (
																							<FetchServiceList
																								value={
																									values.associates[index]
																										.aar_service
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentAARServiceList(
																										option
																									)
																									setCurrentAARSystem({
																										label:
																											option.data
																												.service_component_name,
																										value:
																											option.data
																												.service_component_id,
																									})
																									setCurrentAARSubSystem({
																										label:
																											option.data
																												.sub_component_name,
																										value:
																											option.data
																												.sub_component_id,
																									})
																								}}
																								currentSystemId={
																									values.associates[index]
																										.aar_system !== null
																										? values.associates[index]
																												.aar_system.value
																										: currentAARSystem !==
																												null &&
																										  currentAARSystem.data !==
																												undefined
																										? currentAARSystem.data.id
																										: null
																									// currentAARSystem !== null &&
																									// currentAARSystem.data !==
																									// 	undefined
																									// 	? currentAARSystem.data.id
																									// 	: parts_services[index] !==
																									// 			undefined &&
																									// 	  parts_services[index].aar
																									// 			.system !== undefined
																									// 	? parts_services[index].aar
																									// 			.system.source_system_id
																									// 	: null
																								}
																								currentSubSystemId={
																									values.associates[index]
																										.aar_subsystem !== null
																										? values.associates[index]
																												.aar_subsystem.value
																										: currentAARSubSystem !==
																												null &&
																										  currentAARSubSystem.data !==
																												undefined
																										? currentAARSubSystem.data
																												.id
																										: null
																									// currentAARSubSystem !==
																									// 	null &&
																									// currentAARSubSystem.data !==
																									// 	undefined
																									// 	? currentAARSubSystem.data
																									// 			.id
																									// 	: parts_services[index] !==
																									// 			undefined &&
																									// 	  parts_services[index].aar
																									// 			.subsystem !== undefined
																									// 	? parts_services[index].aar
																									// 			.subsystem
																									// 			.source_system_id
																									// 	: null
																								}
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																			</>
																		)}

																	{values.associates &&
																		values.associates[index].service_type ===
																			"VMRS" && (
																			<>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.vmrs_system`}
																					>
																						{({ field, form, meta }) => (
																							<FetchVMRSSystems
																								value={
																									values.associates[index]
																										.vmrs_system
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									),
																										setCurrentVMRSSystem(option)
																									setCurrentVMRSAssembly(null)
																									setCurrentVMRSComponent(null)
																								}}
																								className="service_dropdown"
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.vmrs_assembly`}
																					>
																						{({ field, form, meta }) => (
																							<FetchAssemblyList
																								value={
																									values.associates[index]
																										.vmrs_assembly
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentVMRSAssembly(option)
																									setCurrentVMRSComponent(null)
																									setCurrentVMRSSystem({
																										label:
																											option &&
																											option.data
																												.system_description,
																										value:
																											option &&
																											option.data.system_code,
																									})
																								}}
																								currentVMRSSystemCode={
																									values.associates[index]
																										.vmrs_assembly !== null
																										? values.associates[index]
																												.vmrs_assembly.value
																										: currentVMRSSystem !==
																												null &&
																										  currentVMRSSystem.data !==
																												undefined
																										? currentVMRSSystem.data
																												.code
																										: null
																									// currentVMRSSystem !== null
																									// 	? currentVMRSSystem &&
																									// 	  currentVMRSSystem.value
																									// 	: parts_services[index] !==
																									// 			undefined &&
																									// 	  parts_services[index].vmrs
																									// 			.system !== undefined
																									// 	? parts_services[index].vmrs
																									// 			.system.code
																									// 	: null
																								}
																								className="service_dropdown"
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.vmrs_component`}
																					>
																						{({ field, form, meta }) => (
																							<FetchComponentList
																								value={
																									values.associates[index]
																										.vmrs_component
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentVMRSComponent(
																										option
																									)
																									setCurrentVMRSSystem({
																										label:
																											option &&
																											option.data
																												.system_description,
																										value:
																											option &&
																											option.data.system_code,
																									})
																									setCurrentVMRSAssembly({
																										label:
																											option &&
																											option.data
																												.assembly_description,
																										value:
																											option &&
																											option.data.assembly_code,
																									})
																								}}
																								currentVMRSSystemCode={
																									values.associates[index]
																										.vmrs_system !== null
																										? values.associates[index]
																												.vmrs_system.value
																										: currentVMRSSystem !==
																												null &&
																										  currentVMRSSystem.data !==
																												undefined
																										? currentVMRSSystem.data
																												.code
																										: null
																									// currentVMRSSystem !== null
																									// 	? currentVMRSSystem.value
																									// 	: parts_services[index] !==
																									// 			undefined &&
																									// 	  parts_services[index]
																									// 			.vmrs !== undefined
																									// 	? parts_services[index].vmrs
																									// 			.system.code
																									// 	: null
																								}
																								currentVMRSAssemblyCode={
																									values.associates[index]
																										.vmrs_assembly !== null
																										? values.associates[index]
																												.vmrs_assembly.value
																										: currentVMRSAssembly !==
																												null &&
																										  currentVMRSAssembly.data !==
																												undefined
																										? currentVMRSAssembly.data
																												.code
																										: null
																									// currentVMRSAssembly !== null
																									// 	? currentVMRSAssembly.value
																									// 	: parts_services[index]
																									// 			.vmrs !== undefined &&
																									// 	  parts_services[index].vmrs
																									// 			.assembly !== undefined
																									// 	? parts_services[index].vmrs
																									// 			.assembly.code
																									// 	: null
																								}
																								className="service_dropdown"
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																			</>
																		)}
																	{values.associates &&
																		values.associates[index].service_type
																			.value === "VMRS" && (
																			<>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.vmrs_system`}
																					>
																						{({ field, form, meta }) => (
																							<FetchVMRSSystems
																								value={
																									values.associates[index]
																										.vmrs_system
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									),
																										setCurrentVMRSSystem(option)
																									setCurrentVMRSAssembly(null)
																									setCurrentVMRSComponent(null)
																								}}
																								className="service_dropdown"
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.vmrs_assembly`}
																					>
																						{({ field, form, meta }) => (
																							<FetchAssemblyList
																								value={
																									values.associates[index]
																										.vmrs_assembly
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentVMRSAssembly(option)
																									setCurrentVMRSComponent(null)
																									setCurrentVMRSSystem({
																										label:
																											option &&
																											option.data
																												.system_description,
																										value:
																											option &&
																											option.data.system_code,
																									})
																								}}
																								currentVMRSSystemCode={
																									values.associates[index]
																										.vmrs_assembly !== null
																										? values.associates[index]
																												.vmrs_assembly.value
																										: currentVMRSSystem !==
																												null &&
																										  currentVMRSSystem.data !==
																												undefined
																										? currentVMRSSystem.data
																												.code
																										: null
																									// currentVMRSSystem !== null
																									// 	? currentVMRSSystem &&
																									// 	  currentVMRSSystem.value
																									// 	: parts_services[index] !==
																									// 			undefined &&
																									// 	  parts_services[index].vmrs
																									// 			.system !== undefined
																									// 	? parts_services[index].vmrs
																									// 			.system.code
																									// 	: null
																								}
																								className="service_dropdown"
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																				<div className="input-elements async-dd-elements">
																					<Field
																						name={`associates.${index}.vmrs_component`}
																					>
																						{({ field, form, meta }) => (
																							<FetchComponentList
																								value={
																									values.associates[index]
																										.vmrs_component
																								}
																								onChange={(option) => {
																									form.setFieldValue(
																										field.name,
																										option
																									)
																									setCurrentVMRSComponent(
																										option
																									)
																									setCurrentVMRSSystem({
																										label:
																											option &&
																											option.data
																												.system_description,
																										value:
																											option &&
																											option.data.system_code,
																									})
																									setCurrentVMRSAssembly({
																										label:
																											option &&
																											option.data
																												.assembly_description,
																										value:
																											option &&
																											option.data.assembly_code,
																									})
																								}}
																								currentVMRSSystemCode={
																									values.associates[index]
																										.vmrs_system !== null
																										? values.associates[index]
																												.vmrs_system.value
																										: currentVMRSSystem !==
																												null &&
																										  currentVMRSSystem.data !==
																												undefined
																										? currentVMRSSystem.data
																												.code
																										: null
																									// currentVMRSSystem !== null
																									// 	? currentVMRSSystem.value
																									// 	: parts_services[index] !==
																									// 			undefined &&
																									// 	  parts_services[index]
																									// 			.vmrs !== undefined
																									// 	? parts_services[index].vmrs
																									// 			.system.code
																									// 	: null
																								}
																								currentVMRSAssemblyCode={
																									values.associates[index]
																										.vmrs_assembly !== null
																										? values.associates[index]
																												.vmrs_assembly.value
																										: currentVMRSAssembly !==
																												null &&
																										  currentVMRSAssembly.data !==
																												undefined
																										? currentVMRSAssembly.data
																												.code
																										: null
																									// currentVMRSAssembly !== null
																									// 	? currentVMRSAssembly.value
																									// 	: parts_services[index]
																									// 			.vmrs !== undefined &&
																									// 	  parts_services[index].vmrs
																									// 			.assembly !== undefined
																									// 	? parts_services[index].vmrs
																									// 			.assembly.code
																									// 	: null
																								}
																								className="service_dropdown"
																								showError={showError}
																							/>
																						)}
																					</Field>
																				</div>
																			</>
																		)}
																</div>
															</div>
														</div>
													))}
											</div>
										)}
									/>
									{errors.arr && <div className="hint">{errors.arr}</div>}
								</div>
								<div className="cta-buttons">
									<button type="submit" className="btn-regular btn-blue">
										Submit
									</button>
								</div>
							</Form>
						)
					}}
				</Formik>
			</div>
		</div>
	)
}

export default EditPartsAssociate
