import React, { useState, useEffect, useRef } from "react"
import Table, { TableHeader, TableBody } from "./CRUDListServiceBundles"
import Loader from "react-loader-spinner"
import { instance, partsEnabled } from "../core/api"
import ServiceBundlesLabels from "../ServiceBundles/serviceBundlesLabels"
import PopupServiceBundle from "../core/PopupServiceBundle"
import Popup from "../core/Popup"
import Pagination from "../core/Pagination"
import AddAssociates from "./AddAssociates"
import EditServiceBundle from "./EditServiceBundle"
import ImportServiceBundles from "./ImportServiceBundles"

const ServiceBundles = (props) => {
	const { userInfo } = props
	const [oems, setOems] = useState({})
	const [equipmentTypes, setEquipmentTypes] = useState([])
	const [ServiceBundlesData, setServiceBundlesData] = useState([])
	const [metaData, setMetaData] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [isDeletePopupOpen, setIsDeletePopuupOpen] = useState(false)
	const [loadparts, setLoadParts] = useState([])
	const [rowValue, setRowValue] = useState(null)
	const [currentData, setCurrentData] = useState([])
	const [currentPage, setCurrentPage] = useState(null)
	const [totalPages, setTotalPages] = useState(null)
	const [selectedRecordId, setSelectedRecordId] = useState("")
	const [showMessage, setShowMessage] = useState(false)
	const [showDeleteMessage, setShowDeleteMessage] = useState(false)
	const [showDropdown, setShowDropdown] = useState(false)

	const [selectedServiceType, setSelectedServiceType] = useState(null)
	const [search, setSearch] = useState("")

	const [openEditPopup, setOpenEditPopup] = useState(false)
	const [isEditPopupOpen, setIsEditPopupOpen] = useState(false)
	const [selectedEditData, setselectedEditData] = useState(null)

	const [showNewRecordMessage, setShowNewRecordMessage] = useState(false)
	const [showAddSuccessMessage, setShowAddSuccessMessage] = useState(false)
	const [showEditMessage, setShowEditMessage] = useState(false)
	const [showEditSuccessMessage, setShowEditSuccessMessage] = useState(false)
	const [showLoaderForListing, setShowLoaderForListing] = useState(true)
	const [showEmptyMsg, setShowEmptyMsg] = useState(false)
	const [downloadStarted, setDownloadStarted] = useState(false)

	let current_page = metaData.current_page
	let next_page = metaData.next_page
	let per_page = metaData.per_page
	let total_count = metaData.total_count
	let total_pages = metaData.total_pages

	const node = useRef()

	useEffect(() => {
		document.addEventListener("mousedown", handleClick)

		instance
			.get(`/service_bundles/grouped/?page=${metaData.current_page}&search=${search}`)
			.then((res) => {
				setShowLoaderForListing(true)
				if (res.status === 200 && res.data.service_bundle_rules.length > 0) {
					setShowLoaderForListing(false)
					const { service_bundle_rules, meta } = res.data
					setServiceBundlesData(service_bundle_rules)
					setMetaData(meta)
					setShowEmptyMsg(false)
				} else {
					setShowLoaderForListing(false)
					setShowEmptyMsg(true)
				}
				
			})
			.catch((error) => {
				console.log(error)
			})

		partsEnabled
			.get("/")
			.then((res) => {
				setLoadParts(res.parts)
			})
			.catch((error) => {
				console.log(error)
			})

		instance
			.get("/oems")
			.then((res) => {
				if (res !== undefined) {
					setOems(res.data)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		instance
			.get("/service_codes/equipment_types")
			.then((res) => {
				if (res !== undefined) {
					setEquipmentTypes(res.data.items)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		const timer = setTimeout(() => {
			setShowMessage(false)
			setShowNewRecordMessage(false)
			setShowEditMessage(false)
			setDownloadStarted(false)
		}, 1000)

		// To clear or cancel a timer, you call the clearTimeout(); method,
		// passing in the timer object that you created into clearTimeout().

		//return () => clearTimeout(timer);

		return () => {
			document.removeEventListener("mousedown", handleClick)
			clearTimeout(timer)
		}
	}, [showMessage, showNewRecordMessage, showEditMessage, downloadStarted])

	const handleClick = (e) => {
		if (node.current.contains(e.target)) {
			// inside click
			return
		}
		// outside click
		setShowDropdown(false)
	}

	const onAddViewAssociateParts = (rowValue) => {
		instance
			.get("/service_bundles/", {
				params: {
					rule_ids: rowValue.rule_ids.map((rule_id, i) => {
						return rule_id
					}),
				},
			})
			.then((res) => {
				if (res.data !== undefined) {
					setselectedEditData(res.data.service_bundle_rules)
					setIsEditPopupOpen(!isEditPopupOpen)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	// search functions

	const handleSearchInput = (e) => {
		setSearch(e.target.value)
		if (search === "" && search.length === 1) {
			instance
				.get("service_bundles/grouped", {
					params: {
						search: search,
						page: metaData.current_page
					}
				})
				.then((res) => {
					const { service_bundle_rules, meta } = res.data
					setServiceBundlesData(service_bundle_rules)
					setMetaData(meta)
				})
				.catch((error) => {
					console.log(error)
				})
		} else {
			fetchServiceBundlesData(search, current_page)
		}
	}

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			fetchServiceBundlesData(search, current_page)
		}
	}

	const handleSearchSubmit = (e) => {
		if (e.target.value === "") {
			instance
				.get("/service_bundles/grouped", {
					params: {
						search: search.replace(/\s+/g, "_"),
						page: metaData.current_page
					},
				})
				.then((res) => {
					const { service_bundle_rules, meta } = res.data
					setServiceBundlesData(service_bundle_rules)
					setMetaData(meta)
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const fetchServiceBundlesData = (search, current_page) => {
		instance
			.get("/service_bundles/grouped", {
				params: {
					search: search,
					page: current_page
				}
			})
			.then((res) => {
				const { service_bundle_rules, meta } = res.data
				setServiceBundlesData(service_bundle_rules)
				setMetaData(meta)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	// search function end

	// delete service bundles

	const onRemoveClick = (id) => {
		setSelectedRecordId(id)
		toggleRemovePopup()
	}

	const handleRemovePart = () => {
		deleteServiceBundle(selectedRecordId)
		setShowMessage(true)
		setShowDeleteMessage(true)
		toggleRemovePopup()
	}

	const deleteServiceBundle = (ids) => {
		//let currentPage = Math.ceil(total_count / 10)
		instance
			.delete(`/service_bundles/${ids}`)
			.then((res) => {
				if (res.status === 200) {
					setShowMessage(!showMessage)
					let currPage
					let remainder = metaData.total_count % 10
					if (remainder === 1) {
						currPage = metaData.total_pages - 1
					} else {
						currPage = metaData.current_page
					}
					instance
						.get(`/service_bundles/grouped/?page=${currPage}&search=${search}`)
						.then((res) => {
							if (res.status === 200) {
								const { service_bundle_rules, meta } = res.data
								setServiceBundlesData(service_bundle_rules)
								setMetaData(meta)
							}
						})
						.catch((error) => {
							console.log(error)
						})
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const onPageChanged = (data) => {
		const { currentPage, totalPages, pageLimit } = data
		const offset = currentPage * pageLimit
		const currData =
			ServiceBundlesData !== undefined
				? ServiceBundlesData.slice(offset, offset + pageLimit)
				: ""
		setCurrentPage(currentPage)
		setTotalPages(totalPages)
		setCurrentData(currData)

		instance
			.get("/service_bundles/grouped", {
				params: {
					page: currentPage,
					search: search,
				},
			})
			.then((res) => {
				const { service_bundle_rules, meta } = res.data
				setServiceBundlesData(service_bundle_rules)
				setMetaData(meta)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const showAddAssociationPopup = (e) => {
		e.preventDefault()
		togglePopup()
	}

	const closePopup = (status) => {
		if (status) {
			togglePopup()
			toggleEditPopup()
		}
	}

	const togglePopup = () => {
		setIsOpen(!isOpen)
	}

	const toggleRemovePopup = () => {
		setIsDeletePopuupOpen(!isDeletePopupOpen)
	}

	const toggleEditPopup = () => {
		setIsEditPopupOpen(false)
		setIsOpen(false)
	}

	const reloadRulesIndex = (arg) => {
		if (arg) {
			instance
				.get(
					`/service_bundles/grouped/?page=${metaData.current_page}`
				)
				.then((res) => {
					setShowEmptyMsg(false)
					const { service_bundle_rules, meta } = res.data
					setServiceBundlesData(service_bundle_rules)
					setMetaData(meta)
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const editSucccessful = (arg) => {
		if (arg) {
			setShowEditSuccessMessage(true)
			setShowEditMessage(true)
		}
	}

	const addSuccessful = (arg) => {
		if (arg) {
			setShowAddSuccessMessage(true)
			setShowNewRecordMessage(true)
			instance
				.get(`/service_bundles/grouped/`)
				.then((res) => {
					if (res.status === 200) {
						setShowEmptyMsg(false);
						const { service_bundle_rules, meta } = res.data
						setServiceBundlesData(service_bundle_rules)
						setMetaData(meta)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const downloadServiceRules = () => {
		instance
			.get("/service_bundles/download", { responseType: 'arraybuffer' })
			.then((res) => {
				if (res.status === 200) {
					setShowMessage(true)
					setDownloadStarted(true)
				}
				const url = window.URL.createObjectURL(new Blob([res.data]))
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", "ServiceBundleUploadTemplate.csv")
				document.body.appendChild(link)
				link.click()
				link.parentNode.removeChild(link)
				setShowDropdown(false)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const downloadReferenceExcel = () => {
		instance
			.get("/service_codes/download", { responseType: 'arraybuffer' })
			.then((res) => {
				if (res.status === 200) {
					setShowMessage(true)
					setDownloadStarted(true)
				}
				const url = window.URL.createObjectURL(new Blob([res.data]))
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", "ReferenceSheet.xls")
				document.body.appendChild(link)
				link.click()
				link.parentNode.removeChild(link)
				setShowDropdown(false)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleDropdown = (e) => {
		e.preventDefault()
		setShowDropdown(!showDropdown)
	}

	return (
		<div className="service-bundles-container">
			{showMessage && showDeleteMessage ? (
				<div className={"message"}>
					<p>Service bundle deleted</p>
				</div>
			) : null}

			{showNewRecordMessage && showAddSuccessMessage ? (
				<div className={"message"}>
					<p>Service bundle Added</p>
				</div>
			) : null}

			{showEditMessage && showEditSuccessMessage ? (
				<div className={"message"}>
					<p>Edited successfully</p>
				</div>
			) : null}

			{showMessage && downloadStarted && (
				<div className={"message"}>Download Started...</div>
			)}

			<div className="service-bundles-list">
				<div className="listing-header">
					<div>
						<button className="btn-regular" onClick={showAddAssociationPopup}>
							Add Association
						</button>
					</div>
					<div className="menu-outer" ref={node}>
						<button
							className="btn-regular btn-dd"
							onClick={(e) => handleDropdown(e)}
						>
							Download Template <i className="fas fa-sort-down"></i>
						</button>

						{showDropdown && (
							<div className="dd-container">
								<ul>
									<li onClick={downloadServiceRules}>CSV Template</li>
									<li onClick={downloadReferenceExcel}>Reference Sheet</li>
								</ul>
							</div>
						)}
					</div>
					{equipmentTypes &&
					equipmentTypes.length &&
					Object.keys(oems).length ? (
						<div>
							<ImportServiceBundles
								reloadRulesIndex={reloadRulesIndex}
								userInfo={userInfo}
								equipmentTypes={equipmentTypes}
								oems={oems}
							/>
						</div>
					) : null}
					<div>
						<input
							type="text"
							className="text-box"
							placeholder="Type and search"
							onChange={(e) => handleSearchInput(e)}
							onKeyPress={handleKeyPress}
						/>
						<button className="btn-search" onClick={handleSearchSubmit}>
							Search
						</button>
					</div>
				</div>
				<Table>
					<TableHeader headerData={ServiceBundlesLabels} />
					{showLoaderForListing && (
						<div className="preloader">
							<Loader type="Puff" color="#00BFFF" height={50} width={50} />
						</div>
					)}
					<TableBody
						headerData={ServiceBundlesLabels}
						rowData={ServiceBundlesData || []}
						onAddViewAssociateParts={{
							onAddViewAssociateParts: onAddViewAssociateParts,
						}}
						onRemoveClick={{ onRemoveClick: onRemoveClick }}
						showEmptyMsg={showEmptyMsg}
					/>
				</Table>
				<div className="pagination-container">
					{total_count !== undefined && total_count > 10 ? (
						<>
							<div className="col-4 sec-1">
								Page: {current_page} / {total_pages}
							</div>
							<div className="col-4 sec-2">
								<Pagination
									totalRecords={total_count}
									pageLimit={per_page}
									pageNeighbours={1}
									onPageChanged={onPageChanged}
									totalPages={Math.ceil(total_count / 10)}
									currentPage={current_page}
								/>
							</div>
							<div className="col-4 sec-3">Total Rows: {total_count}</div>
						</>
					) : null}
				</div>
				{isOpen && (
					<PopupServiceBundle
						content={
							<>
								<AddAssociates
									closePopup={closePopup}
									addSuccessful={addSuccessful}
								/>
							</>
						}
						handleClose={togglePopup}
					/>
				)}

				{isEditPopupOpen && (
					<PopupServiceBundle
						content={
							<>
								<EditServiceBundle
									selectedEditData={selectedEditData}
									closePopup={closePopup}
									editSucccessful={editSucccessful}
								/>
							</>
						}
						handleClose={toggleEditPopup}
					/>
				)}

				{isDeletePopupOpen && (
					<Popup
						content={
							<>
								<div className="popup-content-container">
									<div className="popup-title">Delete confirmation</div>
									<div className="popup-body">
										<h3>Are you sure?</h3>
										<p>This action cannot be undone!</p>
									</div>
									<div className="popup-footer">
										<p className="action-buttons">
											<span>
												<button
													className="btn-regular btn-grey"
													onClick={toggleRemovePopup}
												>
													Cancel
												</button>
											</span>
											<span>
												<button
													className="btn-regular btn-blue"
													onClick={handleRemovePart}
												>
													Confirm
												</button>
											</span>
										</p>
									</div>
								</div>
							</>
						}
						handleClose={toggleRemovePopup}
					/>
				)}
			</div>
		</div>
	)
}

export default ServiceBundles

