import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const FetchVMRSSystems = (props) => {
  const [propsValue, setPropsValue] = useState(null)
  const queryParams = new URLSearchParams(window.location.search);
  const accessToken = queryParams.get("userAuthToken");

  useEffect(() => {
    setPropsValue(props.value)
  },[props.value])

  const loadOptions = async (searchQuery, _, { page }) => {

    const response = await fetch(
      `/api/v1/service_codes/vmrs/systems?search=${searchQuery}&page=${page}`, {
      method: 'GET',
      headers: {
        'Access-Token': accessToken,
      },
    }
    );
    const responseJSON = await response.json();

    let options = responseJSON.vmrs_services.map((option) => ({
      label: `${option.system_description}`,
      value: `${option.code}`,
      data: option,
    }))

    return {
      options: options,
      hasMore: responseJSON.vmrs_services.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: props.showError ? '#ff0000' : '#cccccc',
    }),
  };

  return (
    <>
      <AsyncPaginate
        value={propsValue}
        loadOptions={loadOptions}
        onChange={onChange}
        isSearchable={true}
        isClearable={true}
        placeholder="Please select"
        className='service_dropdown async-dd'
        styles={customStyles}
        debounceTimeout={500}
        additional={{
          page: 1,
        }}
      />
    </>
  );
};

FetchVMRSSystems.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default FetchVMRSSystems;
