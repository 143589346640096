import {
  FETCH_PARTS_REQUEST,
  FETCH_PARTS_SUCCESS,
  FETCH_PARTS_FAILURE,
} from '../constants/loadParts';

const initialState = {
  loading: false,
  parts: [],
  error: ''
}

const partsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_PARTS_SUCCESS:
      return {
        ...state,
        parts: action.payload,
        loading: false
      }
    case FETCH_PARTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    default: return state
  }
}

export default partsReducer;
