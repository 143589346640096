const ServiceBundlesLabels = [
	{
		name: "service_type",
		label: "Service Type",
		className: "service_type",
	},
	{
		name: "equipment_type",
		label: "Equipment Type",
		className: "equipment_type",
		isCurrency: true,
		isVline: true,
	},
	{
		name: "oem",
		label: "OEM",
		className: "oem",
		isCurrency: true,
		isVline: true,
	},
	{
		name: "specification",
		label: "Specification",
		className: "tire_size",
		isCurrency: true,
		isVline: true,
	},
	{
		name: "add_associations",
		label: "Edit / View Associations",
		className: "btn-add",
		addAssociatesClickFunction: "onAddViewAssociateParts",
		isVline: true,
	},
	// {
	//   name: 'edit',
	//   label: '',
	//   isBtn: true,
	//   className: 'icon'
	// },
	// {
	//   name: 'save',
	//   label: '',
	//   saveClickFunction: 'onSaveClick',
	//   showIcon: true,
	//   isBtn: true,
	//   className: 'icon'
	// },
	{
		name: "delete",
		label: "",
		removeClickFunction: "onRemoveClick",
		isBtn: true,
		className: "icon",
	},
]

export default ServiceBundlesLabels;
