import React, { useState, useEffect } from "react"
import { instance, getEquipment } from "../core/api"
import Select from "react-select"
import * as Yup from "yup"
import FetchSystems from "./FetchSystems"
import FetchSubSystems from "./FetchSubSystems"
import FetchServiceList from "./FetchServiceList"
import FetchVMRSSystems from "./FetchVMRSSystems"
import FetchAssemblyList from "./FetchAssemblyList"
import FetchComponentList from "./FetchComponentList"
import FetchVMRSTask from "./FetchVMRSTask"
import FetchParts from "./FetchParts"
import { omit } from "lodash"
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik"

const AddAssociates = (props) => {
	const [selectedServiceType, setSelectedServiceType] = useState({
		label: "AAR",
		value: "AAR"
	})
	const [service_types, setServiceTypes] = useState({})
	const [equipmentTypes, setEquipmentTypes] = useState([])
	const [oems, setOems] = useState({})
	const [selectedOemOption, setSelectedOEMOption] = useState(null)
	const [formValue, setFormValue] = useState(null)
	const [specificationValue, setSpecificationValue] = useState("")
	const [currentAARSystem, setCurrentAARSystem] = useState(null)
	const [currentAARSubSystem, setCurrentAARSubSystem] = useState(null)
	const [currentAARServiceList, setCurrentAARServiceList] = useState(null)
	const [currentVMRSSystem, setCurrentVMRSSystem] = useState(null)
	const [currentVMRSAssembly, setCurrentVMRSAssembly] = useState(null)
	const [currentVMRSComponent, setCurrentVMRSComponent] = useState(null)
	const [aarTask, setAARTask] = useState("")
	const [vmrsTask, setvmrsTask] = useState("")
	const [defaultParts, setDefaultParts] = useState(null)
	const [addonParts, setAddonParts] = useState(null)
	const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState(null)
	const [partsErrorMsg, setPartsErrorMsg] = useState([]);
	const [showPartsErrorMsg, setShowPartsErrorMsg] = useState(false)
	useEffect(() => {
		instance
			.get("/service_types")
			.then((res) => {
				let service_types = res.data
				if (service_types !== undefined) {
					setServiceTypes(service_types)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		instance
			.get("/oems")
			.then((res) => {
				if (res !== undefined) {
					setOems(res.data)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		if (
			selectedServiceType &&
			selectedServiceType.value === "AAR" &&
			currentAARSystem !== null
		) {
			getEquipment
				.get("/equipment_types", {
					params: {
						service_type: selectedServiceType.value.toLowerCase(),
						service_component_id:
							currentAARSystem !== null ? parseInt(currentAARSystem.value) : "",
						sub_service_component_id:
							currentAARSubSystem !== null
								? parseInt(currentAARSubSystem.value)
								: "",
						service_id:
							currentAARServiceList !== null
								? parseInt(currentAARServiceList.value)
								: "",
					},
				})
				.then((res) => {
					if (res.status === 200) {
						let data = res.data.equipment_types
						setEquipmentTypes(data)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}

		if (
			selectedServiceType &&
			selectedServiceType.value === "VMRS" &&
			currentVMRSSystem !== null
		) {
			getEquipment
				.get("/equipment_types", {
					params: {
						service_type: selectedServiceType.value.toLowerCase(),
						vmrs_system_code:
							currentVMRSSystem !== null ? currentVMRSSystem.value : "",
						vmrs_assembly_code:
							currentVMRSAssembly !== null ? currentVMRSAssembly.value : "",
						vmrs_component_code:
							currentVMRSComponent !== null ? currentVMRSComponent.value : "",
					},
				})
				.then((res) => {
					if (res.status === 200) {
						let data = res.data.equipment_types
						setEquipmentTypes(data)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}, [
		props,
		currentAARSystem,
		currentAARSubSystem,
		currentAARServiceList,
		currentVMRSSystem,
		currentVMRSAssembly,
		currentVMRSComponent,
		specificationValue,
	])

	const getNames = {
		equipment_name: null,
		task: null,
		default_parts: null,
		addon_parts: null,
	}

	const partsAssociates = {
		service_type: selectedServiceType,
		oem: "",
		tire_size: "",
		aar_system: currentAARSystem,
		aar_sub_system: currentAARSubSystem,
		aar_service: currentAARServiceList,
		vmrs_system: currentVMRSSystem,
		vmrs_assembly: currentVMRSAssembly,
		vmrs_component: currentVMRSComponent,
		associates: [getNames],
	}

	const options = [
		{ value: service_types.aar, label: service_types.aar },
		{ value: service_types.vmrs, label: service_types.vmrs },
	]

	let OEM_options = []
	for (const [key, value] of Object.entries(oems)) {
		OEM_options.push({
			value: key,
			label: value,
		})
	}

	let equipmentTypesOptions = []
	if (equipmentTypes !== null) {
		equipmentTypes.map((equipment) => {
			equipmentTypesOptions.push({
				value: equipment.code,
				label: equipment.description,
			})
		})
	}

	let aarServiceType = []
	if (equipmentTypes !== null) {
		equipmentTypes.map((equipment) => {
			aarServiceType.push({
				value: equipment.code,
				label: equipment.description,
			})
		})
	}

	let aarTaskOption = []
	if (aarTask !== "") {
		aarTaskOption.push({
			label: aarTask.code + " - " + aarTask.description,
			value: aarTask.id,
		})
	}

	const getValues = (formValue) => {
		setFormValue(formValue)
		let payload = []

		const associatedparts = (associates) => {
			let default_parts = []
			let addon_parts = []
			associates.default_parts !== null &&
				associates.default_parts.length > 0 &&
				associates.default_parts.map((default_part) => {
					default_parts.push({
						part_id: default_part.value,
						add_on: false,
					})
				})

			associates.addon_parts !== null &&
				associates.addon_parts.length > 0 &&
				associates.addon_parts.map((addon_part) => {
					addon_parts.push({
						part_id: addon_part.value,
						add_on: true,
					})
				})

			let combine_parts = default_parts.concat(addon_parts)
			return combine_parts
		}

		if (formValue.associates) {
			let serviceType =
				selectedServiceType && selectedServiceType.value.toLowerCase()
			formValue.associates.map((associate, i) => {
				payload.push({
					service_type: serviceType !== "" ? serviceType : null,
					equipment_type: formValue.associates[i].equipment_name.value,
					specification: specificationValue !== "" ? specificationValue : null,
					oem: formValue.oem !== "" ? formValue.oem.value : null,
					aar_system: {
						service_type: serviceType === "aar" ? "aar" : null,
						name:
							formValue.aar_system !== null
								? formValue.aar_system.label
								: currentAARSystem && currentAARSystem.label,
						level: serviceType === "aar" ? "system" : null,
						code: null,
						source_system_id:
							formValue.aar_system !== null
								? formValue.aar_system.value
								: currentAARSystem && currentAARSystem.value,
					},
					aar_subsystem: {
						service_type: serviceType === "aar" ? "aar" : null,
						name:
							formValue.aar_sub_system !== null
								? formValue.aar_sub_system.label
								: currentAARSubSystem && currentAARSubSystem.label,
						level: serviceType === "aar" ? "subsystem" : null,
						code: null,
						source_system_id:
							formValue.aar_sub_system !== null
								? formValue.aar_sub_system.value
								: currentAARSubSystem && currentAARSubSystem.value,
					},
					aar_service: {
						service_type: serviceType === "aar" ? "aar" : null,
						name:
							formValue.aar_service !== null
								? formValue.aar_service.label
								: null,
						level: serviceType === "aar" ? "service" : null,
						code: null,
						source_system_id:
							formValue.aar_service !== null
								? formValue.aar_service.value
								: null,
					},
					aar_job: {
						service_type: serviceType === "aar" ? "aar" : null,
						name:
							aarTask.description !== undefined ? aarTask.description : null,
						level: serviceType === "aar" ? "aarJob" : null,
						code: aarTask.code !== undefined ? aarTask.code : null,
						source_system_id: aarTask.id !== undefined ? aarTask.id : null,
					},
					vmrs_system: {
						service_type: serviceType === "vmrs" ? "vmrs" : null,
						name:
							formValue.vmrs_system !== null
								? formValue.vmrs_system.label
								: null,
						level: serviceType === "vmrs" ? "system" : null,
						code: 
						  formValue.vmrs_system !== null
								? formValue.vmrs_system.value
								: null,
						source_system_id: null,
					},
					vmrs_assembly: {
						service_type: serviceType === "vmrs" ? "vmrs" : null,
						name:
							formValue.vmrs_assembly !== null
								? formValue.vmrs_assembly.label
								: null,
						level: serviceType === "vmrs" ? "assembly" : null,
						code: 
						  formValue.vmrs_assembly !== null
						    ? formValue.vmrs_assembly.value
						    : null,
						source_system_id: null,
					},
					vmrs_component: {
						service_type: serviceType === "vmrs" ? "vmrs" : null,
						name:
							formValue.vmrs_component !== null
								? formValue.vmrs_component.label
								: null,
						level: serviceType === "vmrs" ? "component" : null,
						code: 
						  formValue.vmrs_component !== null
								? formValue.vmrs_component.value
								: null,
						source_system_id: null,
					},
					vmrs_work_accomplished: {
						service_type: serviceType === "vmrs" ? "vmrs" : null,
						name:
							serviceType === "vmrs"
								? formValue.associates[i].task.data &&
								  formValue.associates[i].task.data.description
								: null,
						level:
							formValue.associates[i].task !== null ? "workAccomplished" : null,
						code:
							serviceType === "vmrs"
								? formValue.associates[i].task.data &&
								  formValue.associates[i].task.data.code
								: null,
						source_system_id:
							serviceType === "vmrs"
								? formValue.associates[i].task &&
								  formValue.associates[i].task.data.id
								: null,
					},
					service_bundle_parts: associatedparts(formValue.associates[i]),
				})
			})
		}
		
		let errorMsg = []

		payload && payload.map((data, index) => {
			if (data.service_bundle_parts.length === 0) {
				setShowPartsErrorMsg(true)
				errorMsg.push({
					err: `Please choose either Default parts or Addon parts in row ${index + 1}`
				})
			}
		})

		setPartsErrorMsg(errorMsg)

		if (errorMsg.length === 0) {
			instance
				.post("/service_bundles/upsert", {
					service_bundle_rules: payload,
				})
				.then((res) => {
					props.closePopup(true, res.service_bundle_rules)
					props.addSuccessful(true)
				})
				.catch((error) => {
					console.log(error)
					props.closePopup(false)
				})
		}
		
	}

	let aarValidateSchema
	let vmrsValidateSchema

	if (
		currentAARSystem &&
		currentAARSystem.data === undefined &&
		currentAARSubSystem &&
		currentAARSubSystem.data === undefined &&
		currentAARServiceList &&
		currentAARServiceList.data !== undefined
	) {
		aarValidateSchema = Yup.object({
			service_type: Yup.object().required("Required"),
			associates: Yup.array().of(
				Yup.object().shape({
					equipment_name: Yup.object()
						.shape({
							label: Yup.string(),
							value: Yup.string(),
						})
						.nullable()
						.required("Equipment required"),
					task: Yup.object()
						.shape({
							label: Yup.string(),
							value: Yup.string(),
						})
						.nullable()
						.required("Task required"),
					// default_parts: Yup.array().nullable().required("required"),
				})
			),
		})
	} else {
		aarValidateSchema = Yup.object({
			service_type: Yup.object().required("Required"),
			aar_system: Yup.object()
				.shape({
					label: Yup.string(),
					value: Yup.number(),
				})
				.nullable()
				.required("System required"),
			aar_sub_system: Yup.object()
				.shape({
					label: Yup.string(),
					value: Yup.number(),
				})
				.nullable()
				.required("Subsystem required"),
			aar_service: Yup.object()
				.shape({
					label: Yup.string(),
					value: Yup.number(),
				})
				.nullable()
				.required("Service required"),
			associates: Yup.array().of(
				Yup.object().shape({
					equipment_name: Yup.object()
						.shape({
							label: Yup.string(),
							value: Yup.string(),
						})
						.nullable()
						.required("Equipment required"),
					task: Yup.object()
						.shape({
							label: Yup.string(),
							value: Yup.string(),
						})
						.nullable()
						.required("Task required"),
					// default_parts: Yup.array().nullable().required("required"),
				})
			),
		})
	}

	if (currentVMRSComponent && currentVMRSComponent.data !== undefined) {
		vmrsValidateSchema = Yup.object({
			service_type: Yup.object().required("Required"),
			associates: Yup.array().of(
				Yup.object().shape({
					equipment_name: Yup.object()
						.shape({
							label: Yup.string(),
							value: Yup.string(),
						})
						.nullable()
						.required("Equipment required"),
					task: Yup.object()
						.shape({
							label: Yup.string(),
							value: Yup.string(),
						})
						.nullable()
						.required("Task required"),
					// default_parts: Yup.array().nullable().required("required"),
				})
			),
		})
	} else {
		vmrsValidateSchema = Yup.object({
			service_type: Yup.object().required("Required"),
			vmrs_system: Yup.object()
				.shape({
					label: Yup.string(),
					value: Yup.string(),
				})
				.nullable()
				.required("System required"),
			vmrs_assembly: Yup.object()
				.shape({
					label: Yup.string(),
					value: Yup.string(),
				})
				.nullable()
				.required("Subsystem required"),
			associates: Yup.array().of(
				Yup.object().shape({
					equipment_name: Yup.object()
						.shape({
							label: Yup.string(),
							value: Yup.string(),
						})
						.nullable()
						.required("Equipment required"),
					task: Yup.object()
						.shape({
							label: Yup.string(),
							value: Yup.string(),
						})
						.nullable()
						.required("Task required"),
					// default_parts: Yup.array().nullable().required("required"),
				})
			),
		})
	}

	const handleTireSize = (val) => {
		setSpecificationValue(val)
	}

	return (
		<div>
			<Formik
				enableReinitialize={true}
				initialValues={partsAssociates}
				validationSchema={
					selectedServiceType && selectedServiceType.value === "AAR"
						? aarValidateSchema
						: vmrsValidateSchema
				}
				validate={(values) => {
					if (values.associates.length === 0) {
						return { arr: "Required least one associated part" }
					}
				}}
				onSubmit={(values) => {
					getValues(values)
				}}
				render={({
					values,
					touched,
					handleReset,
					errors,
					resetForm,
					handleChange,
					handleBlur,
				}) => {
					return (
						<Form>
							<div className="popup-content-container">
								<div className="popup-title">Associate Parts</div>
								<div className="popup-body">
									<div className="parts-details">
										<div className="input-row">
											<div className="chose-service-type">
												<div className="label">
													Select type: <sup>*</sup>
												</div>
												<div>
													<Field name={"service_type"}>
														{({ field, form, meta }) => (
															<Select
																value={selectedServiceType}
																onChange={(option) => {
																	form.setFieldValue(field.name, option)
																	setSelectedServiceType(option)
																	setCurrentAARSystem(null)
																	setCurrentAARSubSystem(null)
																	setCurrentAARServiceList(null)
																	setCurrentVMRSSystem(null)
																	setCurrentVMRSAssembly(null)
																	setCurrentVMRSComponent(null)
																	setSelectedOEMOption(null)
																	setSpecificationValue("")
																}}
																options={options}
																className="service_dropdown"
																placeholder="Select service type"
															/>
														)}
													</Field>
													<ErrorMessage name={`service_type`}>
														{(msg) => (
															<div
																style={{
																	color: "red",
																	fontSize: "1.2rem",
																}}
															>
																{msg}
															</div>
														)}
													</ErrorMessage>
												</div>
											</div>
										</div>
										{selectedServiceType &&
											selectedServiceType.value === "AAR" && (
												<div className="input-row">
													<div className="flex-grid header">
														<div>
															AAR System <sup>*</sup>
														</div>
														<div>
															AAR Subsystem <sup>*</sup>
														</div>
														<div>
															AAR Service <sup>*</sup>
														</div>
													</div>
													<div className="flex-grid">
														<div className="input-elements async-dd-elements">
															<Field name={"aar_system"}>
																{({ field, form, meta }) => (
																	<FetchSystems
																		value={currentAARSystem}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option)
																			setCurrentAARSystem(option)
																			setCurrentAARSubSystem(null)
																			setCurrentAARServiceList(null)
																		}}
																	/>
																)}
															</Field>
															<ErrorMessage name={`aar_system`}>
																{(msg) => (
																	<div
																		style={{
																			color: "red",
																			fontSize: "1.2rem",
																		}}
																	>
																		{msg}
																	</div>
																)}
															</ErrorMessage>
														</div>
														<div className="input-elements async-dd-elements">
															<Field name={"aar_sub_system"}>
																{({ field, form, meta }) => (
																	<FetchSubSystems
																		value={currentAARSubSystem}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option)
																			setCurrentAARSubSystem(option)
																			setCurrentAARServiceList(null)
																			setCurrentAARSystem({
																				label:
																					option.data.service_component_name,
																				value: option.data.service_component_id,
																			})
																		}}
																		serviceComponentId={
																			currentAARSystem !== null
																				? currentAARSystem.value
																				: null
																		}
																	/>
																)}
															</Field>
															<ErrorMessage name={`aar_sub_system`}>
																{(msg) => (
																	<div
																		style={{
																			color: "red",
																			fontSize: "1.2rem",
																		}}
																	>
																		{msg}
																	</div>
																)}
															</ErrorMessage>
														</div>
														<div className="input-elements async-dd-elements">
															<Field name={"aar_service"}>
																{({ field, form, meta }) => (
																	<FetchServiceList
																		value={currentAARServiceList}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option)
																			setCurrentAARServiceList(option)
																			setAARTask(option.data.jobs[0])
																			setCurrentAARSystem({
																				label:
																					option.data.service_component_name,
																				value: option.data.service_component_id,
																			})
																			setCurrentAARSubSystem({
																				label: option.data.sub_component_name,
																				value: option.data.sub_component_id,
																			})
																		}}
																		currentSystemId={
																			currentAARSystem !== null
																				? currentAARSystem.value
																				: ""
																		}
																		currentSubSystemId={
																			currentAARSubSystem !== null
																				? currentAARSubSystem.value
																				: ""
																		}
																	/>
																)}
															</Field>
															<ErrorMessage name={`aar_service`}>
																{(msg) => (
																	<div
																		style={{
																			color: "red",
																			fontSize: "1.2rem",
																		}}
																	>
																		{msg}
																	</div>
																)}
															</ErrorMessage>
														</div>
													</div>
												</div>
											)}
										{selectedServiceType &&
											selectedServiceType.value === "VMRS" && (
												<div className="input-row">
													<div className="flex-grid header">
														<div>
															VMRS System <sup>*</sup>
														</div>
														<div>
															VMRS Assembly <sup>*</sup>
														</div>
														<div>VMRS Component</div>
													</div>
													<div className="flex-grid">
														<div className="input-elements async-dd-elements">
															<Field name={"vmrs_system"}>
																{({ field, form, meta }) => (
																	<FetchVMRSSystems
																		value={currentVMRSSystem}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option),
																				setCurrentVMRSSystem(option)
																			setCurrentVMRSAssembly(null)
																			setCurrentVMRSComponent(null)
																		}}
																		className="service_dropdown"
																	/>
																)}
															</Field>
															<ErrorMessage name={`vmrs_system`}>
																{(msg) => (
																	<div
																		style={{
																			color: "red",
																			fontSize: "1.2rem",
																		}}
																	>
																		{msg}
																	</div>
																)}
															</ErrorMessage>
														</div>
														<div className="input-elements async-dd-elements">
															<Field name={"vmrs_assembly"}>
																{({ field, form, meta }) => (
																	<FetchAssemblyList
																		value={currentVMRSAssembly}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option)
																			setCurrentVMRSAssembly(option)
																			setCurrentVMRSComponent(null)
																			setCurrentVMRSSystem({
																				label: option.data.system_description,
																				value: option.data.system_code,
																			})
																		}}
																		currentVMRSSystemCode={
																			currentVMRSSystem !== null
																				? currentVMRSSystem.value
																				: null
																		}
																		className="service_dropdown"
																	/>
																)}
															</Field>
															<ErrorMessage name={`vmrs_assembly`}>
																{(msg) => (
																	<div
																		style={{
																			color: "red",
																			fontSize: "1.2rem",
																		}}
																	>
																		{msg}
																	</div>
																)}
															</ErrorMessage>
														</div>
														<div className="input-elements async-dd-elements">
															<Field name={"vmrs_component"}>
																{({ field, form, meta }) => (
																	<FetchComponentList
																		value={currentVMRSComponent}
																		onChange={(option) => {
																			form.setFieldValue(field.name, option)
																			setCurrentVMRSComponent(option)
																			setCurrentVMRSSystem({
																				label: option.data.system_description,
																				value: option.data.system_code,
																			})
																			setCurrentVMRSAssembly({
																				label: option.data.assembly_description,
																				value: option.data.assembly_code,
																			})
																		}}
																		currentVMRSSystemCode={
																			currentVMRSSystem !== null
																				? currentVMRSSystem.value
																				: null
																		}
																		currentVMRSAssemblyCode={
																			currentVMRSAssembly !== null
																				? currentVMRSAssembly.value
																				: null
																		}
																		className="service_dropdown"
																	/>
																)}
															</Field>
														</div>
													</div>
												</div>
											)}
										<div className="input-row">
											<div className="flex-grid header">
												<div className="input-elements">
													OEM{" "}
													<span>
														<i>(Optional)</i>
													</span>
												</div>
												<div className="input-elements">
													Specification{" "}
													<span>
														<i>(Optional)</i>
													</span>
												</div>
											</div>
											<div className="flex-grid">
												<div className="input-elements">
													<Field name={"oem"}>
														{({ field, form, meta }) => (
															<Select
																options={OEM_options}
																value={selectedOemOption}
																onChange={(option) => {
																	form.setFieldValue(field.name, option)
																	setSelectedOEMOption(option)
																}}
																isClearable={true}
																className="service_dropdown"
																placeholder="Select OEM"
															/>
														)}
													</Field>
												</div>
												<div className="input-elements">
													<Field name={"tire_size"}>
														{({ field, form, meta }) => (
															<input
																type="text"
																className="textbox"
																placeholder="Specification"
																onChange={(val) => {
																	form.setFieldValue(field.name, val)
																	handleTireSize(val.target.value)
																}}
																value={specificationValue}
															/>
														)}
													</Field>
												</div>
											</div>
										</div>
										<div className="input-row">
											<FieldArray name="associates">
												{({ insert, remove, push }) => (
													<>
														{selectedServiceType.value === "VMRS" ?
															<div className="cta-add">
																<button
																	type="button"
																	className="btn-regular btn-blue"
																	onClick={() => push(getNames)}
																>
																	Add Parts
																</button>
															</div> : null}
														<div className="flex-grid header add-parts-header">
															<div>
																Equipments <sup>*</sup>
															</div>
															{(selectedServiceType &&
																selectedServiceType.value === "AAR") ||
															(selectedServiceType &&
																selectedServiceType.value === "VMRS") ? (
																<div>
																	Task <sup>*</sup>
																</div>
															) : (
																""
															)}
															<div>Default Parts</div>
															<div>AddOn Parts</div>
														</div>
														<ul className="errorMessageList">
															{showPartsErrorMsg &&
																partsErrorMsg &&
																partsErrorMsg.map((error, index) => {
																	return (
																		<li key={index} className="hint">
																			{error.err}
																		</li>
																	)
																})}
														</ul>
														{selectedServiceType &&
															selectedServiceType.value === "VMRS" &&
															values.associates.length > 0 &&
															values.associates.map((associate, index) => (
																<>
																	<div className="parts-addition">
																		<div className="flex-grid" key={index}>
																			<div className="input-elements inputs-dd">
																				<Field
																					name={`associates.${index}.equipment_name`}
																				>
																					{({ field, form, meta }) => (
																						<Select
																							options={equipmentTypesOptions}
																							onChange={(option) => {
																								form.setFieldValue(
																									field.name,
																									option
																								)
																								setSelectedEquipmentTypes(
																									option
																								)
																							}}
																							isClearable={true}
																							className="service_dropdown"
																							placeholder="Select Equipment"
																						/>
																					)}
																				</Field>
																				<ErrorMessage
																					name={`associates.${index}.equipment_name`}
																				>
																					{(msg) => (
																						<div
																							style={{
																								color: "red",
																								fontSize: "1.2rem",
																							}}
																						>
																							{msg}
																						</div>
																					)}
																				</ErrorMessage>
																			</div>

																			{selectedServiceType &&
																				selectedServiceType.value === "AAR" && (
																					<div className="input-elements inputs-dd">
																						<Field
																							name={`associates.${index}.task`}
																						>
																							{({ field, form, meta }) => (
																								<Select
																									options={aarTaskOption}
																									value={values.name}
																									onChange={(option) => {
																										form.setFieldValue(
																											field.name,
																											option
																										)
																									}}
																									isClearable={true}
																									placeholder="Select task"
																									className="service_dropdown"
																								/>
																							)}
																						</Field>
																						<ErrorMessage
																							name={`associates.${index}.task`}
																						>
																							{(msg) => (
																								<div
																									style={{
																										color: "red",
																										fontSize: "1.2rem",
																									}}
																								>
																									{msg}
																								</div>
																							)}
																						</ErrorMessage>
																					</div>
																				)}
																			{selectedServiceType &&
																				selectedServiceType.value ===
																					"VMRS" && (
																					<div className="input-elements inputs-dd">
																						<Field
																							name={`associates.${index}.task`}
																						>
																							{({ field, form, meta }) => (
																								<>
																									<FetchVMRSTask
																										defaultValue={field.name}
																										onChange={(option) => {
																											form.setFieldValue(
																												field.name,
																												option
																											)
																											setAddonParts(option)
																										}}
																										className="service_dropdown"
																										vmrsAssemblyCode={
																											currentVMRSAssembly !==
																											null
																												? currentVMRSAssembly &&
																												  currentVMRSAssembly.value
																												: null
																										}
																										vmrsComponentCode={
																											currentVMRSComponent !==
																											null
																												? currentVMRSComponent
																														.data.code
																												: null
																										}
																									/>
																								</>
																							)}
																						</Field>
																						<ErrorMessage
																							name={`associates.${index}.task`}
																						>
																							{(msg) => (
																								<div
																									style={{
																										color: "red",
																										fontSize: "1.2rem",
																									}}
																								>
																									{msg}
																								</div>
																							)}
																						</ErrorMessage>
																					</div>
																				)}

																			<div className="input-elements inputs-dd">
																				<Field
																					name={`associates.${index}.default_parts`}
																				>
																					{({ field, form, meta }) => (
																						<>
																							<FetchParts
																								onChange={(system) => {
																									form.setFieldValue(
																										field.name,
																										system
																									)
																									setDefaultParts(system)
																								}}
																							/>
																						</>
																					)}
																				</Field>
																				<ErrorMessage
																					name={`associates.${index}.default_parts`}
																				>
																					{(msg) => (
																						<div
																							style={{
																								color: "red",
																								fontSize: "1.2rem",
																							}}
																						>
																							{msg}
																						</div>
																					)}
																				</ErrorMessage>
																			</div>

																			<div className="input-elements inputs-dd">
																				<Field
																					name={`associates.${index}.addon_parts`}
																				>
																					{({ field, form, meta }) => (
																						<>
																							<FetchParts
																								onChange={(system) => {
																									form.setFieldValue(
																										field.name,
																										system
																									)
																									setAddonParts(system)
																								}}
																							/>
																						</>
																					)}
																				</Field>
																			</div>
																			<div className="close-cta">
																				<button
																					type="button"
																					className="btn-regular btn-red"
																					onClick={() => remove(index)}
																				>
																					Delete
																				</button>
																			</div>
																		</div>
																	</div>
																</>
															))}

														{selectedServiceType &&
															selectedServiceType.value === "AAR" &&
															values.associates.length > 0 &&
															values.associates.map((associate, index) => (
																<>
																	<div className="parts-addition">
																		<div className="flex-grid" key={index}>
																			<div className="input-elements inputs-dd">
																				<Field
																					name={`associates.${index}.equipment_name`}
																				>
																					{({ field, form, meta }) => (
																						<Select
																							options={equipmentTypesOptions}
																							onChange={(option) => {
																								form.setFieldValue(
																									field.name,
																									option
																								)
																								setSelectedEquipmentTypes(
																									option
																								)
																							}}
																							isClearable={true}
																							className="service_dropdown"
																							placeholder="Select Equipment"
																						/>
																					)}
																				</Field>
																				<ErrorMessage
																					name={`associates.${index}.equipment_name`}
																				>
																					{(msg) => (
																						<div
																							style={{
																								color: "red",
																								fontSize: "1.2rem",
																							}}
																						>
																							{msg}
																						</div>
																					)}
																				</ErrorMessage>
																			</div>

																			{selectedServiceType &&
																				selectedServiceType.value === "AAR" && (
																					<div className="input-elements inputs-dd">
																						<Field
																							name={`associates.${index}.task`}
																						>
																							{({ field, form, meta }) => (
																								<Select
																									options={aarTaskOption}
																									value={values.name}
																									onChange={(option) => {
																										form.setFieldValue(
																											field.name,
																											option
																										)
																									}}
																									isClearable={true}
																									placeholder="Select task"
																									className="service_dropdown"
																								/>
																							)}
																						</Field>
																						<ErrorMessage
																							name={`associates.${index}.task`}
																						>
																							{(msg) => (
																								<div
																									style={{
																										color: "red",
																										fontSize: "1.2rem",
																									}}
																								>
																									{msg}
																								</div>
																							)}
																						</ErrorMessage>
																					</div>
																				)}
																			{selectedServiceType &&
																				selectedServiceType.value ===
																					"VMRS" && (
																					<div className="input-elements inputs-dd">
																						<Field
																							name={`associates.${index}.task`}
																						>
																							{({ field, form, meta }) => (
																								<>
																									<FetchVMRSTask
																										defaultValue={field.name}
																										onChange={(option) => {
																											form.setFieldValue(
																												field.name,
																												option
																											)
																											setAddonParts(option)
																										}}
																										className="service_dropdown"
																										vmrsAssemblyCode={
																											currentVMRSAssembly !==
																											null
																												? currentVMRSAssembly &&
																												  currentVMRSAssembly.value
																												: null
																										}
																										vmrsComponentCode={
																											currentVMRSComponent !==
																											null
																												? currentVMRSComponent
																														.data.code
																												: null
																										}
																									/>
																								</>
																							)}
																						</Field>
																						<ErrorMessage
																							name={`associates.${index}.task`}
																						>
																							{(msg) => (
																								<div
																									style={{
																										color: "red",
																										fontSize: "1.2rem",
																									}}
																								>
																									{msg}
																								</div>
																							)}
																						</ErrorMessage>
																					</div>
																				)}

																			<div className="input-elements inputs-dd">
																				<Field
																					name={`associates.${index}.default_parts`}
																				>
																					{({ field, form, meta }) => (
																						<>
																							<FetchParts
																								onChange={(system) => {
																									form.setFieldValue(
																										field.name,
																										system
																									)
																									setDefaultParts(system)
																								}}
																							/>
																						</>
																					)}
																				</Field>
																				<ErrorMessage
																					name={`associates.${index}.default_parts`}
																				>
																					{(msg) => (
																						<div
																							style={{
																								color: "red",
																								fontSize: "1.2rem",
																							}}
																						>
																							{msg}
																						</div>
																					)}
																				</ErrorMessage>
																			</div>

																			<div className="input-elements inputs-dd">
																				<Field
																					name={`associates.${index}.addon_parts`}
																				>
																					{({ field, form, meta }) => (
																						<>
																							<FetchParts
																								onChange={(system) => {
																									form.setFieldValue(
																										field.name,
																										system
																									)
																									setAddonParts(system)
																								}}
																							/>
																						</>
																					)}
																				</Field>
																			</div>
																			<div className="close-cta">
																				<button
																					type="button"
																					className="btn-regular btn-red"
																					onClick={() => remove(index)}
																				>
																					Delete
																				</button>
																			</div>
																		</div>
																	</div>
																</>
															))}
													</>
												)}
											</FieldArray>
											{errors.arr && <div className="hint">{errors.arr}</div>}
										</div>
									</div>
								</div>
							</div>
							<div className="btn-save">
								<button type="submit" className="btn-regular btn-blue">
									Save
								</button>
							</div>
						</Form>
					)
				}}
			/>
		</div>
	)
}

export default AddAssociates
