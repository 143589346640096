import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import Table, { TableHeader, TableBody } from "./CRUDListLabor"
import laborRatesLabels from "../Labor/laborRatesLabel"
import ImportLaborRates from "./ImportLaborRates"
import { instance, aar } from "../core/api"
import Pagination from "../core/Pagination"
import { omit } from "lodash"
import Popup from "../core/Popup"
import { NavItem } from "react-bootstrap"
import Loader from "react-loader-spinner"

const LaborRates = (props) => {
	const { userInfo } = props
	const [search, setSearch] = useState("")
	const [laborData, setLaborData] = useState([])
	const [service_types, set_service_types] = useState({})
	const [addNewRow, setAddNewRow] = useState(false)
	const [metaData, setMetaData] = useState([])
	const [showMessage, setShowMessage] = useState(false)
	const [currentData, setCurrentData] = useState([])
	const [currentPage, setCurrentPage] = useState(null)
	const [totalPages, setTotalPages] = useState(null)
	const [noResponse, setNoResponse] = useState(false)
	const [updateFailureMsg, setUpdateFailureMsg] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [selectedRecord, setSelectedRecord] = useState("")
	const [aar_system_list, set_aar_system_list] = useState([])
	const [aar_system_list_new, set_aar_system_list_new] = useState([])
	const [aar_sub_system_list, set_aar_sub_system_list] = useState([])
	const [services_list, set_services_list] = useState([])
	const [enableButton, setEnableButton] = useState(false)
	const [addRecord, setAddRecord] = useState(false)
	const [deleteSuccessMsg, setDeleteSuccessMsg] = useState(false)
	const [showDropdown, setShowDropdown] = useState(false)
	const [successAddRecord, setSuccessAddRecord] = useState(false)
	const [successDeleteRecord, setSuccessDeleteRecord] = useState(false)
	const [successUpdatedRecord, setSuccessUpdatedRecord] = useState(false)
	const [showErrors, setShowErrors] = useState([]);
	const [showErrorPopup, setShowErrorPopup] = useState(false)
	const [downloadStarted, setDownloadStarted] = useState(false)
	const [showLoaderForListing, setShowLoaderForListing] = useState(true)
	const [showEmptyMsg, setShowEmptyMsg] = useState(false)

	let current_page = metaData.current_page
	let next_page = metaData.next_page
	let per_page = metaData.per_page
	let total_count = metaData.total_count
	let total_pages = metaData.total_pages

	const node = useRef()

	useEffect(() => {
		document.addEventListener("mousedown", handleClick)
		instance
			.get(`/labor/?page=${metaData.current_page}&search=${search}`)
			.then((res) => {
				setShowLoaderForListing(true)
				if (res.status === 200 && res.data.labor_rates.length > 0) {
					setShowLoaderForListing(false)
					let { labor_rates, meta } = res.data
					setLaborData(labor_rates)
					setMetaData(meta)
					setShowEmptyMsg(false)
				} else {
					setShowLoaderForListing(false)
					setShowEmptyMsg(true)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		instance
			.get("/labor/standard_services")
			.then((res) => {
				if (res !== undefined) {
					set_service_types(res.data)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		aar
			.get("/systems/")
			.then((res) => {
				if (res !== undefined) {
					let { service_components } = res.data
					set_aar_system_list(service_components)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		aar
			.get("/systems/")
			.then((res) => {
				if (res !== undefined) {
					let data = res.data
					set_aar_system_list_new(data)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		const timer = setTimeout(() => {
			setSuccessAddRecord(false)
			setSuccessDeleteRecord(false)
			setSuccessUpdatedRecord(false)
			setDownloadStarted(false)
		}, 1000)

		// To clear or cancel a timer, you call the clearTimeout(); method,
		// passing in the timer object that you created into clearTimeout().

		//return () => clearTimeout(timer);
		return () => {
			document.removeEventListener("mousedown", handleClick)
			clearTimeout(timer)
		}
	}, [
		deleteSuccessMsg,
		addRecord,
		successAddRecord,
		successDeleteRecord,
		successUpdatedRecord,
		showMessage,
		downloadStarted
	])

	const updateLaborParts = (id, payload) => {
		instance
			.put(`labor/${id}`, {
				labor: {
					regular_hours_rate: payload.regular_hours_rate,
					after_hours_rate: payload.after_hours_rate,
					holiday_rate: payload.holiday_rate,
					service_type: payload.service_type,
					aar_system: {
						service_type:
							payload.aar_system !== undefined
								? payload.aar_system.service_type
								: null,
						name:
							payload.aar_system !== undefined ? payload.aar_system.name : null,
						level:
							payload.aar_system !== undefined
								? payload.aar_system.level
								: null,
						code:
							payload.aar_system !== undefined ? payload.aar_system.code : null,
						source_system_id:
							payload.aar_system !== undefined
								? payload.aar_system.source_system_id
								: null,
					},
					aar_subsystem: {
						service_type:
							payload.aar_subsystem !== undefined
								? payload.aar_subsystem.service_type
								: null,
						name:
							payload.aar_subsystem !== undefined
								? payload.aar_subsystem.name
								: null,
						level:
							payload.aar_subsystem !== undefined
								? payload.aar_subsystem.level
								: null,
						code:
							payload.aar_subsystem !== undefined
								? payload.aar_subsystem.code
								: null,
						source_system_id:
							payload.aar_subsystem !== undefined
								? payload.aar_subsystem.source_system_id
								: null,
					},
					aar_service: {
						service_type:
							payload.aar_service !== undefined
								? payload.aar_service.service_type
								: null,
						name:
							payload.aar_service !== undefined
								? payload.aar_service.name
								: null,
						level:
							payload.aar_service !== undefined
								? payload.aar_service.level
								: null,
						code:
							payload.aar_service !== undefined
								? payload.aar_service.code
								: null,
						source_system_id:
							payload.aar_service !== undefined
								? payload.aar_service.source_system_id
								: null,
					},

					vmrs_system: {
						service_type:
							payload.vmrs_system !== undefined
								? payload.vmrs_system.service_type
								: null,
						name:
							payload.vmrs_system !== undefined
								? payload.vmrs_system.name
								: null,
						level:
							payload.vmrs_system !== undefined
								? payload.vmrs_system.level
								: null,
						code:
							payload.vmrs_system !== undefined
								? payload.vmrs_system.code
								: null,
						source_system_id:
							payload.vmrs_system !== undefined
								? payload.vmrs_system.source_system_id
								: null,
					},
					vmrs_assembly: {
						service_type:
							payload.vmrs_assembly !== undefined
								? payload.vmrs_assembly.service_type
								: null,
						name:
							payload.vmrs_assembly !== undefined
								? payload.vmrs_assembly.name
								: null,
						level:
							payload.vmrs_assembly !== undefined
								? payload.vmrs_assembly.level
								: null,
						code:
							payload.vmrs_assembly !== undefined
								? payload.vmrs_assembly.code
								: null,
						source_system_id:
							payload.vmrs_assembly !== undefined
								? payload.vmrs_assembly.source_system_id
								: null,
					},
					vmrs_component: {
						service_type:
							payload.vmrs_component !== undefined
								? payload.vmrs_component.service_type
								: null,
						name:
							payload.vmrs_component !== undefined
								? payload.vmrs_component.name
								: null,
						level:
							payload.vmrs_component !== undefined
								? payload.vmrs_component.level
								: null,
						code:
							payload.vmrs_component !== undefined
								? payload.vmrs_component.code
								: null,
						source_system_id:
							payload.vmrs_component !== undefined
								? payload.vmrs_component.source_system_id
								: null,
					},
				},
			})
			.then((res) => {
				// if (res.status === 304 && res.status === 422) {
				//   setUpdateFailureMsg(!updateFailureMsg);
				// }
				if (res.status === 200) {
					setAddRecord(true)
					setSuccessUpdatedRecord(true)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const deleteLaborRate = (id) => {
		let currPage
		let remainder = metaData.total_count % 10
		if (remainder === 1) {
			currPage = metaData.total_pages - 1
		} else {
			currPage = metaData.current_page
		}
		instance
			.delete(`/labor/${id}`)
			.then((res) => {
				if (res.status === 200) {
					instance
						.get(`/labor/?page=${currPage}&search=${search}`)
						.then((res) => {
							if (res !== undefined) {
								let { labor_rates, meta } = res.data
								setLaborData(labor_rates)
								setMetaData(meta)
							}
						})
						.catch((error) => {
							console.log(error)
						})
				}
				setSuccessDeleteRecord(true)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const showNewRow = () => {
		setAddNewRow(!addNewRow)
		setShowEmptyMsg(false)
	}

	const removeRow = () => {
		setAddNewRow(false)
	}

	const newRowData = (newRowData) => {
		instance
			.post(`/labor`, { labor: { ...newRowData } })
			.then((res) => {
				if (res.status === 201) {
					instance
						.get("/labor", {
							params: {
								page: currentPage,
							},
						})
						.then((res) => {
							if (res !== undefined) {
								let { labor_rates, meta } = res.data
								setLaborData(labor_rates)
								setMetaData(meta)
							}
						})
						.catch((error) => {
							console.log(error)
						})
					setSuccessAddRecord(!successAddRecord)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleSearchInput = (e) => {
		setSearch(e.target.value)
		if (search === "" && search.length === 1) {
			instance
				.get("/labor")
				.then((res) => {
					if (res !== undefined) {
						let { labor_rates, meta } = res.data
						setLaborData(labor_rates)
						setMetaData(meta)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		} else {
			fetchLaborRates(search.replace(/\s+/g, "_"), current_page)
		}
	}

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			fetchLaborRates(search, current_page)
		}
	}

	const handleSearchSubmit = (e) => {
		e.preventDefault()
		if (search !== "") {
			instance
				.get("/labor", {
					params: {
						search: search,
						page: metaData.current_page
					},
				})
				.then((res) => {
					if (res !== undefined) {
						let data = res.data
						setLaborData(data.labor_rates)
						setMetaData(data.meta)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const fetchLaborRates = (search, current_page) => {
		instance
			.get("/labor", {
				params: {
					search: search,
					page: current_page
				},
			})
			.then((res) => {
				let laborRates = res.data
				if (laborRates !== undefined || laborRates.length > 0) {
					setLaborData(laborRates.labor_rates)
					setMetaData(laborRates.meta)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const onPageChanged = (data) => {
		const { currentPage, totalPages, pageLimit } = data
		const offset = currentPage * pageLimit
		const currData =
			laborData !== undefined ? laborData.slice(offset, offset + pageLimit) : ""
		setCurrentPage(currentPage)
		setTotalPages(totalPages)
		setCurrentData(currData)

		instance
			.get("/labor", {
				params: {
					page: currentPage,
					search: search,
				},
			})
			.then((res) => {
				let labor = res.data
				if (labor !== undefined) {
					setLaborData(labor.labor_rates)
					setMetaData(labor.meta)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const onSaveClick = (rowValue, id, updatedRes, errorMsg) => {
		if (errorMsg && errorMsg.length === 0) {
			if (Object.keys(updatedRes).length !== 0) {
				updateLaborParts(id, updatedRes)
			} else {
				setNoResponse(!noResponse)
			}
		} else {
			setShowErrors(errorMsg);
			setShowErrorPopup(true)
		}
	}

	const onRemoveClick = (id) => {
		setSelectedRecord(id)
		togglePopup()
	}

	const togglePopup = () => {
		setIsOpen(!isOpen)
	}

	const handleRemovePart = () => {
		deleteLaborRate(selectedRecord)
		togglePopup()
	}

	const toggleErrorPopup = () => {
		setShowErrorPopup(false)
	}

	const selectedAARSystem = (id) => {
		aar
			.get("/sub_systems", {
				params: {
					service_component: id,
				},
			})
			.then((res) => {
				if (res !== undefined) {
					const { sub_service_components } = res.data
					set_aar_sub_system_list(sub_service_components)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const selectedAARSubSystem = (subSystemId, systemId) => {
		aar
			.get("/services", {
				params: {
					sub_service_component: subSystemId,
					service_component: systemId,
				},
			})
			.then((res) => {
				if (res !== undefined) {
					const { services } = res.data
					set_services_list(services)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const reloadLaborIndex = (arg) => {
		if (arg) {
			instance
				.get(`/labor/?page=${metaData.current_page}`)
				.then((res) => {
					if (res !== undefined) {
						setShowEmptyMsg(false)
						let { labor_rates, meta } = res.data
						setLaborData(labor_rates)
						setMetaData(meta)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const downloadLaborRates = () => {
		instance
			.get("/labor/download", { responseType: 'arraybuffer' })
			.then((res) => {
				if (res.status === 200) {
					setShowMessage(true)
					setDownloadStarted(true)
				}
				const url = window.URL.createObjectURL(new Blob([res.data]))
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", "LaborRatesUploadTemplate.csv")
				document.body.appendChild(link)
				link.click()
				link.parentNode.removeChild(link)
				setShowDropdown(false)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const downloadReferenceExcel = () => {
		instance
			.get("/service_codes/download", { responseType: 'arraybuffer' })
			.then((res) => {
				if (res.status === 200) {
					setShowMessage(true)
					setDownloadStarted(true)
				}
				const url = window.URL.createObjectURL(new Blob([res.data]))
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", "ReferenceSheet.xls")
				document.body.appendChild(link)
				link.click()
				link.parentNode.removeChild(link)
				setShowDropdown(false)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleDropdown = (e) => {
		e.preventDefault()
		setShowDropdown(!showDropdown)
	}

	const handleClick = (e) => {
		if (node.current.contains(e.target)) {
			// inside click
			return
		}
		// outside click
		setShowDropdown(false)
	}

	return (
		<>
			<div className="labor-container">
				{successAddRecord ? (
					<div className="message">Labor rates added successfully</div>
				) : (
					""
				)}
				{successDeleteRecord ? (
					<div className="message">Labor rates deleted successfully</div>
				) : (
					""
				)}
				{successUpdatedRecord ? (
					<div className="message">Labor rates updated successfully</div>
				) : (
					""
				)}

				{showMessage && downloadStarted && (
					<div className="message">Download Started...</div>
				)}

				<div className="labor-rate-list">
					<div className="listing-header">
						<div>
							<button className="btn-regular" onClick={showNewRow}>
								Add
							</button>
						</div>
						<div className="menu-outer" ref={node}>
							<button
								className="btn-regular btn-dd"
								onClick={(e) => handleDropdown(e)}
							>
								Download Template <i className="fas fa-sort-down"></i>
							</button>
							{showDropdown && (
								<div className="dd-container">
									<ul>
										<li onClick={downloadLaborRates}>CSV Template</li>
										<li onClick={downloadReferenceExcel}>Reference Sheet</li>
									</ul>
								</div>
							)}
						</div>
						<div>
							{Object.keys(service_types).length ? (
								<div>
									<ImportLaborRates
										reloadLaborIndex={reloadLaborIndex}
										service_types={service_types}
										userInfo={userInfo}
									/>
								</div>
							) : null}
						</div>
						<div>
							<input
								type="text"
								className="text-box"
								placeholder="Type and search"
								onChange={(e) => handleSearchInput(e)}
								onKeyPress={handleKeyPress}
							/>
							<button
								className="btn-search"
								onClick={(e) => handleSearchSubmit(e)}
							>
								Search
							</button>
						</div>
					</div>
					<Table>
						<TableHeader headerData={laborRatesLabels} />
						{showLoaderForListing && (
							<div className="preloader">
								<Loader type="Puff" color="#00BFFF" height={50} width={50} />
							</div>
						)}
						
						<TableBody
							headerData={laborRatesLabels}
							rowData={laborData || []}
							service_types={service_types}
							addNewRow={addNewRow}
							onSaveClick={{ onSaveClick: onSaveClick }}
							onRemoveClick={{ onRemoveClick: onRemoveClick }}
							newRowData={newRowData}
							removeRow={removeRow}
							selectedAARSystem={selectedAARSystem}
							selectedAARSubSystem={selectedAARSubSystem}
							services_list={services_list}
							showEmptyMsg={showEmptyMsg}
						/>
					</Table>
					<div className="pagination-container">
						{metaData.total_count !== undefined && metaData.total_count > 10 ? (
							<>
								<div className="col-4 sec-1">
									Page: {current_page} / {total_pages}
								</div>
								<div className="col-4 sec-2">
									<Pagination
										totalRecords={metaData.total_count}
										pageLimit={metaData.per_page}
										pageNeighbours={1}
										onPageChanged={onPageChanged}
										totalPages={Math.ceil(metaData.total_count / 10)}
										currentPage={metaData.current_page}
									/>
								</div>
								<div className="col-4 sec-3">
									Total Rows: {metaData.total_count}
								</div>
							</>
						) : null}
					</div>
				</div>
				{isOpen && (
					<Popup
						content={
							<>
								<div className="popup-content-container">
									<div className="popup-title">Delete confirmation</div>
									<div className="popup-body">
										<h3>Are you sure?</h3>
										<p>This action cannot be undone!</p>
									</div>
									<div className="popup-footer">
										<p className="action-buttons">
											<span>
												<button
													className="btn-regular btn-grey"
													onClick={togglePopup}
												>
													Cancel
												</button>
											</span>
											<span>
												<button
													className="btn-regular btn-blue"
													onClick={handleRemovePart}
												>
													Confirm
												</button>
											</span>
										</p>
									</div>
								</div>
							</>
						}
						handleClose={togglePopup}
					/>
				)}
				{showErrorPopup && (
					<Popup
						content={
							<>
								<div className="popup-content-container">
									<div className="popup-title error-popup-title">
										Fund error
									</div>
									<div className="popup-body">
										<div className="error-list">
											<ul>
												{showErrors &&
													showErrors.map((error, index) => {
														return <li key={index}>{error.err}</li>
													})}
											</ul>
										</div>
									</div>
									<div className="popup-footer">
										<p className="action-buttons">
											<span>
												<button
													className="btn-regular btn-grey"
													onClick={toggleErrorPopup}
												>
													Cancel
												</button>
											</span>
										</p>
									</div>
								</div>
							</>
						}
						handleClose={toggleErrorPopup}
					/>
				)}
			</div>
		</>
	)
}

export default LaborRates

