import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import AddParts from "../Parts/AddParts";

const Parts = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showGrid, setShowGrid] = useState(true);

  const handleOptionChange = () => {
    if (document.getElementById("hqParts").value === "hq-upload-parts") {
      setShowGrid(!showGrid);
      setShowOptions(!showOptions);
    }
  };

  return (
    <div className="parts-container">
      {showOptions && (
        <div className="options-container">
          <div className="options">
            <div className="btn-radio">
              <input
                type="radio"
                name="opt"
                id="hqParts"
                value="hq-upload-parts"
                defaultChecked={true}
              />
            </div>
            <div className="info">
              <h3>Use HQ uploaded parts</h3>
              <p className="sub-hd">
                You can edit the parts or add your own later
              </p>
            </div>
          </div>
          <div className="options">
            <div className="btn-radio">
              <input type="radio" name="opt" value="add-upload-parts" />
            </div>
            <div className="info">
              <h3>Add / upload parts</h3>
              <p className="sub-hd">You can import HQ parts later</p>
            </div>
          </div>
          <div className="cta-btn">
            <button className="btn-main btn-green" onClick={handleOptionChange}>
              Continue
            </button>
          </div>
        </div>
      )}

      {showGrid && <AddParts userInfo={props.userInfo} />}
    </div>
  );
};

export default withRouter(Parts);
