import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import withLoader from '../core/withLoader';
import ReactTooltip from "react-tooltip"
import cx from 'classnames';

export const Row = ({ children, rowClassName, id, onRowClick }) => {
  return (
    <>
      <div
        className={cx('table-row', rowClassName)}
        onClick={() => onRowClick(id)}
      >
        {children}
      </div>
    </>
  );
};

Row.propTypes = {
  children: PropTypes.node,
  rowClassName: PropTypes.string,
  onRowClick: PropTypes.func,
  id: PropTypes.number,
};
Row.defaultProps = {
  rowClassName: '',
  onRowClick: () => { },
};

export const Cell = ({
  headerData,
  rowData,
  isRowClickable,
  onRowClick,
  onSaveClick,
  onRemoveClick,
  service_types,
  onAddViewAssociateParts
}) => {
  const [rowObj, setRowObj] = useState(null);

  const columnClassNames = cx(
    `table-cell`,
  );

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return rowData && rowData.length ? (
    rowData.map((rowValue, rowKey) => {
      return (
				<Row
					key={rowKey}
					rowClassName={isRowClickable ? "clickable-row" : null}
					id={rowValue.id}
					onRowClick={onRowClick}
				>
					{headerData.map(
						(headerValue) =>
							headerValue.name && (
								<div
									key={`${headerValue.name}${rowKey}`}
									className={
										headerValue.className === "service_type"
											? cx(columnClassNames) + " service-type"
											: headerValue.className === "equipment_type"
											? cx(columnClassNames) + " equipment-type"
											: headerValue.className === "oem"
											? cx(columnClassNames) + " oem"
											: headerValue.className === "tire_size"
											? cx(columnClassNames) + " tire-size"
											: headerValue.className === "btn-add"
											? cx(columnClassNames) + " btn-add"
											: headerValue.className === "icon"
											? cx(columnClassNames) + " icon"
											: cx(columnClassNames)
									}
									onClick={(e) => {
										e.preventDefault()
										if (headerValue.name === "add_associations") {
											onAddViewAssociateParts[
												headerValue.addAssociatesClickFunction
											](rowValue)
										}

										if (headerValue.name === "delete") {
											onRemoveClick[headerValue.removeClickFunction](
												rowValue.rule_ids
											)
										}
									}}
								>
									{rowValue ? (
										headerValue.name === "edit" ? (
											<i className="fas fa-pencil-alt"></i>
										) : headerValue.name === "delete" ? (
											<i className="far fa-trash-alt"></i>
										) : headerValue.name === "save" ? (
											<i className="fas fa-save"></i>
										) : headerValue.name === "add_associations" ? (
											<button>Edit Bundle</button>
										) : rowValue[headerValue.name] === "aar" ? (
											<>
												<span className="aar">
													{rowValue[headerValue.name]}
												</span>
												<span className="service-name">
													{rowValue.aar.system !== undefined ? (
														<>
															<a
																data-for="main"
																data-tip={rowValue.aar.system.name}
																data-iscapture="true"
															>
																{rowValue.aar.system.name}
															</a>
															<ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
														</>
													) : (
														""
													)}
												</span>
												{rowValue.aar.system !== null ? <span>{">"}</span> : ""}
												<span className="service-name">
													{rowValue.aar.subsystem !== undefined ? (
														<>
															<a
																data-for="main"
																data-tip={rowValue.aar.subsystem.name}
																data-iscapture="true"
															>
																{rowValue.aar.subsystem.name}
															</a>
															<ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
														</>
													) : (
														""
													)}
												</span>
												{rowValue.aar.service !== undefined ? (
													<span>{">"}</span>
												) : (
													""
												)}
												<span className="service-name">
													{rowValue.aar.service !== undefined ? (
														<>
															<a
																data-for="main"
																data-tip={rowValue.aar.service.name}
																data-iscapture="true"
															>
																{rowValue.aar.service.name}
															</a>
															<ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
														</>
													) : (
														""
													)}
												</span>
											</>
										) : rowValue[headerValue.name] === "vmrs" ? (
											<>
												<span className="vmrs">
													{rowValue[headerValue.name]}
												</span>
												<span className="service-name">
													{rowValue.vmrs.system !== undefined ? (
														<>
															<a
																data-for="main"
																data-tip={rowValue.vmrs.system.name}
																data-iscapture="true"
															>
																{rowValue.vmrs.system.name}
															</a>
															<ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
														</>
													) : (
														""
													)}
												</span>
												{rowValue.vmrs.assembly !== undefined ? (
													<span>{">"}</span>
												) : (
													""
												)}
												<span className="service-name">
													{rowValue.vmrs.assembly !== undefined ? (
														<>
															<a
																data-for="main"
																data-tip={rowValue.vmrs.assembly.name}
																data-iscapture="true"
															>
																{rowValue.vmrs.assembly.name}
															</a>
															<ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
														</>
													) : (
														""
													)}
												</span>
												{rowValue.vmrs.component !== undefined ? (
													<span>{">"}</span>
												) : (
													""
												)}
												<span className="service-name">
													{rowValue.vmrs.component !== undefined ? (
                            <>
														<a
															data-for="main"
															data-tip={rowValue.vmrs.component.name}
															data-iscapture="true"
														>
															{rowValue.vmrs.component.name}
														</a>
                            <ReactTooltip
																id="main"
																place="top"
																type="info"
																effect="float"
																multiline={true}
															/>
                              </>
													) : (
														""
													)}
												</span>
											</>
										) : rowValue[headerValue.name] === "tractor" ? (
											"Power Unit"
										) : headerValue.name === "equipement_type" ? (
											capitalizeFirstLetter(rowValue.equipement_type)
										) : (
											rowValue[headerValue.name]
										)
									) : (
										"NA"
									)}
								</div>
							)
					)}
				</Row>
			)
    })
  ) : (
      null
    );
};

Cell.propTypes = {
  headerData: PropTypes.array,
  rowData: PropTypes.array,
  isRowClickable: PropTypes.bool,
  onSaveClick: PropTypes.object,
  onRemoveClick: PropTypes.object
};
Cell.defaultProps = {
  headerData: [],
  rowData: [],
  isRowClickable: false,
};

export const TableHeader = ({
  headerData,
  tableHeaderClassName,
  rowClassName,
}) => {
  return (
    <div className={cx('table-header', tableHeaderClassName)}>
      <Row className={rowClassName}>
        {headerData.map(({ name, label, isVline, className }) => (
          <div
            key={`title-${name}`}
            className={cx(
              `table-cell ${className === 'service_type' ? 'service-type' : className === 'equipment_type' ? 'equipment-type' : className === 'oem' ? 'oem' : className === 'tire_size' ? 'tire-size' : className === 'icon' ? 'icon' : className === 'oem' ? 'oem' : className === 'btn-add' ? 'btn-add' : 'secondary-column'}`,
            )}
          >
            {isVline ? <span className="vline"></span> : ''} {label}
          </div>
        ))}
      </Row>
    </div>
  );
};
TableHeader.propTypes = {
  headerData: PropTypes.array,
  tableHeaderClassName: PropTypes.string,
  rowClassName: PropTypes.string,
};
TableHeader.defaultProps = {
  tableHeaderClassName: '',
  rowClassName: '',
  headerData: [],
};

export const TableBody = ({
  headerData,
  rowData,
  rowClassName,
  tableBodyClassName,
  onSaveClick,
  onRemoveClick,
  addNewRow,
  newRowData,
  removeRow,
  service_types,
  onAddViewAssociateParts,
	showEmptyMsg
}) => {

	return (
		<>
			{showEmptyMsg && (
				<div className="no-record">
					<span>No data</span>
				</div>
			)}
			<div className={cx('table-body', tableBodyClassName)} id='table-body'>
				<Cell
					headerData={headerData}
					rowData={rowData}
					rowClassName={rowClassName}
					onSaveClick={onSaveClick}
					onRemoveClick={onRemoveClick}
					service_types={service_types}
					onAddViewAssociateParts={onAddViewAssociateParts}
				>
				</Cell>
			</div>
		</>
  );
};
TableBody.propTypes = {
  headerData: PropTypes.array,
  rowData: PropTypes.array,
  rowClassName: PropTypes.string,
  tableBodyClassName: PropTypes.string,
  addNewRow: PropTypes.bool,
  newRowData: PropTypes.func,
};
TableBody.defaultProps = {
  headerData: [],
  rowData: [],
  rowClassName: '',
  tableBodyClassName: '',
  isRowClickable: false,
};


const Table = ({
  children,
  tableClassName,
  ...restProps
}) => {
  return (
    <Fragment>
      <div className={cx('sticky-table', tableClassName)} {...restProps}>
        {children}
      </div>
    </Fragment>
  );
};
Table.propTypes = {
  children: PropTypes.node,
  tableClassName: PropTypes.string,
  onPageChanged: PropTypes.func,
  totalRecords: PropTypes.number,
  pageLimit: PropTypes.number,
};
Table.defaultProps = {
  tableClassName: '',
};

export default withLoader(Table);
