import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const FetchServiceList = (props) => {

  const [systemCode, setSystemCode] = useState('');
  const [subSystemCode, setSubSystemCode] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const accessToken = queryParams.get("userAuthToken");

  useEffect(() => {
    setSystemCode(props.currentAARSystemId);
    setSubSystemCode(props.currentAARSubSystemId)
  }, [props.currentAARSystemId, props.currentAARSubSystemId])

  const loadOptions = async (searchQuery, _, { page }) => {

    const response = await fetch(
      `/api/v1/service_codes/aar/services?sub_service_component=${subSystemCode ? subSystemCode : ''}&service_component=${systemCode ? systemCode : ''}&page=${page}`, {
      method: 'GET',
      headers: {
        'Access-Token': accessToken,
      },
    }
    );
    const responseJSON = await response.json();

    let options = responseJSON.services.jobs[0].map((option) => ({
      label: `${option.code} - ${option.description}`,
      value: `${option.id}`,
      data: option,
    }))

    let filteredOptions;
    if (!searchQuery) {
      filteredOptions = options;
    } else {
      const searchLower = searchQuery.toLowerCase();
      filteredOptions = options.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    return {
      options: filteredOptions,
      hasMore: responseJSON.meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#5195FB',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };

  return (
    <>
      <AsyncPaginate
        key={JSON.stringify(systemCode)}
        value={props.value}
        loadOptions={loadOptions ? loadOptions : null}
        onChange={onChange}
        isSearchable={true}
        placeholder="Select task"
        className='service_dropdown'
        styles={customStyles}
        additional={{
          page: 1,
        }}
      />

    </>
  );
};

FetchServiceList.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  currentSystemId: PropTypes.number,
  currentSubSystemId: PropTypes.number
};

export default FetchServiceList;
