import React, { useEffect, useState } from "react";
import { FlatfileButton } from "@flatfile/react";
import { instance } from "../core/api";

const ImportParts = (props) => {
  const { userInfo, UOMList } = props;
  let uomValues = [];
  let uomOptions = [];

  Object.entries(UOMList).forEach(([key, value]) => {
    uomValues.push(key);
    uomOptions.push({
      value: `${key}`,
      label: `${value}`,
    });
  });

  const licenseKey = process.env.PLT_FLATFILE_LICENSE_KEY;
  const flatfilePartsConfig = {
    type: "Parts",
    fields: [
      {
        label: "Part Group Code",
        key: "part_group_code",
        validators: [{ validate: "required" }],
      },
      {
        label: "Part Group Description",
        key: "part_group_description",
        validators: [{ validate: "required" }],
      },
      {
        label: "Part Code",
        key: "part_code",
        validators: [
          { validate: "required" },
          { validate: "unique" }
        ],
      },
      {
        label: "Part Code Description",
        key: "part_code_description",
        validators: [{ validate: "required" }],
      },
      {
        label: "Part Price",
        key: "part_price",
        validators: [
          {
            validate: "regex_matches",
            regex: "^(?=.*[0-9])[0-9]*[.]?[0-9]{1,2}$",
            error: "Accepts a number with two decimal values",
          },
          { validate: "required" }
        ],
      },
      {
        label: "UOM",
        key: "uom",
        type: "select",
        options: uomOptions,
        validators: [{ validate: "required" }],
      },
      {
        label: "Manufacturer Part",
        key: "mfr_part"
      },
      {
        label: "Specification",
        key: "specification",
      },
      {
        label: "Rating",
        key: "rating",
      },
      {
        label: "AAR Mapping",
        key: "aar_mapping",
      },
      {
        label: "VMRS Mapping",
        key: "vmrs_mapping",
      },
      {
        label: "Row ID",
        key: "source_row_id",
      },
      {
        label: "Part Services",
        key: "parts_services",
      },
    ],
    allowInvalidSubmit: false,
    managed: false,
    allowCustom: true,
    disableManualInput: true,
    title: 'Bulk Upload Parts',
  };

  return (
    <div>
      <FlatfileButton
        licenseKey={licenseKey}
        customer={{ userId: userInfo.username, email: userInfo.email }}
        settings={flatfilePartsConfig}
        className="btn-regular"
        fieldHooks={{
          part_code: async (values) => {
            let serverResults = [];
            await instance
              .get("/parts")
              .then((res) => {
                if (res !== undefined) {
                  Object.entries(res.data.parts).forEach(([index, part]) => {
                    serverResults.push(part.part_code)
                  });
                }
              })
              .catch((error) => {
                console.log(error)
              })
            let changeValues = [];
            values.forEach((item) => {
              if (serverResults.includes(item[0])) {
                changeValues.push([
                  {
                    info: [
                      {
                        message: "Duplicate Record",
                        level: "error",
                      },
                    ],
                  },
                  item[1],
                ]);
              }
            });
            return changeValues;
          },

          uom: async (values) => {
            let changeValues = [];
            values.forEach((item) => {
              if (!uomValues.includes(item[0])) {
                changeValues.push([
                  {
                    info: [
                      {
                        message: "Invalid UOM",
                        level: "error",
                      },
                    ],
                  },
                  item[1],
                ]);
              }
            });
            return changeValues;
          },
        }}
        onData={async (results) => {
          const updatedImportData = results.validData.map((record, index) => {
            let partServices = [];
            record.aar_mapping.split(/;/).forEach((aar) => {
              if (aar) {
                let source = aar.split(/\-/);
                partServices.push({
                  service_type: "aar",
                  aar_system_ss_id: source[0],
                  aar_subsystem_ss_id: source[1] || null,
                  aar_service_ss_id: source[2] || null,
                });
              }
            });
            record.vmrs_mapping.split(/;/).forEach((vmrs) => {
              if (vmrs) {
                partServices.push({
                  service_type: "vmrs",
                  vmrs_system_code: vmrs.match(/^\d{3,}/)[0],
                  vmrs_assembly_code: vmrs.match(/^\d{3,}-\d{3,}/)[0] || null,
                  vmrs_component_code: vmrs.match(/^\d{3,}-\d{3,}-\d{3,}$/) && 
                    vmrs.match(/^\d{3,}-\d{3,}-\d{3,}$/)[0] || null,
                });
              }
            });
            record.parts_services = partServices;
            record.source_row_id = index + 2;
            return record;
          });
          instance
            .post(`/parts/upload`, { parts: updatedImportData })
            .then((res) => {
              if (res.status === 201) {
                props.reloadPartsIndex(true);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          return "Upload Successful!!";
        }}
      >
        {" "}
        Upload Parts
      </FlatfileButton>
    </div>
  );
};

export default ImportParts;
