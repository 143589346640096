import React, { useState, useEffect, useContext } from "react";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

function ControlledTabs() {

  return (
    <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
      <Tab eventKey="home" title="Parts">
        Parts tab content
      </Tab>
      <Tab eventKey="profile" title="labor">
        Labor tab content
      </Tab>
      <Tab eventKey="contact" title="Service Bundles">
        Service Bundles tab content
      </Tab>
    </Tabs>
  );
}

export default ControlledTabs;