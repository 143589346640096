const laborRatesLabels = [
  {
    name: 'service_type',
    label: 'Labor Rate For Services',
    className: 'service_type',
  },
  {
    name: 'regular_hours_rate',
    label: 'Regular Rate/Hr',
    className: 'labor_rates',
    currency: '$',
    isVline: true
  },
  {
    name: "after_hours_rate",
    label: "After Hour Rate/Hr",
    className: 'labor_rates',
    currency: '$',
    isVline: true
  },
  {
    name: 'holiday_rate',
    label: 'Holiday Rate/Hr',
    className: 'labor_rates',
    currency: '$',
    isVline: true
  },
  {
    name: 'edit',
    label: '',
    isBtn: true,
    className: 'icon'
  },
  {
    name: 'save',
    label: '',
    saveClickFunction: 'onSaveClick',
    showIcon: true,
    isBtn: true,
    className: 'icon'
  },
  {
    name: 'delete',
    label: 'Action',
    removeClickFunction: 'onRemoveClick',
    isBtn: true,
    className: 'icon'
  },

];

export default laborRatesLabels;
