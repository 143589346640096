import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const FetchServiceList = (props) => {

  const [vmrsAssemblyCode, setVMRSAssemblyCode] = useState('');
  const [vmrsComponentCode, setVMRSComponentCode] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const accessToken = queryParams.get("userAuthToken");

  useEffect(() => {
    setVMRSAssemblyCode(props.vmrsAssemblyCode);
    setVMRSComponentCode(props.vmrsComponentCode)
  }, [props.vmrsAssemblyCode, props.vmrsComponentCode])

  const loadOptions = async (searchQuery, _, { page }) => {

    const response = await fetch(
      `/api/v1/service_codes/vmrs/wa_codes?vmrs_assembly_code=${vmrsAssemblyCode ? vmrsAssemblyCode : ''}&vmrs_component_code=${vmrsComponentCode ? vmrsComponentCode : ''}&search=${searchQuery}&page=${page}`, {
      method: 'GET',
      headers: {
        'Access-Token': accessToken,
      },
    }
    );
    const responseJSON = await response.json();
    
    let options = responseJSON.wa_codes.map((option) => ({
        label: `${option.details}`,
        value: `${option.id}`,
        data: option,
    }))

    return {
      options: options,
      hasMore: responseJSON.meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: props.showError ? '#ff0000' : '#cccccc',
    }),
  };

  return (
    <>
      <AsyncPaginate
        key={JSON.stringify(vmrsAssemblyCode)}
        value={props.value}
        loadOptions={loadOptions ? loadOptions : null}
        onChange={onChange}
        isSearchable={true}
        isClearable={true}
        debounceTimeout={500}
        placeholder="Select task"
        className='service_dropdown'
        styles={customStyles}
        additional={{
          page: 1,
        }}
      />

    </>
  );
};

FetchServiceList.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  currentSystemId: PropTypes.number,
  currentSubSystemId: PropTypes.number
};

export default FetchServiceList;
