import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../components/Home";
import AddParts from '../components/Parts/AddParts';

export default (
  <div className="container-fluid regular-space">
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/add-parts" exact component={AddParts} />
      </Switch>
    </Router>
  </div>
);
