import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const FetchSystems = (props) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const accessToken = queryParams.get("userAuthToken");

  useEffect(() => {
    if (typeof (props.value) === 'string') {
      setSelectedValue({
        value: props.value,
        label: props.value
      })
    } else {
      setSelectedValue(props.value)
    }
  }, [props.value, props.currentServiceList, props.currentSubSystem])

  const loadOptions = async (searchQuery, _, { page }) => {

    const response = await fetch(
      `/api/v1/service_codes/aar/systems?search=${searchQuery}&page=${page}`, {
      method: 'GET',
      headers: {
        'Access-Token': accessToken,
      },
    });

    const responseJSON = await response.json();

    let options = responseJSON.service_components.map((option) => ({
      label: `${option.name}`,
      value: `${option.id}`,
      data: option
    }))

    return {
      options: options,
      hasMore: responseJSON.meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#5195FB',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };

  const handlePropsvalue = (data) => {
    let renderValue;
    if (data.value !== null) {
      renderValue = data.value
    } else if (data.currentServiceList !== null) {
      renderValue = data.currentServiceList
    } else if (data.currentSubSystem !== null) {
      renderValue = data.currentSubSystem;
    } else {
      renderValue = data.value
    }
    return renderValue;
  }

  return (
    <>
      {typeof (props.value) === 'string' ?
        <AsyncPaginate
          defaultValue={{ value: props.value, label: props.value }}
          loadOptions={loadOptions}
          onChange={onChange}
          isSearchable={true}
          placeholder="System"
          className='service_dropdown'
          styles={customStyles}
          debounceTimeout={500}
          additional={{
            page: 1,
          }}
        /> :
        <AsyncPaginate
          value={handlePropsvalue(props)}
          loadOptions={loadOptions}
          onChange={onChange}
          isSearchable={true}
          placeholder="System"
          className='service_dropdown'
          styles={customStyles}
          debounceTimeout={500}
          additional={{
            page: 1,
          }}
        />
      }
    </>
  );
};

FetchSystems.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default FetchSystems;